<script>
	import { onMount } from 'svelte';
	import * as d3core from 'd3';
	import * as d3annotation from 'd3-svg-annotation';
	import * as d3polygon from 'd3-polygon'
	import _ from 'lodash'
	import brusselsCommunes from "../assets/maps/brusselsCommunes.json"
	import brusselsCanal from "../assets/maps/brusselsCanal.json"
	
	import communesRaw from "../assets/data/immoCommune.json"
	import { location } from '../store.js';
	import { _ as i18n, locale } from 'svelte-i18n'

	const d3 = {...d3core, ...d3annotation, ...d3polygon}

	let el;
	let w;
	let h;
	let width;
	let height;
	let main;
	let xScale;
	let yScale;
	let axis;
	let evolutionsBack;
	let evolutions;
	let line;
	let data;
	let origins;
	let destinations;
	let map;
	let ouest = ["Jette", "Anderlecht", "Berchem-Sainte-Agathe", "Molenbeek", "Koekelberg", "Ganshoren"];
	let communes = communesRaw.map(c => ({ ...c, baseColor:  c.name === 'Bruxelles' ? '#ed9370' : (ouest.includes(c.name) ? '#c32e00' : '#00939c')}))
	let scaleLabels;

	export let step;
	export let evolution;

	onMount(() => {
		const margin = { top: 10, bottom: 20, left: 0, right: 30}
		width = w - margin.left - margin.right
		height = h - margin.top - margin.bottom
				
		const svg = d3.select(el)
		  .append("svg")
			.attr("width", width + margin.left + margin.right)
			.attr("height", height + margin.top + margin.bottom)
			.style('overflow', 'visible')
			.style('font-family', 'Alfphabet')
			.style('font-size', '14px')
		
		main = svg.append('g')
			.attr("transform", `translate(${margin.left}, ${margin.top})`)
			
		drawAxis()

		line = d3.line()
			.x(d => d.fx || xScale(d.prix))
			.y(d => d.fy || yScale(d.name) + yScale.bandwidth() / 2)
			.curve(d3.curveCatmullRom)

		data = communes
			.map(commune => [{name: commune.name, prix: commune["1995"]}, {name: commune.name, prix: commune["2020"]}])
			.concat([[{year: 1995, fx: width/2 - 70, fy: -20}, {year: 2020, fx: width/2 + 70, fy: -20}]])
			.map(points => ({
					points,
					length: length(line(points)),
				}
			))

		data[data.length-1].baseColor = 'grey';

		evolutionsBack = main.append('g')
			.attr("class", 'evolutionsBack')
		evolutions = main.append('g')
			.attr("class", 'evolutions')

		map = main.append("g")
			.attr('class', 'map')

		drawOrigin()		
		drawMap()
	})

	function drawMap(){
		const projection = d3.geoMercator()
  		.fitExtent([[0, 0], [width*0.2, width*0.2]], brusselsCommunes);

		const path = d3.geoPath(d3.geoIdentity())
    	.projection(projection);

		map
			.attr('transform', `translate(${(0.8*width)- 30}, ${height-(0.2*width) - 50})`)
			.style("opacity", 0)
		
		map.append('g')
			.selectAll('path')
			.data(brusselsCommunes.features)
			.enter().append('path')
			.attr('class', 'commune')
			.attr('d', path)
			.style('fill', d => d.properties.NAME_FRE === 'Bruxelles' ? '#ed9370' : (ouest.includes(d.properties.NAME_FRE) ? '#c32e00' : '#00939c') )
			.style('stroke', 'white')
			.style('stroke-width', width * 0.001)
			.style('stroke-opacity', 1)

		map.append('g').selectAll('path')
			.data(brusselsCanal.features)
			.enter().append('path')
			.attr('fill', 'none')
			.attr('d', path)
			.style('stroke', 'dodgerblue')
			.style('stroke-width', width * 0.002)
			.style('opacity', 0.4)
	}

	function length(path) {
    return d3.create("svg:path").attr("d", path).node().getTotalLength();
  }

	function showEvolution() {
		if(evolutions){
			evolutionsBack.selectAll('.evolution')
				.data(data)
				.join(enter => enter
						.append('path')
						.attr('class','evolution')
						.attr('fill', 'none')
						.attr('stroke', 'white')
						.attr('stroke-width', 4)
						.attr('d', d => line(d.points))
						.attr("stroke-dasharray", d => `${0},${d.length}`)
						.style('opacity', 0.7),
					update => update	
						.attr("stroke-dasharray", d => `${evolution * d.length},${d.length}`)
				)

			evolutions.selectAll('.evolution')
				.data(data)
				.join(enter => enter
						.append('path')
						.attr('class','evolution')
						.attr('fill', 'none')
						.attr('stroke', 'black')
						.attr('stroke-width', 2)
						.attr('d', d => line(d.points))
						.attr("stroke-dasharray", d => `${0},${d.length}`)
						.style('opacity', 0.7),
					update => update	
						.attr("stroke-dasharray", d => `${evolution * d.length},${d.length}`)
				)
		}
	}

	$: evolution, showEvolution()

	function update() {
		if(destinations){
			destinations.transition().style('opacity', step > 1 ? 1 : 0)

			destinations.selectAll('circle')
				.attr('fill', d => step > 2 || d.baseColor === 'grey' ? d.baseColor : '#00939c')
				.attr('stroke', d => step > 2 || d.baseColor === 'grey' ? d.baseColor : '#00939c')

			origins.selectAll('circle')
				.attr('stroke', d => step > 2 || d.baseColor === 'grey' ? d.baseColor : '#00939c')

			map.transition().style('opacity', step > 2 ? 1 : 0)
		}
	}

	$: step, update()

	function drawAxis(){
		axis = main.append('g')

		xScale = d3.scaleLinear()
			.domain([0, d3.max(communes, d => d["2020"])])
			.range([0, width])
			.nice()

		const xAxis = g => g
			.attr("transform", `translate(0,${height})`)
			.call(d3.axisBottom(xScale)
					.ticks(6)
					.tickFormat(d => `${d3.format(',')(d)}€`)
			)
			.call(g => g.select(".domain").remove())

		axis.append("g")
      .call(xAxis);
		
		const xGrid = svg => svg
			.attr("transform", `translate(0,0)`)
			.call(d3.axisBottom(xScale)
					.tickSize(height)
					.ticks(6)
					.tickFormat('')
			)
			.call(g => g.select(".domain").remove())

		axis.append("g")
			.attr('class', 'x-grid')
      .call(xGrid)
			.style("stroke-dasharray", 2)
			.style("stroke-opacity", 0.2)

		yScale = d3.scaleBand()
			.range([ 0, height ])
			.domain(_(communes).orderBy('2020', 'desc').map('name').value().concat(['']))
			.padding(.1);

		const yAxis = g => g
			//.attr("transform", `translate(0,${height})`)
			.call(d3.axisLeft(yScale)
					//.ticks(d3.timeMonth.every(3))
					//.tickFormat(d => `${d}`)
			)
			.call(g => g.select(".domain").remove())

		/*axis.append("g")
      .call(yAxis);*/
		
		const yGrid = svg => svg
			.attr("transform", `translate(${width},0)`)
			.call(d3.axisLeft(yScale)
					.tickSize(width)
					.tickFormat('')
			)
			.call(g => g.select(".domain").remove())

		axis.append("g")
			.attr('class', 'y-grid')
      .call(yGrid)
			.style("stroke-dasharray", 2)
			.style("stroke-opacity", 0.2)

		scaleLabels = axis.append('g')

		scaleLabels.append('text')
			.attr('x', width * 0.95)
			.attr('y', height - 20)
			.attr('text-anchor', 'end')
			.attr('font-size', 14)
			.text($i18n("plot.prix")+' →')
	}

	function drawOrigin() {
		origins = main.append('g')
			.attr('class', 'origins')

		destinations = main.append('g')
			.attr('class', 'destinations')
			.style('opacity', 0)

		origins.selectAll('.origin')
			.data(communes.concat([{year: 1995, fx: width/2 - 70, fy: -20, baseColor: 'grey'}]))
			.join(enter => enter
				.append('g')
				.attr('class', 'origin')
				.call(enter => enter
					.append('circle')
					.attr('r', 7)
					.attr('cx', d => d.fx || xScale(d["1995"]))
					.attr('cy', d => d.fy || yScale(d.name) + yScale.bandwidth() / 2)
					.attr('fill', 'white')
					.attr('stroke', d => d.baseColor === 'grey' ? 'grey' : '#00939c')
					.attr('stroke-width', 2)
				)
				.call(enter => enter
					.filter(d => !d.year)
					.append('text')
					.attr('class', 'commune-label')
					.attr('x', d => xScale(d["1995"]) - 12)
					.attr('y', d => (yScale(d.name) + yScale.bandwidth() / 2) + 4)
					.attr('text-anchor', 'end')
					.attr('font-size', w < 900 ? 12 : 14)
					.attr('font-family', 'Alfphabet')
					.text(d => d.name === 'Berchem-Sainte-Agathe' && w < 900 ? 'Berchem-St-Agathe' : d.name )
				)
			)

		origins.selectAll('.origin-label')
			.data([{year: 1995, fx: width/2 - 70, fy: -20, baseColor: 'grey'}])
			.join(enter => enter
					.append('text')
					.attr('class', 'origin-label')
					.attr('x', d => (d.fx || xScale(d["1995"])) - 18)
					.attr('y', d => (d.fy || yScale(d.name) + yScale.bandwidth() / 2) + 2)
					.attr('text-anchor', 'end')
					.text(d => d.year)
			)

		//destination

		destinations.selectAll('.destination')
			.data(communes.concat([{year: 2020, fx: width/2 + 70, fy: -20, baseColor: 'grey'}]))
			.join(enter => enter
					.append('circle')
					.attr('class', 'destination')
					.attr('r', 7)
					.attr('cx', d => d.fx || xScale(d["2020"]))
					.attr('cy', d => d.fy || yScale(d.name) + yScale.bandwidth() / 2)
					.attr('fill', d => d.baseColor === 'grey' ? 'grey' : '#00939c')
					.attr('stroke', d => d.baseColor === 'grey' ? 'grey' : '#00939c')
					.attr('stroke-width', 2)
			)

		destinations.selectAll('.destination-label')
			.data(communes.concat([{year: 2020, fx: width/2 + 70, fy: -20, baseColor: 'grey'}]))
			.join(enter => enter
					.append('text')
					.attr('class', 'destination-label')
					.attr('x', d => (d.fx || xScale(d["2020"])) + 12)
					.attr('y', d => (d.fy || yScale(d.name) + yScale.bandwidth() / 2) + 4)
					.text(d => d.year || `×${Math.round(d["2020"]/d["1995"]*10)/10}` )
			)
	}

	function setLocation() {
		if(destinations) {
			origins.selectAll('.commune-label')
		    .style('font-weight', d => $location && d.name.toUpperCase() === $location.commune.toUpperCase() ? 'bold' : '')
		}
	}

	$: $location, setLocation()

	function setLocale() {
		if(scaleLabels) 
			scaleLabels.select('text')
				.text($i18n("plot.prix")+' →')
	}

	$: $locale, setLocale()
</script>

<style>
</style>

<div bind:this={el} bind:clientWidth={w} bind:clientHeight={h} style="height: 100%; background-color: #fff0"></div>
