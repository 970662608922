<script>
  import Section from "../layout/Section.svelte";
  import PreventionMap from "../charts/PreventionMap.svelte";
	import Divider from "../layout/Divider.svelte";
	import { themes } from "../config.js";
	import { locale, _ } from 'svelte-i18n'

	let w;
	let wVideo;
</script>
<div bind:clientWidth={w}></div>

<Section>
	<p style="margin-top: 64px;">{@html $_('chap2.p21')}</p>
  {@html $_('chap2.p22')}
</Section>

<Section>
	{@html $_('chap2.p23')}
</Section>
{#if w !== undefined && w >= 600}
	<div style={`display: flex; flex-direction: row; align-items: flex-start; justify-content: space-between; width: 70vw !important; margin: auto; margin-top: 32px;`}>
		<span style="width: 31%; display: inline-block;">
			<PreventionMap selectedMertic={"depistageCancerUterus2016"} title="Dépistage Cancer de l'Uterus, 2016"/>
			{@html $_('chap2.p24')}
		</span>
		<span style="width: 31%; display: inline-block;">
			<PreventionMap selectedMertic={"soinDentaire2016"} title="Soin dentaire préventif, 2016"/>
			{@html $_('chap2.p25')}
		</span>
		<span style="width: 31%; display: inline-block;">
			<PreventionMap invertedColor={true} selectedMertic={"diabète2016"} title={$_('chap2.p26')}/>
			{@html $_('chap2.p27')}
		</span>
	</div>
	<caption style="color: {themes['light']['text']}; background-color: {themes['light']['background']}; margin-top: 32px; margin-bottom: 32px;">
		<div class="col-medium">
			<div class="caption" style="text-align: center;">{@html $_('base.source')}: <a alt="OBSERVATOIRE DE LA SANTÉ ET DU SOCIAL" target="_blank" href="https://www.ccc-ggc.brussels/fr/observatbru/publications/dossier-20192-tous-egaux-face-la-sante-bruxelles-donnees-recentes-et">{@html $_('base.obs')}</a></div>
		</div>
	</caption>
{:else if w !== undefined}
	<Section>
		<div class="inset-narrow" style="margin-top: 64px;">
			<PreventionMap selectedMertic={"depistageCancerUterus2016"} title={$_('chap2.p28')}/>
		</div>
		<caption style="color: {themes['light']['text']}; background-color: {themes['light']['background']};">
			<div class="col-medium">
				<div class="caption" style="text-align: center;">{$_('base.source')}: <a alt="OBSERVATOIRE DE LA SANTÉ ET DU SOCIAL" target="_blank" href="https://www.ccc-ggc.brussels/fr/observatbru/publications/dossier-20192-tous-egaux-face-la-sante-bruxelles-donnees-recentes-et">{@html $_('base.obs')}</a></div>
			</div>
		</caption>
		{@html $_('chap2.p29')}
	</Section>

	<Section>
		<div class="inset-narrow" style="margin-top: 64px;">
			<PreventionMap selectedMertic={"soinDentaire2016"} title={$_('chap2.p30')}/>
		</div>
		<caption style="color: {themes['light']['text']}; background-color: {themes['light']['background']};">
			<div class="col-medium">
				<div class="caption" style="text-align: center;">{$_('base.source')}: <a alt="OBSERVATOIRE DE LA SANTÉ ET DU SOCIAL" target="_blank" href="https://www.ccc-ggc.brussels/fr/observatbru/publications/dossier-20192-tous-egaux-face-la-sante-bruxelles-donnees-recentes-et">{@html $_('base.obs')}</a></div>
			</div>
		</caption>
		{@html $_('chap2.p31')}
	</Section>

	<Section>
		<div class="inset-narrow" style="margin-top: 64px;">
			<PreventionMap invertedColor={true} selectedMertic={"diabète2016"} title={$_('chap2.p32')}/>
		</div>
		<caption style="color: {themes['light']['text']}; background-color: {themes['light']['background']};">
			<div class="col-medium">
				<div class="caption" style="text-align: center;">{@html $_('base.source')}: <a alt="OBSERVATOIRE DE LA SANTÉ ET DU SOCIAL" target="_blank" href="https://www.ccc-ggc.brussels/fr/observatbru/publications/dossier-20192-tous-egaux-face-la-sante-bruxelles-donnees-recentes-et">{@html $_('base.obs')}OBSERVATOIRE DE LA SANTÉ ET DU SOCIAL, 2019</a></div>
			</div>
		</caption>
		{@html $_('chap2.p33')}
	</Section>
{/if}
<Divider/>
<Section>
	{@html $_('chap2.p34')}
	{@html $_('chap2.p35')}
</Section>

<Section>
	<blockquote>{@html $_('chap2.p36')}</blockquote>
	<div bind:clientWidth={wVideo}>
		<iframe title="vimeo-player" src="{$locale.startsWith('fr') ? 'https://player.vimeo.com/video/709169845?h=cfb937fd3b' : $locale.startsWith('en') ? 'https://player.vimeo.com/video/751608159?h=6d4ff49154' : 'https://player.vimeo.com/video/753814911?h=93c7001c7f'}" width={wVideo} height={wVideo/640*360} frameborder="0" allowfullscreen></iframe>
	</div>
	<caption>
		<div class="col-medium">
			<div class="caption">Sarah Missinne – {@html $_('chap2.p37')}</div>
		</div>
	</caption>
</Section>

<Section>
	{@html $_('chap2.p38')}
	
	<blockquote>{@html $_('chap2.p39')}</blockquote>
	<div bind:clientWidth={wVideo}>
		<iframe title="vimeo-player" src="{$locale.startsWith('fr') ? 'https://player.vimeo.com/video/711730811?h=0663724c68' : $locale.startsWith('en') ? 'https://player.vimeo.com/video/751608028?h=f5149919d0': 'https://player.vimeo.com/video/753815468?h=806b295cf7'}" width={wVideo} height={wVideo/640*360} frameborder="0" allowfullscreen></iframe>
	</div>
	<caption>
		<div class="col-medium">
			<div class="caption">Rachid BATHOUM – UNIA</div>
		</div>
	</caption>
</Section>

<style>
</style>