<script>
	import { onMount } from "svelte";
	import Scroller from "../layout/Scroller.svelte";
  import Section from "../layout/Section.svelte";
  import BxlMapDoctors from "../charts/BxlMapDoctors.svelte"
	import Em from "../ui/Em.svelte";
	import { _ } from 'svelte-i18n'

	// SCROLLYTELLING CONFIG
	// Config
	const threshold = 0.8;
	// State
	let index = [];
	let indexPrev = [];
	onMount(() => {
		indexPrev = [...index];
	});
	
	const chartOptions = [ 
		{ show: ['doctors']}, 
		{ show: ['doctors', 'pénuries']}, 
		{ show: ['doctors', 'pénuries', 'maisonMed']},
		{ show: ['doctors', 'pénuries', 'maisonMed', 'hopitals']},
	];
	let options = chartOptions[0];

	// Reactive code to trigger CHART actions
	$: if (index[0] != indexPrev[0]) {
		if (chartOptions[+index[0]]) {
			options = chartOptions[+index[0]];
		}
		indexPrev[0] = index[0];
	}

	let w;
</script>

<Section>
	<div class="col-medium">
		{@html $_('chap2.p40')}
	</div>
</Section>

<Scroller {threshold} bind:index={index[0]} splitscreen={true}>
	<div slot="background">
		<figure>
			<div class="col-wide height-full middle">
				<div class="chart">
					<BxlMapDoctors options={options}/>
				</div>
			</div>
		</figure>
	</div>

	<div slot="foreground">
		<section style="padding: 40vh 0 80vh 0;">
			<div class="col-medium">
				{@html $_('chap2.p41')}
			</div>
		</section>
		<section>
			<div class="col-medium">
				{@html $_('chap2.p42')}
			</div>
		</section>
		<section>
			<div class="col-medium">
				{@html $_('chap2.p43')}
			</div>
		</section>
		<section>
			<div class="col-medium">
				{@html $_('chap2.p44')}
			</div>
		</section>
	</div>
</Scroller>

<Section>
	{@html $_('chap2.p45')}
	<blockquote class="text-indent">{@html $_('chap2.p46')}</blockquote>
	{@html $_('chap2.p47')}
</Section>

<style>
	.chart {
		margin-top: 10vh;
		margin-bottom: 10vh;
		height: 80vh;
	}
</style>