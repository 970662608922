<script>
  import BruxellesAilleurs from './BruxellesAilleurs.svelte'
  import Scroller from "../layout/Scroller.svelte";
  import { locale, _ } from 'svelte-i18n'

	import Arrow from '../ui/Arrow.svelte'
	let w;
  let wVideo;

  function replaceStateWithQuery (values) {
    const url = new URL(window.location.toString());
    for (let [k, v] of Object.entries(values)) {
      if (!!v) {
        url.searchParams.set(encodeURIComponent(k), encodeURIComponent(v));
      } else {
        url.searchParams.delete(k);
      }
    }
    history.replaceState({}, '', url);
  };
</script>

<div bind:clientWidth={w} id="introduction">
  <Scroller threshold={0.9}>
    <div slot="background">
      <figure>
        <div class="col-full">
          <img src='img/illustrations/medor_bruxelles_malade_introduction_1600x1060_feutre.jpg' style="object-fit: cover; object-position: 25% top; width: 100%; min-height: 100vh;" alt="Introduction"/>
        </div>
      </figure>
    </div>
  
    <div slot="foreground">
      <div style="text-align: center; padding-top: 1.5rem; padding-bottom: 1.5rem;">
        <img
          style="max-width: 70%; width: 420px;"
          class="logo-img"
          src="./img/medor-logo.svg"
          alt="Medor Logo" />
      </div>
      <section style="padding: 1.5rem 0 80vh 0;">
        <div class="col-medium" style="border: solid 1px black;">
            <div style="padding: 8px;">
              <div class="title-font" style={w < 1200 ? "text-align: center;" : "text-align: center;"}>{$_('intro.title')}</div>
              <div class="sub-title-font" style="text-align: center; font-size: 40px;">{$_('intro.subTitle')}</div>
              <div class="text-font" style="text-align: left; margin-top: 32px;">
                <div style="text-align: center; font-family: Belgika; margin-top: -8px; margin-bottom: 16px;">
                  <span 
                    style="text-decoration: {$locale && $locale === 'fr' ? 'underline' : ''}; cursor: pointer;" 
                    on:click={() => {
                      locale.set('fr');
                      replaceStateWithQuery({lang: 'fr'})
                    }}
                  >
                    FR
                  </span>
                  <span 
                  style="text-decoration: {$locale && $locale === 'nl' ? 'underline' : ''}; cursor: pointer;" 
                    on:click={() => {
                      locale.set('nl');
                      replaceStateWithQuery({lang: 'nl'})
                    }}
                  >
                    NL
                  </span>
                  <span 
                    style="text-decoration: {$locale && $locale === 'en' ? 'underline' : ''}; cursor: pointer;" 
                    on:click={() => {
                      locale.set('en');
                      replaceStateWithQuery({lang: 'en'})
                    }}
                  >
                    EN
                  </span>
                </div>
                <p>{$_('intro.p1')}</p>
                <p>{@html $_('intro.p2')}</p>
                <p>{@html $_('intro.p3')}</p>
                <p>{@html $_('intro.p4')}</p>
                <p>{@html $_('intro.p5')}</p>
              </div>
              <div class="credit-font" style={`${w < 1200 ? "font-size: 13px;" : "font-size: 16px;"} margin-top: 32px; text-align: center; font-family: Belgika;`}>
                <div>{@html $_('intro.by')}</div>
                <div><a style="text-decoration: none;" href="https://www.instagram.com/joiecatherine/" target="_blank" alt="Catherine Joie">Catherine Joie</a> – {@html $_('intro.journaliste')}</div>
                <div><a style="text-decoration: none;" href="https://twitter.com/karim_douieb" target="_blank" alt="Karim Douieb">Karim Douieb</a> – {@html $_('intro.dataDesigner')} @ <a href="https://jetpack.ai" target="_blank" alt="jetpackAI">Jetpack.AI</a></div>
                <div><a style="text-decoration: none;" href="https://twitter.com/q_noirfalisse" target="_blank" alt="Quentin Noirfalisse">Quentin Noirfalisse</a> - {@html $_('intro.producteur')} @ <a href="https://dancingdog.be/" target="_blank" alt="Dancing Dog Productions">Dancing Dog Productions</div>
                <div><a style="text-decoration: none;" href="https://www.linkedin.com/in/antoine-sanchez-a4993866/" target="_blank" alt="Antoine Sanchez">Antoine Sanchez</a> - {@html $_('intro.monteur')} @ <a href="https://dancingdog.be/" target="_blank" alt="Dancing Dog Productions">Dancing Dog Productions</div>
                <div><a style="text-decoration: none;" href="https://www.instagram.com/adrienherda" target="_blank" alt="Adrien Herda">Adrien Herda</a> - {@html $_('intro.illustrateur')}</div>
                <div style="margin-top: 16px;">{@html $_('intro.publié')} </div>
              </div>
              <div bind:clientWidth={wVideo} style="margin-top: 64px;">
                <iframe title="vimeo-player" src="{$locale.startsWith('fr') ? 'https://player.vimeo.com/video/709170068?h=acd261d9f3?' : $locale.startsWith('en') ? 'https://player.vimeo.com/video/751608247?h=8300085783' : 'https://player.vimeo.com/video/753811959?h=ecd10094bd'}" width={wVideo} height={wVideo/640*360} frameborder="0" allowfullscreen></iframe>
              </div>
              <div class="text-font" style="text-align: center; margin-top: 32px;">
                <Arrow/>
              </div>
            </div>
        </div>
      </section>
      <section>
        <div class="col-medium">
          <BruxellesAilleurs />
        </div>
      </section>
      <section>
        <div class="col-medium">
          <p>
            {@html $_('intro.p10')}
            <br>
            {@html $_('intro.p11')}
          </p>
        </div>
      </section>
      <section>
        <div class="col-medium">
          <p>
            {@html $_('intro.p12')}
            <br/>
            {@html $_('intro.p13')} 
          </p>
        </div>
      </section>
      <section>
        <div class="col-medium">
          <p>
            {@html $_('intro.p14')} 
          </p>
          <blockquote>{@html $_('intro.p15')}</blockquote>
        </div>
      </section>
      <section>
        <div class="col-medium">
          <p>
            {@html $_('intro.p16')}
          </p>
        </div>
      </section>
      <section>
        <div class="col-medium">
          <p>
            {@html $_('intro.p17')}
          </p>
          <p>
            {@html $_('intro.p18')}
          </p>
        </div>
      </section>
    </div>
  </Scroller> 
</div>

<style>
</style>