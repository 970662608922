<script>
	import { onMount } from "svelte";
	import Scroller from "../layout/Scroller.svelte";
  import Section from "../layout/Section.svelte";
  import UrgenceAmbulatoiresPlot from "../charts/UrgenceAmbulatoiresPlot.svelte";
	import { themes } from "../config.js";
	import { _ } from 'svelte-i18n'

	// SCROLLYTELLING CONFIG
	// Config
	const threshold = 0.8;
	// State
	let index = [];
	let indexPrev = [];
	onMount(() => {
		indexPrev = [...index];
	});
	
	// Actions for CHART scroller
	const chartOptions = [ 
		{ showTrend: false, highlightCommunes: false, showEvolution: false}, 
		{ showTrend: false, highlightCommunes: true, showEvolution: false},
		{ showTrend: true, highlightCommunes: false, showEvolution: false},
		{ showTrend: true, highlightCommunes: false, showEvolution: true},
	];
	let options = chartOptions[0];

	// Reactive code to trigger CHART actions
	$: if (index[0] != indexPrev[0]) {
		if (chartOptions[+index[0]]) {
			options = chartOptions[+index[0]];
		}
		indexPrev[0] = index[0];
	}

	let w;
</script>

<Section>
	{@html $_('chap2.p48')}
	<ul>
		<li>{@html $_('chap2.p49')}</li>
		<li>{@html $_('chap2.p50')} <span class="caption">({@html $_('base.source')}: <a alt="Observatoire de la Santé et du Social, novembre 2020" target="_blank" href="https://www.ccc-ggc.brussels/sites/default/files/documents/graphics/dossiers/dossier_2019-2_inegalites_sociales_sante.pdf">{@html $_('base.obs')}</a>)</span></li>
	</ul>
	{@html $_('chap2.p51')}
</Section>

<Scroller {threshold} bind:index={index[0]} splitscreen={true}>
	<div slot="background">
		<figure>
			<div class="col-wide height-full middle">
				<div class="chart">
					<UrgenceAmbulatoiresPlot options={options}/>
					<caption style="color: {themes['light']['text']}; background-color: {themes['light']['background']};">
						<div class="col-medium">
							<div class="caption" style="text-align: center;">{@html $_('base.source')}: <a alt="Le recours aux urgences hospitalières par les Bruxellois" target="_blank" href="https://www.brudoc.be/opac_css/doc_num.php?explnum_id=2210">{@html $_('chap2.p52')}</a></div>
						</div>
					</caption>
				</div>
			</div>
		</figure>
	</div>

	<div slot="foreground">
		<section style="padding: 40vh 0 80vh 0;">
			<div class="col-medium">
				{@html $_('chap2.p53')}
			</div>
		</section>
		<section>
			<div class="col-medium">
				{@html $_('chap2.p54')}
			</div>
		</section>
		<section>
			<div class="col-medium">
				{@html $_('chap2.p55')}
			</div>
		</section>
		<section>
			<div class="col-medium">
				{@html $_('chap2.p56')}
				<p>{@html $_('chap2.p57')} <span class="caption">({@html $_('base.source')} : <a alt="Observatoire de la Santé et du Social" target="_blank" href="https://www.ccc-ggc.brussels/sites/default/files/documents/graphics/dossiers/dossier_2019-1_recours-urgences-fr.pdf">{@html $_('chap2.p58')}</a>)</span></p>
			</div>
		</section>
	</div>
</Scroller>

<Section>
	{@html $_('chap2.p59')}
	{@html $_('chap2.p60')}
	{@html $_('chap2.p61')}
	{@html $_('chap2.p62')}
	{@html $_('chap2.p63')}
	{@html $_('chap2.p64')}
	<p style="margin-bottom: 64px;">{@html $_('chap2.p65')}</p>
</Section>

<style>
	.chart {
		margin-top: 10vh;
		margin-bottom: 10vh;
		height: 80vh;
	}
</style>