<script>
  import Section from "../layout/Section.svelte";
	import Divider from "../layout/Divider.svelte";
	import ShareButtons from './ShareButtons.svelte';
	import { locale,_ } from 'svelte-i18n'

	let w;
	let wVideo;
	let agree = null;
</script>
<div bind:clientWidth={w}></div>

<Section>
	{@html $_('chap3.p39')}
  {@html $_('chap3.p40')}
  {@html $_('chap3.p41')}
  {@html $_('chap3.p42')}
  <blockquote class="text-indent">{@html $_('chap3.p43')} &mdash; <a style="color: #777" target="_blank" href="https://www.ccc-ggc.brussels/sites/default/files/documents/graphics/dossiers/dossier_2021_fr_etude_sante_environnement_esp_ulb.pdf">{@html $_('chap3.p44')}</a></blockquote>
	{@html $_('chap3.p45')}
	{@html $_('chap3.p46')}
	{@html $_('chap3.p47')}
	{@html $_('chap3.p48')}
</Section>

<Section>
	{@html $_('chap3.p49')}
	<div bind:clientWidth={wVideo}>
		<iframe title="vimeo-player" src="{$locale.startsWith('fr') ? 'https://player.vimeo.com/video/709169611?h=825bb2f170' : $locale.startsWith('en') ? 'https://player.vimeo.com/video/751607615?h=4913782744' : 'https://player.vimeo.com/video/753812100?h=23b1d0cf5c'}" width={wVideo} height={wVideo/640*360} frameborder="0" allowfullscreen></iframe>
	</div>
	<caption>
		<div class="col-medium">
			<div class="caption">Catherine BOULAND – ULB</div>
		</div>
	</caption>
	{@html $_('chap3.p50')}
	{@html $_('chap3.p51')}
	{@html $_('chap3.p52')}
	{@html $_('chap3.p53')}
	{@html $_('chap3.p54')}
	<div>
		<iframe title="vimeo-player" src="{$locale.startsWith('fr') ? 'https://player.vimeo.com/video/709169739?h=9642f0f48e' : $locale.startsWith('en') ? 'https://player.vimeo.com/video/751607767?h=fd2464f537' : 'https://player.vimeo.com/video/753812884?h=3579bf6c24'}" width={wVideo} height={wVideo/640*360} frameborder="0" allowfullscreen></iframe>
	</div>
	<caption>
		<div class="col-medium">
			<div class="caption">Elisa DONDERS – Perspective Brussels</div>
		</div>
	</caption>
	{@html $_('chap3.p55')}
	{@html $_('chap3.p56')}
	{@html $_('chap3.p57')}
	<div>
		<iframe title="vimeo-player" src="{$locale.startsWith('fr') ? 'https://player.vimeo.com/video/709169955?h=f62d00d0f6' : $locale.startsWith('en') ? 'https://player.vimeo.com/video/751607936?h=2cdedf9ea4' : 'https://player.vimeo.com/video/753814502?h=be83576326' }" width={wVideo} height={wVideo/640*360} frameborder="0" allowfullscreen></iframe>
	</div>
	<caption>
		<div class="col-medium">
			<div class="caption">Mathilde BERLANGER – Perspective Brussels</div>
		</div>
	</caption>
	{@html $_('chap3.p58')}
</Section>
<Divider/>
<Section>
	<p style="margin-top: 64px;">{@html $_('chap3.p59')}
	</p>
	<p style="text-align: center">
		<button 
			class="matter-button-{agree !== null && agree === true ? 'contained' : 'outlined'}" 
			on:click={() => agree = true}
		>
			{@html $_('chap3.p60')}
		</button>
		<button 
			class="matter-button-{agree !== null && agree === false ? 'contained' : 'outlined'}" 
			on:click={() => agree = false}
		>
			{@html $_('chap3.p61')}
		</button>
	</p>

	{#if agree !== null && agree === true} 
		{@html $_('chap3.p62')}
		<div style="text-align: center; margin-top: 32px;">
			<ShareButtons/>
		</div>
	{:else if agree !== null && agree === false}
		<p>{@html $_('chap3.p63')}&nbsp;: <a href="sante@medor.coop">pilotes@medor.coop</a></p>
	{/if}

</Section>

<Divider/>
<Section>	
	{@html $_('chap3.p64')}
	<div style="margin-top: 32px;">
		<a class="taxonomy__item" href="https://medor.coop/search/?query=santé" target="_blank" alt="santé">{@html $_('chap3.p65')}</a>
		<a class="taxonomy__item" href="https://medor.coop/search/?query=Bruxelles" target="_blank" alt="Bruxelles">{@html $_('chap3.p66')}</a>
		<a class="taxonomy__item" href="https://medor.coop/search/?query=précarité" target="_blank" alt="précarité">{@html $_('chap3.p67')}</a> 
		<a class="taxonomy__item" href="https://medor.coop/search/?tag=ville" target="_blank" alt="villes">{@html $_('chap3.p68')}</a> 
	</div>
</Section>

<style>
	.taxonomy__item { 
		font-family: 'Belgika';
		background-color: #FCF9F9;
		border-radius: 40px;
		min-width: 60px;
		padding: 1rem;
    text-align: center;
		border: 1px solid #171216;
		text-decoration: none;
		font-size: 13px;
		line-height: 58px;
	}
</style>