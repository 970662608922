<script>
  import Section from "../layout/Section.svelte";
  import PrecarityMarimekko from "../charts/PrecarityMarimekko.svelte"
	import { themes } from "../config.js";
	import { locale,_ } from 'svelte-i18n'

	let w
	let wVideo;
</script>

<Section>
	{@html $_('chap1.p46')}
</Section>

<div bind:clientWidth={w}>
	{#if w !== undefined}
		<div style={`margin: auto; width: ${w < 900 ? 96 : 70}vw !important;`}>
			<PrecarityMarimekko h={w < 900 ? w*1.5 : w*0.5*0.8}/>
			<div style="color: {themes['light']['text']}; background-color: {themes['light']['background']};">
				<div class="col-medium" style="margin: auto;">
					<div class="caption" style="text-align: center; font-size: { w < 900 ? '10px' : '15px'}; margin-top: { w < 900 ? '32px' : '32px'}">{@html $_('base.source')}: <a href="https://dwh-live.bcss.fgov.be/fr/dwh/dwh_page/content/websites/datawarehouse/menu/application-web-chiffres-locaux.html" target="_blank" alt="BCSS">{@html $_('base.bcss')}</a></div>
					<div class="caption" style="text-align: center; font-size: '10px'; margin-top: 8px">{@html $_('chap1.p47')}</div>			
				</div>
			</div>
		</div>
	{/if}
</div>

<Section>
	{@html $_('chap1.p48')}
</Section>

<Section>
	{@html $_('chap1.p49')}
	<blockquote>{@html $_('chap1.p50')}</blockquote>
	<div bind:clientWidth={wVideo}>
		<iframe title="vimeo-player" src="{$locale.startsWith('fr') ? 'https://player.vimeo.com/video/711730470?h=26fd4edc86' : $locale.startsWith('en') ? 'https://player.vimeo.com/video/751607685?h=3019a4fa16' : 'https://player.vimeo.com/video/753812465?h=8479266107'}" width={wVideo} height={wVideo/640*360} frameborder="0" allowfullscreen></iframe>
	</div>
	<caption>
		<div class="col-medium">
			<div class="caption">Chloé SALEMBIER – UCLouvain</div>
		</div>
	</caption>
	{@html $_('chap1.p51')}
</Section>
<style>
</style>