<script>
	import { onMount } from 'svelte';
	import * as d3core from 'd3';
	import * as d3annotation from 'd3-svg-annotation';
	import _ from 'lodash';
	import { location } from '../store.js';
	import sectors from "../assets/data/sectorData.json"
	import { _ as i18n, locale } from 'svelte-i18n'

	const d3 = {...d3core, ...d3annotation}

	let el;
	let w;
	export let h;
	let width;
	let height;
	let dimensions;
	let crossDimMySector;
	let crossDimSectors;
	let crossDimMeans;
	let dim;
	let line;
	let diff;
	export let options;

	onMount(() => {
		const margin = { top: 20, bottom: 5, left: 20, right: 20}
		width = w - margin.left - margin.right
		height = h - margin.top - margin.bottom
				
		const svg = d3.select(el)
		  .append("svg")
			.style('overflow', 'visible')
			.attr("width", width + margin.left + margin.right)
			.attr("height", height + margin.top + margin.bottom)

		svg
			.append('svg:defs')
			.append('svg:marker')
			.attr('id', 'triangle')
			.attr('refX', 6)
			.attr('refY', 6)
			.attr('markerWidth', 30)
			.attr('markerHeight', 30)
			.attr('markerUnits', 'userSpaceOnUse')
			.attr('orient', 'auto')
			.append('path')
			.attr('d', 'M 0 0 12 6 0 12 3 6')
			.style('fill', 'black');

		
		const main = svg.append('g')
			.attr("transform", `translate(${margin.left}, ${margin.top})`)

		// main.append("svg:image")
		// 	.attr('x', 0)
		// 	.attr('y', -8)
		// 	.attr('width', 32)
		// 	.attr('height', 32)
		// 	.attr("xlink:href", "./img/negative-vote.png")

		// main.append("svg:image")
		// 	.attr('x', width -32)
		// 	.attr('y', -8)
		// 	.attr('width', 32)
		// 	.attr('height', 32)
		// 	.attr("xlink:href", "./img/positive-vote.png")

		crossDimSectors = main.append('g').style("opacity", options.show.includes('sectors') && !options.show.includes('means') ? 0.6 : 0.2)
		crossDimMySector = main.append('g')
		crossDimMeans = main.append('g').style("opacity", options.show.includes('means') ? 1 : 0)
		const linearDimensions = main.append('g')

		const xScaleBIM = d3.scaleLinear()
			.domain([0, 75])
			.range([0, width])
			.clamp(true)

		const xScaleNo2 = d3.scaleLinear()
			.domain([15, 36])
			.range([0, width])
			.clamp(true)

		const xScalePm25 = d3.scaleLinear()
			.domain([9, 13])
			.range([0, width])
			.clamp(true)

		const xScaleGreen = d3.scaleLinear()
			.domain([0, 0.9])
			.range([width, 0])
			.clamp(true)

		const xScaleNoise = d3.scaleLinear()
			.domain([50, 70])
			.range([0, width])
			.clamp(true)

		const dimCount = 5
		dim = [
			{
				label: $i18n('plot.xScaleBIM'),
				class: 'xScaleBIM',
				xScale: xScaleBIM,
				key: "Droit à l'intervention majorée|2018",
				h: height / dimCount,
				format: d => `${d}%`,
			},
			{
				label: $i18n('plot.xScaleNo2'),
				class: 'xScaleNo2',
				xScale: xScaleNo2,
				key: "no2",
				h: height / dimCount,
			},
			{
				label: $i18n('plot.xScalePm25'),
				class: 'xScalePm25',
				xScale: xScalePm25,
				key: "pm25",
				h: height / dimCount,
			},
			{
				label: $i18n('plot.xScaleGreen'),
				class: 'xScaleGreen',
				xScale: xScaleGreen,
				key: "greenCoverage",
				h: height / dimCount,
				format: d3.format('0.0%')
			},
			{
				label: $i18n('plot.xScaleNoise'),
				class: 'xScaleNoise',
				xScale: xScaleNoise,
				key: "noise_mean",
				h: height / dimCount,
			}
		].map((dim, i) => ({
			...dim, 
			y: dim.h * i, 
			xAxis: d3.axisBottom(dim.xScale)
				.tickFormat(dim.format ? dim.format : null)
				.ticks(w < 900 ? 6 : 9),
			meanMin: dim.xScale(d3.sum(sectors.filter(s => s.classe === 0), s => s[dim.key] * s["population"])/d3.sum(sectors.filter(s => s.classe === 0), s => s["population"])) + 8,
			meanMax: dim.xScale(d3.sum(sectors.filter(s => s.classe === 4), s => s[dim.key] * s["population"])/d3.sum(sectors.filter(s => s.classe === 4), s => s["population"])) - 8,
		}))

		dimensions = linearDimensions.selectAll(".dim")
			.data(dim)
			.join(enter => enter
				.append("g")
				.attr("class", "dim")
				.attr("transform", d => `translate(0, ${d.y + d.h/2})`)
				.call(enter => enter
					.append("g")
					.attr("class", "xAxis")
				)
				.call(enter => enter
					.append("g")
					.attr("class", `label`)
					.append("text")
					.attr("class", d => `label-${d.class}`)
					.attr("y", w < 900 ? - 10 : - 20)
					.style('font-size', w < 900 ? 12 : 14)
					.style("font-family", 'Alfphabet')
					.text(d => d.label)
					.call(text => text.clone(true))
					.attr("fill", "none")
					.attr("stroke", "white")
					.attr("stroke-width", 2)
					.style('opacity', 0.9)
				)
				.call(enter => enter
					.append("text")
					.attr("class", "horizontal-arrow")
					.attr("x", -15)
					.attr("y", 13)
					.style('font-size', 30)
					.style('text-anchor', 'end')
					.style("font-family", '-apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Helvetica, Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol"')
					.text(d => '→')
					.style("opacity", options.show.includes('horizontalArrows') ? 1 : 0)
				)
			)

			diff = linearDimensions.selectAll(".diff")
			.data(dim)
			.join(enter => enter
				.append("g")
				.attr("class", "diff")
				.attr("transform", d => `translate(0, ${(d.y + d.h/2) - 0 })`)
				.call(enter => enter
					.append("line")
					.attr("x1", d => d.meanMin)
					.attr("y1", 0)
					.attr("x2", d => d.meanMax)
					.attr("y2", 0)
					.attr("fill", "none")
					.attr("stroke", "black")
					.attr("stroke-width", 3)
					.attr('marker-end', 'url(#triangle)')
				)
				.call(enter => enter
					.append("line")
					.attr("x1", d => d.meanMax)
					.attr("y1", 0)
					.attr("x2", d => d.meanMin)
					.attr("y2", 0)
					.attr("fill", "none")
					.attr("stroke", "black")
					.attr("stroke-width", 3)
					.attr('marker-end', 'url(#triangle)')
				)
			)

		dimensions.each(function (d, i) {
			d3.select(this).select(".xAxis").call(d.xAxis)
  			.select(function() { return this.parentNode.appendChild(this.cloneNode(true))})
		});

		line = d3.line()
			.x(d => d.x)
			.y(d => d.y)
			.curve(d3.curveMonotoneY)
		
		const colorScale = d3
			.scaleThreshold()
			.domain([11,14,18,24,29,34,41,50])
			.range(["#00939C","#49A6AE","#65B3BA","#B2DCDF","#FEEEE8","#F8C0AA","#EC9370","#DA6436","#C22E00"])

		const sectorLines = _(sectors)
			.orderBy("Droit à l'intervention majorée|2018")
			.map((s, i) => {
				return {
					...s,
					path: line(dim.map(d => ({ x: d.xScale(s[d.key]), y: d.y + d.h/2 }))),
					color: colorScale(s["Droit à l'intervention majorée|2018"])//i < sectors.length*0.2 ? "#00939C" : (i > sectors.length*0.8 ? "#C22E00" : 'lightgrey')
				}
			})
			.value()

		crossDimSectors.selectAll('line')
			.data(sectorLines.filter(sec => sec["Droit à l'intervention majorée|2018"]))
			.join("path")
				.attr('d', d => d.path)
				.attr('fill', 'none')
				.attr('stroke', d => d.color)
				.attr('stroke-width', 1)
				//.style("opacity", options.show.includes('sectors') && !options.show.includes('means') ? 0.6 : 0.2)

		const meanLines = _(sectors)
				.groupBy('classe')
				.map(sec => {
					return {
						path: line(dim.map(d => ({ x: d.xScale(d3.sum(sec, s => s[d.key] * s["population"])/d3.sum(sec, s => s["population"])), y: d.y + d.h/2 }))),
						color: colorScale(d3.sum(sec, s => s["Droit à l'intervention majorée|2018"] * s["population"])/d3.sum(sec, s => s["population"]))
					}
				})
				.take(5)
				.value()

		crossDimMeans.selectAll('line')
			.data(meanLines)
			.join("path")
				.attr('d', d => d.path)
				.attr('fill', 'none')
				.attr('stroke', "white")
				.attr('stroke-width', 6)
				.attr('opacity', 1)
				.attr('stroke-linecap, "round"')

		crossDimMeans.selectAll('line')
			.data(meanLines)
			.join("path")
				.attr('d', d => d.path)
				.attr('fill', 'none')
				.attr('stroke', d => d.color)
				.attr('stroke-width', 4)
				.attr('opacity', 1)
				.attr('stroke-linecap, "round"')
	});

	function setLocation() {
		const mySector = $location ? sectors.find(s => s.cd_sector === $location.sectorId) : null
		if(crossDimMySector && mySector && mySector[dim[0].key]) {
			const data = [{
					id: $location.sectorId,
					label: { x: dim[0].xScale(mySector[dim[0].key]), y: 0, t2: `${$location.sector} ${$location.commune}`, t1: $i18n('base.quartier')},
					path: line([{ x: dim[0].xScale(mySector[dim[0].key]), y: 0}].concat(dim.map(d => ({ x: d.xScale(mySector[d.key]), y: d.y + d.h/2 })))),
					color: 'grey'
				}]

			crossDimMySector.style('opacity', 1)
			crossDimMySector
				.selectAll('.my-sector')
				.data(data, d => d.id)
				.join(enter => 
					enter.append('g')
					.attr('class', 'my-sector')
					.call(enter => enter
						.append("text")
						.attr('class', 'ton-quartier')
						.attr('x', d => d.label.x + (d.label.x > w / 2 ? -10 : 10))
						.attr('y', d => d.label.y)
						.attr('fill', 'grey')
						.style('font-family', 'Alfphabet')
						.style('font-size', 16)
						.attr('text-anchor', d => d.label.x > w / 2 ? 'end' : 'start')
						.text(d => d.label.t1)
					)
					.call(enter => enter
						.append("text")
						.attr('x', d => d.label.x + (d.label.x > w / 2 ? -10 : 10))
						.attr('y', d => d.label.y + 20)
						.attr('fill', 'grey')
						.style('font-family', 'Alfphabet')
						.style('font-size', 16)
						.attr('text-anchor', d => d.label.x > w / 2 ? 'end' : 'start')
						.text(d => d.label.t2)
					)
					.call(enter => enter
						.append("path")
						.attr('d', d => d.path)
						.attr('fill', 'none')
						.attr('stroke', "white")
						.attr('stroke-width', 3.5)
						.attr('opacity', 0.8)
					)
					.call(enter => enter
						.append("path")
						.attr('d', d => d.path)
						.attr('fill', 'none')
						.attr('stroke', d => d.color)
						.attr('stroke-width', 2)
						.attr('opacity', 1)
					),
					update => update,
					exit => exit.remove()
				)
			} else if(crossDimMySector) {
				crossDimMySector.style('opacity', 0)
			}
	}
	
	$: $location, setLocation()

	function update() {
		if(dimensions){
			dimensions.selectAll('.horizontal-arrow').style("opacity", options.show.includes('horizontalArrows') ? 1 : 0)
		}

		if(crossDimSectors){
			crossDimSectors.style("opacity", options.show.includes('sectors') && !options.show.includes('means') ? 0.6 : 0.2)
		}

		if(crossDimMeans){
			crossDimMeans.style("opacity", options.show.includes('means') ? 1 : 0)
		}

		if(dimensions){
			dimensions.style("opacity", d => !options.focus || options.focus.includes(d.key) ? 1 : 0.2)
		}

		if(diff){
			diff.style("opacity", d => options.show.includes(`diff-${d.key}`) ? 1 : 0)
		}
	}

	$: options, update()

	function setLocale() {
		if(dimensions){
			[
				{
					label: $i18n('plot.xScaleBIM'),
					class: 'xScaleBIM', 
				},
				{
					label: $i18n('plot.xScaleNo2'),
					class: 'xScaleNo2', 
				},
				{
					label: $i18n('plot.xScalePm25'),
					class: 'xScalePm25', 
				},
				{
					label: $i18n('plot.xScaleGreen'),
					class: 'xScaleGreen', 
				},
				{
					label: $i18n('plot.xScaleNoise'),
					class: 'xScaleNoise', 
				}
			].forEach((dim, i) => {
				dimensions.selectAll(`.label-${dim.class}`)
					.text(dim.label)
			})
		}

		if(crossDimMySector){
			crossDimMySector.selectAll('.ton-quartier')
				.text($i18n('base.quartier'))
		}
	}

	$: $locale, setLocale()
</script>

<style>
</style>

<div bind:this={el} bind:clientWidth={w} style="background-color: #fff0; padding: 0px;"></div>
