<script>
	import { onMount } from 'svelte';
	import * as d3core from 'd3';
	import * as d3annotation from 'd3-svg-annotation';
	import _ from 'lodash'
	import { _ as i18n, locale } from 'svelte-i18n'

	import brusselsCommunes from "../assets/maps/brusselsCommunes.json"
	import sectorData from "../assets/data/sectorData.json"
	import brusselsSectors from "../assets/maps/brusselsSectors.json"
	import cartogramPaths from "../assets/data/cartogramPaths.json"
	import { location } from '../store.js';
	import flubber from 'flubber'

	const d3 = {...d3core, ...d3annotation}

	let el;
	let w;
	export let h;
	let projection;
	let path;
	let main;
	let width;
	let height;
	let locationLayer;
	let annotations;
	let verdure;
	let verdureSectors;
	let verdureSectorsLegend;
	let bivariateSectorsLegend;
	let colorGreenCoverage;
	let colorBivariate;
	let greenCoverScale;
	let pm25Scale;
	let data;
	let pointers;
	let noise;

	const bivariateColors = [
		"#e8e8e8", "#ace4e4", "#5ac8c8",
		"#dfb0d6", "#a5add3", "#5698b9", 
		"#be64ac", "#8c62aa", "#3b4994"
	]

	export let step;
	export let transformationProgress;

	onMount(() => {
		const margin = { top: 10, bottom: 10, left: 10, right: 10}
		width = Math.min(w, h) - margin.left - margin.right
		height = width - margin.top - margin.bottom

		projection = d3.geoMercator()
  		.fitExtent([[5, 5], [width, height]], brusselsSectors);

		path = d3.geoPath(d3.geoIdentity())
    	.projection(projection);
		
		const svg = d3.select(el)
		  .append("svg")
			.attr("width", w + margin.left + margin.right)
			.attr("height", height + margin.top + margin.bottom)
			.style('overflow', 'visible')
		
		main = svg.append('g')
			.attr("transform", `translate(${margin.left + (w - width) / 2}, ${Math.max(margin.top, (h-w) / 2)})`)

		verdure = main.append('g')
		showVerdure()

		verdureSectors = main.append('g')
		showVerdureSectors()

		verdureSectorsLegend = verdureSectors.append('g')
			.attr('transform', `translate(0, ${w < 900 ? -w*0.2: 0})`)
		showVerdureSectorsLegend()

		bivariateSectorsLegend = verdureSectors.append('g')
		showBivariateSectorsLegend()

		noise = main.append('g')
		showNoise()
		showNoiseLegend()

		annotations = main.append('g')
			.attr('class', 'annotations sub-title-font')

		locationLayer = main.append('g')
		setLocation()

		pointers = main.append('g')
		showPointers()

		update()
	});

	function showPointers() {
		pointers.selectAll('.pointer')
			.data([
				{
					origin: { x: width * 0.1, y: height * 0.35},
					destination: { x: width * 0.45, y: height * 0.45},
					r: 1.5,
					text: $i18n("plot.pointer3"),
					align: 'right',
				},
				{
					origin: { x: width * 0.8, y: height * 0.1},
					destination: { x: width * 0.5, y: height * 0.2},
					r: 1.5,
					text: $i18n("plot.pointer4")
				},
				{
					origin: { x: width * 0.7, y: height * 0.8},
					destination: { x: width * 0.8, y: height * 0.95},
					r: 1.5,
					text: $i18n("plot.pointer5"),
					direction: 'oposite'
				},
			])
			.join(enter => enter
				.append('g')
				.attr('class', 'pointer')
				.call(enter => enter
					.append('path')
					.attr('fill', 'none')
					.attr('stroke', 'white')
					.attr('stroke-width', 4)
					.style('stroke-linecap', 'round')
					.attr('d', d => {
						const dx = d.destination.x - d.origin.x
						const dy = d.destination.y - d.origin.y
						const distance = Math.sqrt(Math.pow(dx,2)+Math.pow(dy,2));
						const dr = distance*d.r;
						return `M${d.origin.x},${d.origin.y}A${dr},${dr} 0 0,1 ${d.destination.x},${d.destination.y}`
					})
				)
				.call(enter => enter
					.append('path')
					.attr('fill', 'none')
					.attr('stroke', 'black')
					.attr('stroke-width', 1.5)
					.style('stroke-linecap', 'round')
					.attr('d', d => {
						const dx = d.destination.x - d.origin.x
						const dy = d.destination.y - d.origin.y
						const distance = Math.sqrt(Math.pow(dx,2)+Math.pow(dy,2));
						const dr = distance*d.r;
						return `M${d.origin.x},${d.origin.y}A${dr},${dr} 0 0,1 ${d.destination.x},${d.destination.y}`
					})
				)
				.call(enter => enter
					.append('foreignObject')
					.attr('x', d => (d.direction === 'oposite' ? d.destination.x : d.origin.x) - (d.align === 'right' ? width * 0.2 : -4))
					.attr('y', d => (d.direction === 'oposite' ? d.destination.y : d.origin.y))
					.attr('width', width * 0.2)
					.attr('height', width)
					.append("xhtml:div")
					.style('font-family', 'Alfphabet')
					.html(d => `<div style="font-size: ${Math.round(Math.max(12, width * 0.01))}px;">${d.text}</div>`)
				),
				update => update
					.call(update => update.select('foreignObject')
						.style('font-family', 'Alfphabet')
						.html(d => `<div style="font-size: ${Math.round(Math.max(12, width * 0.01))}px;">${d.text}</div>`))
			)
      ;
	}

	function showNoise() {
		noise.append('image')
			.attr('transform', `translate(${15}, ${7.5})`)
			.attr('href', "./img/noise.jpg")
			.attr('alt', "pollution sonore")
			.attr('height', (height * 1.0040322581) - 10)

		// noise.append('g').selectAll('path')
		// 	.data(brusselsCommunes.features)
		// 	.enter().append('path')
		// 	.attr('class', 'commune')
		// 	.attr('d', path)
		// 	.style('fill', 'none')
		// 	.style('stroke', 'black')
		// 	.style('stroke-width', width * 0.0005)
		// 	.style('stroke-opacity', 1)
	}

	function showNoiseLegend() {
		const legend = noise.append('g')
			.style('font-size', 14)
			.style('font-family', 'Alfphabet')
			.attr('transform', `translate(-20, ${w < 900 ? -w*0.2: 10})`)

		const tickSize = 12;
		const legendWidth = 320;
		const legendHeight = 44 + tickSize;
		const marginTop = 18;
		const marginRight = 0;
		const marginBottom = 26 + tickSize;
		const marginLeft = 0;
		const ticks = width / 64;
		let tickValues;

		const xScale = d3.scaleLinear()
      .domain([35, 80])
      .rangeRound([0, legendWidth])
		
		const colorScale = d3.scaleThreshold()
      .domain([40, 45, 50, 55, 60, 65, 70, 75])
      .range(["#fff7f3", "#fde0dd", "#fcc5c0", "#fa9fb5", "#f768a1", "#dd3497", "#ae017e", "#7a0177", "#49006a"])

		legend.append("g")
      .attr("transform", `translate(0,${legendHeight - marginBottom})`)
			.selectAll("rect")
			.data(colorScale.range().map(function(color) {
				var d = colorScale.invertExtent(color);
				if (d[0] == null) d[0] = xScale.domain()[0];
				if (d[1] == null) d[1] = xScale.domain()[1];
				return d;
			}))
			.enter().insert("rect", ".tick")
				.attr("height", 8)
				.attr("x", function(d) { return xScale(d[0]); })
				.attr("width", function(d) { return xScale(d[1]) - xScale(d[0]); })
				.attr("fill", function(d) { return colorScale(d[0]); })

		legend.append("g")
      .attr("transform", `translate(0,${legendHeight - marginBottom})`)
      .call(d3.axisBottom(xScale)
        .ticks(ticks, typeof tickFormat === "string" ? tickFormat : undefined)
        .tickFormat(d => d === 35 || d === 80 ? '' : d)
        .tickSize(tickSize)
        .tickValues(tickValues))
      //.call(tickAdjust)
      .call(g => g.select(".domain").remove())
      .call(g => g.append("text")
        .attr("x", marginLeft)
        .attr("y", marginTop + marginBottom - legendHeight - 6)
        .attr("fill", "currentColor")
        .attr("text-anchor", "start")
				.attr("font-family", "Alfphabet")
				.style("font-size", "12px")
        .attr("class", "title")
        .text($i18n('noisePolution')));
	}

	function showVerdure() {
		verdure.append('image')
			.attr('transform', `translate(${15}, ${7.5})`)
			.attr('href', "./img/verdure.jpg")
			.attr('alt', "verdure")
			.attr('height', (height * 1.0040322581) - 10)

		verdure.append('g').selectAll('path')
			.data(brusselsCommunes.features)
			.enter().append('path')
			.attr('class', 'commune')
			.attr('d', path)
			.style('fill', 'none')
			.style('stroke', 'black')
			.style('stroke-width', width * 0.0005)
			.style('stroke-opacity', 1)

		const legend = verdure.append('g')
			.style('font-size', 12)
			.style('font-family', 'Alfphabet')
			.attr('transform', `translate(0, ${w < 900 ? -w*0.2: 10})`)

		legend
			.append('circle')
			.attr('cx', d => 0)
			.attr('cy', d => 5)
			.attr('r', 5)
			.style('fill', '#5BBA6F');

		legend
			.append('text')
			.attr('class', 'verdure')
			.attr('x', d => 10)
			.attr('y', d => 10)
			.style('fill', 'black')
			.text($i18n('plot.verdure'));

		legend
			.append('circle')
			.attr('cx', d => 0)
			.attr('cy', d => 25)
			.attr('r', 5)
			.style('fill', '#137547');

		legend
			.append('text')
			.attr('class', 'espaceVert')
			.attr('x', d => 10)
			.attr('y', d => 30)
			.style('fill', 'black')
			.text($i18n('plot.espaceVert'));
	}

	function showVerdureSectors() {
		const scaledCartoPaths = _(cartogramPaths).mapValues(paths => {
			return paths.map(path => 'M' + path.filter(coord => coord.length === 2).map(coord => {
				return (coord[0] * width) + ',' + (coord[1] * width)
			}).join(' L') + 'Z')
		}).value()

		data = brusselsSectors.features
			.map(d => {
				const neighbourhood = sectorData.find(n => n.Code === d.properties.cd_sector);
				const cartogram = scaledCartoPaths[d.properties.cd_sector]
				return {
					...d,
					id: d.properties.cd_sector,
					name: neighbourhood ? neighbourhood.name.split(' (')[0] : null,
					commune: neighbourhood ? neighbourhood.commune : null,
					greenCoverage: neighbourhood ? neighbourhood.greenCoverage : null,
					pm25: neighbourhood ? neighbourhood.pm25 : null,
					interpolator: cartogram.length === 2 ? flubber.interpolateAll(flubber.splitPathString(path(d)), cartogram, { single: true }) : flubber.interpolate(path(d), cartogram[0])
				} 
			})

		colorGreenCoverage = d3.scaleSequential([0, 1], d3.interpolateRdYlGn)

		greenCoverScale = d3.scaleQuantile(_(data).map('greenCoverage').filter().orderBy().value(), [0,1,2])
    pm25Scale = d3.scaleQuantile(_(data).map('pm25').filter().orderBy().value(), [0,1,2])

		colorBivariate = (pm25, greenCoverage)  => {
			return bivariateColors[(3 * pm25Scale(pm25)) + greenCoverScale(greenCoverage)] //bivariateColors[pm25Scale(pm25) + (greenCoverScale(greenCoverage) * 3)];
		};

		data = data.map(d => {
				return {
					...d,
					greenCoverageColor: colorGreenCoverage(d.greenCoverage),
					bivariateColor: d.pm25 && d.greenCoverage ? colorBivariate(d.pm25, d.greenCoverage) : '#ccc',
				} 
			})

		verdureSectors.append('g').selectAll('.sector')
			.data(data)
			.enter().append('path')
			.attr('class', 'sector')
			.attr('d', path)
			.style('fill', d => step <= 2 ? d.greenCoverageColor : d.bivariateColor)
			.style('stroke', 'white')
			.style('stroke-width', 0.5)
			.style('stroke-opacity', 1)
			.append("title")
			.text(d => `${d.name.split(' (')[0]} à ${d.commune}\n% de verdure: ${Math.round(d.greenCoverage*100)}%\nParticules fines: ${Math.round(d.pm25*10)/10} (PM 2.5 µg/m³)`)
	}

	function showVerdureSectorsLegend() {
		verdureSectorsLegend
			.attr('class', 'legend')
			.style('font-size', 12)
			.style('font-family', 'Alfphabet')
			.attr('transform', `translate(-20, ${w < 900 ? -w*0.2: 0})`)

		const tickSize = 6;
		const legendWidth = 320;
		const legendHeight = 44 + tickSize;
		const marginTop = 18;
		const marginRight = 0;
		const marginBottom = 16 + tickSize;
		const marginLeft = 0;
		const ticks = width / 64;
		const tickFormat = "%";
		let tickValues;

		const	x = Object.assign(colorGreenCoverage.copy()
			.interpolator(d3.interpolateRound(marginLeft, legendWidth - marginRight)),
			{range() { return [marginLeft, legendWidth - marginRight]; }});

		function ramp(color, n = 256) {
			const canvas = document.createElement("canvas");
			canvas.width = n;
			canvas.height = 1;
			const context = canvas.getContext("2d");
			for (let i = 0; i < n; ++i) {
				context.fillStyle = color(i / (n - 1));
				context.fillRect(i, 0, 1, 1);
			}
			return canvas;
		}

		verdureSectorsLegend.append("image")
			.attr("x", marginLeft)
			.attr("y", marginTop)
			.attr("width", legendWidth - marginLeft - marginRight)
			.attr("height", legendHeight - marginTop - marginBottom)
			.attr("preserveAspectRatio", "none")
			.attr("xlink:href", ramp(colorGreenCoverage.interpolator()).toDataURL());


		if (!x.ticks) {
      if (tickValues === undefined) {
        const n = Math.round(ticks + 1);
        tickValues = d3.range(n).map(i => d3.quantile(color.domain(), i / (n - 1)));
      }
      if (typeof tickFormat !== "function") {
        tickFormat = d3.format(tickFormat === undefined ? ",f" : tickFormat);
      }
    }

		let tickAdjust = g => g.selectAll(".tick line").attr("y1", marginTop + marginBottom - legendHeight);

		verdureSectorsLegend.append("g")
      .attr("transform", `translate(0,${legendHeight - marginBottom})`)
      .call(d3.axisBottom(x)
        .ticks(ticks, typeof tickFormat === "string" ? tickFormat : undefined)
        .tickFormat(typeof tickFormat === "function" ? tickFormat : undefined)
        .tickSize(tickSize)
        .tickValues(tickValues))
      .call(tickAdjust)
      .call(g => g.select(".domain").remove())
      .call(g => g.append("text")
        .attr("x", marginLeft)
        .attr("y", marginTop + marginBottom - legendHeight - 6)
        .attr("fill", "currentColor")
        .attr("text-anchor", "start")
				.style('font-size', 14)
				.style('font-family', 'Alfphabet')
        .attr("class", "title")
        .text($i18n('plot.pourcentageVerdure')));
	}

	function showBivariateSectorsLegend() {
		bivariateSectorsLegend
			.attr('class', 'legend')
			.attr('transform', w < 900 ? `translate(${width * 0.2}, ${height})` : `translate(${width * 0.08}, ${height-(width * 0.12)})`)

		const g = bivariateSectorsLegend.append('g')
			.style('font-family', 'sans-serif')
			.style('font-size', 10)

		const k = width * 0.06;
		const n = 3;
		const gg = g.append('g')
			.attr('transform', `translate(-${k * n / 2},-${k * n / 2}) rotate(-45 ${k * n / 2},${k * n / 2})`)

		gg.append('marker')
				.attr('id', 'arrow')
				.attr('markerHeight', 10)
				.attr('markerWidth', 10)
				.attr('refX', 6)
				.attr('refY', 3)
				.attr('orient', 'auto')
			.append('path')
				.attr('d', "M0,0L9,3L0,6Z")

		gg.selectAll('rect')
			.data(d3.cross(d3.range(n), d3.range(n)))
			.join('rect')
			.attr('width', k)
			.attr('height', k)
			.attr('x', d => d[0] * k)
			.attr('y', d => (n - 1 - d[1]) * k)
			.attr('fill', d => bivariateColors[d[1] * n + d[0]])

		gg.append('line')
			.attr('marker-end', 'url(#arrow)')
			.attr('x1', 0)
			.attr('x2', n * k)
			.attr('y1', n * k)
			.attr('y2', n * k)
			.style('stroke', 'black')
			.style('stroke-width', w < 900 ? 1 : 1.5)

		gg.append('line')
			.attr('marker-end', 'url(#arrow)')
			.attr('x1', 0)
			.attr('x2', 0)
			.attr('y1', n * k)
			.attr('y2', 0)
			.style('stroke', 'black')
			.style('stroke-width', w < 900 ? 1 : 1.5)	

		gg.append('text')
			.attr('class', 'pm')
			.attr('transform', `rotate(90) translate(${n / 2 * k},6)`)
			.attr('dy', '0.71em')
			.style('font-weight', w < 900 ? "" : "bold")
			.style('text-anchor', "middle")	
			.text($i18n('plot.pm'))

		gg.append('text')
			.attr('class', 'percVerdure')
			.attr('transform', `translate(${n / 2 * k},${n * k + 6})`)
			.attr('dy', '0.71em')
			.style('font-weight', w < 900 ? "" : "bold")
			.style('text-anchor', "middle")	
			.text($i18n('plot.percVerdure'))
	}

	function setLocation() {
		if(locationLayer) {
			locationLayer.selectAll('.pin-back')
				.data($location ? [projection($location.coordinates)] : [])
				.join('circle')
					.attr('class', 'pin-back')
					.attr('cx', d => d[0])
					.attr('cy', d => d[1])
					.attr('r', width * 0.008)
					.attr('fill', 'none')
					.attr('stroke', 'white')
					.attr('stroke-width', width * 0.008)

			locationLayer.selectAll('.pin')
				.data($location ? [projection($location.coordinates)] : [])
				.join('circle')
					.attr('class', 'pin')
					.attr('cx', d => d[0])
					.attr('cy', d => d[1])
					.attr('r', width * 0.008)
					.attr('fill', 'none')
					.attr('stroke', 'grey')
					.attr('stroke-width', width * 0.003)

			locationLayer.style('opacity', step === 2 || step === 3 ? 0 : 1)
		}

		if(annotations && $location) {
			const sector = data.find(c => c.id === $location.sectorId)
			let label;
			if (step === 1) {
				label = sector ? `${Math.round(sector.greenCoverage*100)}${$i18n('percVerdure')}` : null
			} else if(step === 4) {
				const greenCoverLabels = [$i18n('lowVerdure'), $i18n('midVerdure'), $i18n('highVerdure')]
				const pm25Labels = [$i18n('lowPm'), $i18n('midPm'), $i18n('highPm')]
				label = sector ? `${greenCoverLabels[greenCoverScale(sector.greenCoverage)]} (${Math.round(sector.greenCoverage * 100)}%) & ${pm25Labels[pm25Scale(sector.pm25)]} (${Math.round(sector.pm25)} µg/m³ PM2.5) ` : null
			} else {
				label = `${$location.sector} ${$location.commune}`
			}
			const annotationsData = [{
				note: {
					label,
					bgPadding: {"top":15,"left":10,"right":10,"bottom":10},
					title: $i18n('base.quartier') 
				},
				x: projection($location.coordinates)[0] - width * 0.008,
				y: projection($location.coordinates)[1] - width * 0.008,
				ny: width * 0.2,
				nx: width * 0.2,
				color: 'grey',
				//connector: { end: "arrow" },
			}]

			const type = d3.annotationCallout
			const makeAnnotations = d3.annotation()
				.notePadding(4)
				.type(type)
				.textWrap(250)
				.annotations(annotationsData)

			annotations
				.style('font-size', 10)
				.style('stroke-width', 1.5)
				.style('opacity', step === 2 || step === 3 ? 0 : 1)
				//.transition()
				.call(makeAnnotations)
		} else if(annotations) {
			annotations
				.style('opacity', 0)
		}
	}

	$: $location || step || $locale, setLocation()

	function update() {
		if(verdure) {
			verdure.transition()
				.duration(1500)
				.style('opacity', step > 0 ? 0 : 1)
		}

		if(verdureSectors) {
			/*verdureSectors.selectAll('.verdureSectors')
				.transition()
				.duration(1000)
				.delay(1000)
				.style('opacity', step < 1 ? 0 : 1)

			verdureSectors.selectAll('.sector')
				.transition()
				.duration(1000)
				.delay(d => Math.random() * 1000)
				.style('opacity', step < 1 ? 0 : 1)*/
			verdureSectors.transition()
				.duration(1500)
				.style('opacity', step < 1 ? 0 : 1)

			verdureSectors.selectAll('.sector')
				//.attr('d', d => scaledCartoPaths[d.id])
				.style('fill', d => step <= 2 ? d.greenCoverageColor : d.bivariateColor)
				.style('stroke', 'white')
				.style('stroke-width', 0.5)
				.style('stroke-opacity', 1)
		}

		if(bivariateSectorsLegend) {
			bivariateSectorsLegend
				.transition()
				.duration(500)
				.style('opacity', step === 3 || step === 4 ? 1 : 0)
		}

		if(bivariateSectorsLegend) {
			bivariateSectorsLegend
				.transition()
				.duration(500)
				.style('opacity', step === 3 || step === 4 ? 1 : 0)
		}

		if(verdureSectorsLegend) {
			verdureSectorsLegend
				.transition()
					.duration(500)
					.style('opacity', step <= 2 ? 1 : 0)
		}

		if(pointers) {
			showPointers()
			pointers
				.transition()
				.duration(500)
				.style('opacity', step === 3 || step === 4 ? 1 : 0)
		}

		if(noise) {
			noise.transition()
				.duration(500)
				.style('opacity', step > 4 ? 1 : 0)
		}
	}

	$: step, update()


function updateShapes() {
		if(verdureSectors) {
			verdureSectors.selectAll('.sector')
				.attr('d', d => d.interpolator(transformationProgress*1.4))
		}
	}
	$: transformationProgress, updateShapes()

function setLocale() {
		if(verdure){
			verdure.select('.verdure')
				.text($i18n('plot.verdure'))

			verdure.select('.espaceVert')
				.text($i18n('plot.espaceVert'))	
		}

		if(verdureSectorsLegend){
			verdureSectorsLegend.select('.title')
				.text($i18n('plot.pourcentageVerdure'))
		}

		if(bivariateSectorsLegend){
			bivariateSectorsLegend.select('.pm')
				.text($i18n('plot.pm'))

			bivariateSectorsLegend.select('.percVerdure')
				.text($i18n('plot.percVerdure'))
		}

		if(noise){
			noise.select('.title')
				.text($i18n('plot.noisePolution'))
		}

		if(pointers) showPointers()
	}

	$: $locale, setLocale()
</script>

<style>
</style>

<div bind:this={el} bind:clientWidth={w} style="background-color: #fff0; height: 100%;"></div>
<!-- <caption style="color: {themes['light']['text']}; background-color: {themes['light']['background']};">
	<div class="col-medium">
		<div class="caption" style="text-align: center;">Sources: <a href="https://famgb.be/4DCGI/fr/resultats-recherche/" alt="FAMGB" target="_blank">FAMGB</a> & <a alt="OBSERVATOIRE DE LA SANTÉ ET DU SOCIAL" target="_blank" href="https://www.ccc-ggc.brussels/sites/default/files/documents/graphics/dossiers/dossier_2018-02_medecins_generalistes_bruxelles.pdf">OBSERVATOIRE DE LA SANTÉ ET DU SOCIAL</a></div>
	</div>
</caption> -->
<!-- <div>Progress: {progress}</div> -->

