<script>
  import Scroller from "../layout/Scroller.svelte";
  import { location, mainLocation } from '../store.js';
  import { _ } from 'svelte-i18n'
</script>

<div id="chapitre3">
  <Scroller threshold={0.9}>
    <div slot="background">
      <figure>
        <div class="col-full">
          <img src='img/illustrations/medor_bruxelles_malade_environnement_1600x1060_feutre.jpg' style="object-fit: cover; object-position: 50% top; width: 100%; min-height: 100vh;" alt="Chapitre1: "/>
        </div>
      </figure>
    </div>
    <div slot="foreground">
      <section style="padding: 40vh 0 80vh 0;">
        <div class="col-medium" style="text-align: center;">
          <h1 style="font-weight: 500;">{@html $_('chap3.title')}</h1>
          <h1 style="font-weight: 500;">{@html $_('chap3.subTitle')}</h1>
        </div>
      </section>
      <section>
        <div class="col-medium">
          {@html $_('chap3.p1')}
        </div>
      </section>
      <section>
        <div class="col-medium">
          <p>
            {@html $_('chap3.p2')}
          </p>
        </div>
      </section>
      <section>
        <div class="col-medium">
          <p>
            {@html $_('chap3.p3')}
            {#if !$mainLocation || $mainLocation === 'brussels'}
              {@html $_('chap3.p4')}
            {:else}
              {@html $_('chap3.p5')} 
            {/if}
            {@html $_('chap3.p6')}
          </p>
        </div>
      </section>
      <section>
        <div class="col-medium">
          <p>
            {@html $_('chap3.p7')}
          </p>
        </div>
      </section>
      <section>
        <div class="col-medium">
          <p>
            {@html $_('chap3.p8')} 
          </p>
          {@html $_('chap3.p9')}
        </div>
      </section>
    </div>
  </Scroller> 
</div>

<style>
</style>