<script>
	import { onMount } from "svelte";
	import Scroller from "../layout/Scroller.svelte";
	import { themes } from "../config.js";
	import numeral from 'numeral'
	import Section from "../layout/Section.svelte";
	import Divider from "../layout/Divider.svelte";
	import Em from "../ui/Em.svelte";
	import { locale, _ } from 'svelte-i18n'

	import SectorsScatterPlot from '../charts/SectorsScatterPlot.svelte'

	// SCROLLYTELLING CONFIG
	// Config
	const threshold = 0.8;
	// State
	let index = [];
	let indexPrev = [];
	onMount(() => {
		indexPrev = [...index];
	});
	
	// Actions for CHART scroller
	const chartActions = () => [ 
		{ key: 'geo', shortLabel: $_('plot.bim')}, 
		{ key: null}, 
		{ key: null}, 
		{ key: "Âge moyen (année)-2019-", tickFormat: d => `${Math.round(d)}${$_('plot.ans')}`, extent: [25, 55], label: $_('plot.age'), shortLabel: $_('plot.ageShort')}, 
		{ key: "foreignersNonEu15Prop2019", tickFormat: d => `${Math.round(d)}%`, extent: [0, 55], label: $_('plot.natio'), shortLabel: $_('plot.natioShort')},
		{ key: "Superficie moyenne par habitant (m²)-2001-", tickFormat: d => `${Math.round(d*10)/10}m²`, extent: [15, 60], label: $_('plot.logement'), shortLabel: $_('plot.logementShort')},
		{ key: "revenueMensuelMedian2018", tickFormat: d => numeral(d).format(',.0')+'€', extent: [1000, 3200], label: $_('plot.rev'), shortLabel: $_('plot.revShort')}, 
		{ key: "commune", tickFormat: d => numeral(d).format(',.0')+'€', extent: [1000, 2400], label:  $_('plot.rev'), shortLabel: $_('plot.revShort')}, 
		{ key: "commune", tickFormat: d => numeral(d).format(',.0')+'€', extent: [1000, 2400], label:  $_('plot.rev'), shortLabel: $_('plot.revShort'), showHulls: true}, 
	];
	let yAxisSpec = chartActions()[0];

	// Reactive code to trigger CHART actions
	$: if (index[0] != indexPrev[0]) {
		if (chartActions()[+index[0]]) {
			yAxisSpec = chartActions()[+index[0]];
		}
		indexPrev[0] = index[0];
	}

	let w;
	let wVideo;
</script>

<Scroller {threshold} bind:index={index[0]} splitscreen={true}>
	<div slot="background" bind:clientWidth={w}>
		<figure>
			<div class="col-wide height-full middle">
				<div class="chart">
					<SectorsScatterPlot yAxisSpec={yAxisSpec} />
					<caption style="color: {themes['light']['text']}; background-color: {themes['light']['background']};">
						<div class="col-medium">
							<div class="caption" style="text-align: center;">{@html $_('base.source')}: <a alt="AGENCE INTERMUTUALISTE" target="_blank" href="https://atlas.aim-ima.be/">{@html $_('base.aimShort')}</a> & <a alt="MONITEUR DES QUARTIERS IBSA" target="_blank" href="https://monitoringdesquartiers.brussels">{@html $_('base.moniteur')}</a></div>
						</div>
					</caption>
				</div>
			</div>
		</figure>
	</div>

	<div slot="foreground">
		<section style="padding: 20vh 0 80vh 0;">
			<div class="col-medium">
				{@html $_('chap1.p14')}
				{#if w >= 900} <p>{@html $_('chap1.p15')}</p>{/if}
				<p>{@html $_('chap1.p16')}</p>
			</div>
		</section>
		<section>
			<div class="col-medium">
				<p>{@html $_('chap1.p17')}</p> 
				<p>{@html $_('chap1.p18')}</p>
				<p>{@html $_('chap1.p19')}</p>
				<p>{@html $_('chap1.p20')}</p>
				{#if w >= 900} <p>{@html $_('chap1.p21')}</p> {/if}
			</div>
		</section>
		<section>
			<div class="col-medium">
				{@html $_('chap1.p22')}
			</div>
		</section>
		<section>
			<div class="col-medium">
				{@html $_('chap1.p23')}
				<p>{@html $_('chap1.p24')}<span class="caption">({@html $_('base.source')}: <a alt="baromètre social 2020" target="_blank" href="https://www.ccc-ggc.brussels/sites/default/files/documents/graphics/rapport-pauvrete/barometre-welzijnsbarometer/barometre_social_2020.pdf">{@html $_('base.barometre')}</a>)</span></p> 
			</div>
		</section>
		<section>
			<div class="col-medium">
				{@html $_('chap1.p25')}
				<p>{@html $_('chap1.p26')} <span class="caption">({@html $_('base.source')}: <a alt="baromètre social 2020" target="_blank" href="https://www.ccc-ggc.brussels/sites/default/files/documents/graphics/rapport-pauvrete/barometre-welzijnsbarometer/barometre_social_2020.pdf">{@html $_('base.barometre')}</a>)</span></p>
			</div>
		</section>
		<section>
			<div class="col-medium">
				{@html $_('chap1.p27')}
			</div>
		</section>
		<section>
			<div class="col-medium">
				{@html $_('chap1.p28')}
			</div>
		</section>
		<section>
			<div class="col-medium">
				<h2>{@html $_('chap1.p29')}</h2>
				<p>{@html $_('chap1.p30')}</p>
				{#if w >= 900}
					<p>{@html $_('chap1.p31')}</p>
				{/if}
				<p>{@html $_('chap1.p32')}</p>
			</div>
		</section>
		<section>
			<div class="col-medium">
				{@html $_('chap1.p33')}
			</div>
		</section>
	</div>
</Scroller>

<Divider />
<Section>
	{@html $_('chap1.p34')}
	<p>{@html $_('chap1.p35')} <span class="caption">({@html $_('base.source')}: <a alt="baromètre social 2020" target="_blank" href="https://www.ccc-ggc.brussels/sites/default/files/documents/graphics/rapport-pauvrete/barometre-welzijnsbarometer/barometre_social_2020.pdf">{@html $_('base.barometre')}</a>)</span></p>
	
	<blockquote>{@html $_('chap1.p36')}</blockquote>
	<div bind:clientWidth={wVideo}>
		<iframe title="vimeo-player" src="{$locale.startsWith('fr') ? 'https://player.vimeo.com/video/711730712?h=867255b8af' : $locale.startsWith('en') ? 'https://player.vimeo.com/video/751673376?h=64a0a19da7' : 'https://player.vimeo.com/video/753813409?h=f1928639c7'}" width={wVideo} height={wVideo/640*360} frameborder="0" allowfullscreen></iframe>
	</div>
	<caption>
		<div class="col-medium">
			<div class="caption">Jean-Pierre HERMIA – IBSA</div>
		</div>
	</caption>

	{@html $_('chap1.p37')}

	<blockquote>{@html $_('chap1.p38')}</blockquote>
	<div>
		<iframe title="vimeo-player" src="{$locale.startsWith('fr') ? 'https://player.vimeo.com/video/711730578?h=050d2144cf' : $locale.startsWith('en') ? 'https://player.vimeo.com/video/751607838?h=09e09a4ce9' : 'https://player.vimeo.com/video/753814122?h=2d5efeba62'}" width={wVideo} height={wVideo/640*360} frameborder="0" allowfullscreen></iframe>
	</div>
	<caption>
		<div class="col-medium">
			<div class="caption">Martin WAGENER – CREBIS</div>
		</div>
	</caption>

	{@html $_('chap1.p39')}
	<Divider/>
	{@html $_('chap1.p40')}

	<blockquote class="text-indent">{@html $_('chap1.p41')}</blockquote>
	<p>{@html $_('chap1.p42')}</p>
</Section>

<style>
	.chart {
		margin-top: 10vh;
		margin-bottom: 10vh;
		height: 80vh;
	}
</style>
