<script>
  import BurgerMenu from 'svelte-burger-menu';
  import ShareButtons from './ShareButtons.svelte'
  import { locale, _ } from 'svelte-i18n';

  let open;

  function jumpTo (id) {
    open = !open
    document.getElementById(id).scrollIntoView({behavior: "smooth"})
  }

  function replaceStateWithQuery (values) {
    const url = new URL(window.location.toString());
    for (let [k, v] of Object.entries(values)) {
      if (!!v) {
        url.searchParams.set(encodeURIComponent(k), encodeURIComponent(v));
      } else {
        url.searchParams.delete(k);
      }
    }
    history.replaceState({}, '', url);
  };
</script>

<div style="position: sticky; top: 0px; z-index: 3;">
  <BurgerMenu
    backgroundColor="#eae5df"
    burgerColor="black"
    menuColor="black"
    bind:value={open}
  >
    <div>
      <!-- {open ? 'OPEN' : 'CLOSE'} -->
      <button 
        style="margin-left: 64px;"
        class="matter-button-{$locale && $locale === 'fr' ? 'contained' : 'outlined'}" 
        on:click={() => {
          locale.set('fr');
          replaceStateWithQuery({lang: 'fr'})
        }}
      >
        FR
      </button>
      <button 
        class="matter-button-{$locale && $locale === 'nl' ? 'contained' : 'outlined'}" 
        on:click={() => {
          locale.set('nl');
          replaceStateWithQuery({lang: 'nl'})
        }}
      >
        NL
      </button>
      <button 
        class="matter-button-{$locale && $locale === 'en' ? 'contained' : 'outlined'}" 
        on:click={() => {
          locale.set('en');
          replaceStateWithQuery({lang: 'en'})
        }}
      >
        EN
      </button>
      <h2>{@html $_('menu.nav')}</h2>
      <h2 class="chapter" on:click={() => jumpTo("introduction")}>{@html $_('menu.intro')}</h2>
      <h2 class="chapter" on:click={() => jumpTo("chapitre1")}>{@html $_('menu.ch1')}</h2>
      <h2 class="chapter" on:click={() => jumpTo("chapitre2")}>{@html $_('menu.ch2')}</h2>
      <h2 class="chapter" on:click={() => jumpTo("chapitre3")}>{@html $_('menu.ch3')}</h2>

      <h2 style="margin-top: 64px">{@html $_('menu.perso')}</h2>
      <h2 class="chapter" on:click={() => jumpTo("personalisation")}>{@html $_('menu.add')}</h2>

      <h2 style="margin-top: 64px">{@html $_('menu.share')}</h2>
      <p>
        <ShareButtons/>
      </p>

      <h2 style="margin-top: 64px; font-size: 16px;">{@html $_('menu.back')}</h2>
      <a href="https://medor.coop/">
        <img
          style="width: 75%; margin-top: 32px;"
          src="./img/medor-logo.svg"
          alt="Medor Logo" />
      </a>
    </div>
  </BurgerMenu>
</div>

<style>
  .chapter {
    cursor: pointer;
    font-size: 20px;
  }
</style>