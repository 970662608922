<script>
	import { onMount } from 'svelte';
	import * as d3core from 'd3';
	import * as d3annotation from 'd3-svg-annotation';
	import * as d3polygon from 'd3-polygon'
	import * as d3regression from  'd3-regression'
	import _ from 'lodash'
	import { labeler } from './labeler.js'
	import { _ as i18n, locale } from 'svelte-i18n'

	import communes from "../assets/data/communesUrgenceAmbulatoire.json"
	import { location } from '../store.js';

	const d3 = {...d3core, ...d3annotation, ...d3polygon, ...d3regression}

	let el;
	let w;
	let h;
	let width;
	let height;
	let main;
	let xScale;
	let yScale;
	let radiusScale;
	let axis;
	let destinations;
	let regressionPos;
	let regression;
	let evolutions;
	let scaleLabels;
	export let options;

	onMount(() => {
		const margin = { top: 10, bottom: 50, left: 20, right: 20}
		width = w - margin.left - margin.right
		height = h - margin.top - margin.bottom
				
		const svg = d3.select(el)
		  .append("svg")
			.attr("width", width + margin.left + margin.right)
			.attr("height", height + margin.top + margin.bottom)
			.style('overflow', 'visible')
		
		main = svg.append('g')
			.attr("transform", `translate(${margin.left}, ${margin.top})`)
			
		drawAxis()
		regression = main.append('g')
		drawChart()
		setLocation()
	})

	function drawAxis(){
		axis = main.append('g')

		xScale = d3.scaleLinear()
			.domain([0, d3.max(communes, d => d["dim"])])
			.range([0, width])
			.nice()

		const xAxis = g => g
			.attr("transform", `translate(0,${height})`)
			.call(d3.axisBottom(xScale)
					.tickFormat(d => `${d}%`)
			)
			.call(g => g.select(".domain").remove())

		axis.append("g")
      .call(xAxis);
		
		const xGrid = svg => svg
			.attr("transform", `translate(0,0)`)
			.call(d3.axisBottom(xScale)
					.tickSize(height)
					.tickFormat('')
			)
			.call(g => g.select(".domain").remove())

		axis.append("g")
			.attr('class', 'x-grid')
      .call(xGrid)
			.style("stroke-dasharray", 2)
			.style("stroke-opacity", 0.2)

		yScale = d3.scaleLinear()
			.domain([0, d3.max(communes, d => d["uaRatio"])])
			.range([height, 0])
			.nice()

		const yAxis = g => g
			.call(d3.axisLeft(yScale)
				.tickFormat(d => `${d*100}`)
			)
			.call(g => g.select(".domain").remove())

		axis.append("g")
      .call(yAxis);
		
		const yGrid = svg => svg
			.attr("transform", `translate(${width},0)`)
			.call(d3.axisLeft(yScale)
					.tickSize(width)
					.tickFormat('')
			)
			.call(g => g.select(".domain").remove())

		axis.append("g")
			.attr('class', 'y-grid')
      .call(yGrid)
			.style("stroke-dasharray", 2)
			.style("stroke-opacity", 0.2)

		scaleLabels = axis.append('g')
			.attr("font-family","Alfphabet")

		scaleLabels.append('text')
			.attr('class', 'high-precarity')
			.attr('x', width * 0.95)
			.attr('y', height - 20)
			.attr('text-anchor', 'end')
			.attr('font-size', 14)
			.text($i18n("plot.highPrecarity")+' →')

		scaleLabels.append('text')
			.attr('class', 'prop-bim')
			.attr('x', width /2)
			.attr('y', height + 40)
			.attr('text-anchor', 'middle')
			.attr('font-size', 14)
			.text($i18n('plot.propBim'))

		scaleLabels.append('text')
			.attr('class', 'ambu')
			.attr('x', width * 0.05)
			.attr('y', 20)
			.attr('text-anchor', 'start')
			.attr('font-size', 14)
			.text("↑ "+ $i18n("plot.ambu"))
	}

	function drawChart() {
		evolutions = main.append('g')
			.attr('class', 'evolutions')

		destinations = main.append('g')
			.attr('class', 'destinations')

		radiusScale = d3.scaleSqrt()
			.range([0, Math.min(height * 0.1, width * 0.1)])
			.domain(d3.extent(communes, d => d.population))

		const labelArrayDest = communes
			.map(c => ({
				...c,
				x: xScale(c.dim), 
				y: yScale(c.uaRatio), 
				y2016: yScale(c.uaRatio), 
				y2008: yScale(c.uaRatio2008), 
				width: 18.0, 
				height: width * 0.012,
				//r: radiusScale(c.population)
			}))

		const anchorArrayDest = communes
			.map(c => ({
				x: xScale(c.dim), 
				y: yScale(c.uaRatio), 
				r: 5,//radiusScale(c.population)
			}))

		regressionPos = d3.regressionLinear().x(d => d.x).y(d => d.y).domain([0, width])(labelArrayDest)
		regression.append("line")
      .attr("class", "regression")
      .datum(regressionPos)
      .attr("x1", d => d[0][0])
			.attr("y1", d => d[0][1])
      .attr("x2", d => options.showTrend ? d[1][0] : d[0][0])
			.attr("y2", d => options.showTrend ?  d[1][1] : d[0][1])
			.style("stroke", "grey");

		destinations.selectAll('.destination-anchor')
			.data(labelArrayDest)
			.join(enter => enter
					.append('circle')
					.attr('class', 'destination-anchor')
					.attr('r', 5)//d => d.r)
					.attr('cx', d => d.x)
					.attr('cy', d => d.y)
					.attr('fill', 'tomato')
					.attr('stroke', 'white')
					.attr('stroke-width', 1.5)
					.append('title')
					.text(d => d.name)
			)

		evolutions.selectAll('line')
			.data(labelArrayDest)
			.join(enter => enter
					.append('line')
					.attr('class', 'evolution')
					.attr("x1", d => d.x)
					.attr("y1", d => d.y)
					.attr("x2", d => d.x)
					.attr("y2", d => options.showEvolution ? d.y2008 : d.y2016)
					.style("stroke", "grey")
					.attr('stroke-width', 3)
					.style('opacity', 0.5)
			)
			
		evolutions.selectAll('circle')
			.data(labelArrayDest)
			.join(enter => enter
				.append('circle')
				.attr('class', 'evolution-start')
				.attr('r', 3)
				.attr('cx', d => d.x)
				.attr('cy', d => options.showEvolution ? d.y2008 : d.y2016)
				.attr('fill', '#c0c0c0')
				.attr('stroke', 'white')
				.attr('stroke-width', 1)
			)

		let destinationBackLabels = destinations.selectAll('.destination-back-label')
			.data(labelArrayDest)
			.join(enter => enter
					.append('text')
					.attr('class', 'destination-back-label')
					.attr("font-family","Alfphabet")
					.attr('x', d => d.fx ? d.fx + 8 : d.x)
					.attr('y', d => d.fy ? d.fy + 4 : d.y)
					.attr('font-size', 12)
					.attr("fill", "white")
					.attr("stroke", "white")
					.attr("stroke-width", 2)
					.attr('text-anchor', 'start')
					.text(d => d.name)
					.style('opacity', 0.7)
			)

		let destinationLabels = destinations.selectAll('.destination-label')
			.data(labelArrayDest)
			.join(enter => enter
					.append('text')
					.attr("font-family","Alfphabet")
					.attr('class', 'destination-label')
					.attr('x', d => d.fx ? d.fx + 8 : d.x)
					.attr('y', d => d.fy ? d.fy + 4 : d.y)
					.attr('font-size', 12)
					.attr('fill', 'black')
					.attr('text-anchor', 'start')
					.text(d => d.name)
			)

		let index = 0;
		destinationLabels.each(function() {
			labelArrayDest[index].width = this.getBBox().width;
			labelArrayDest[index].height = this.getBBox().height;
			index += 1;
		});

		var sim_ann = labeler()
			.label(labelArrayDest)
			.anchor(anchorArrayDest)
			.width(width*0.8)
			.height(height)
			sim_ann.start(1000);

		destinationLabels
			.attr("x", function(d) { return d.fx ? d.fx + 8 :  (d.x); })
			.attr("y", function(d) { return d.fy ? d.fy + 4 : (d.y); });

		destinationBackLabels
			.attr("x", function(d) { return d.fx ? d.fx + 8 : (d.x); })
			.attr("y", function(d) { return d.fy ? d.fy + 4 : (d.y); });
	}

	function setLocation() {
		if(destinations) {
			destinations.selectAll('.destination-label')
				.style('font-weight', d => $location && d.name.toUpperCase() === $location.commune ? 'bold' : '')
			
			destinations.selectAll('.destination-back-label')
				.style('font-weight',d => $location && d.name.toUpperCase() === $location.commune ? 'bold' : '')
		}
	}

	$: $location, setLocation()

	function update() {
		if(regression){
			regression.selectAll(".regression")
				.transition()
				.duration(1000)
				.attr("x2", d => options.showTrend ? d[1][0] : d[0][0])
				.attr("y2", d => options.showTrend ?  d[1][1] : d[0][1])
		}

		if(destinations){
			destinations.selectAll('.destination-anchor')
				.transition()
				.duration(300)
				.style('opacity', d => !options.highlightCommunes || ['Anderlecht', 'Saint-Josse', 'Molenbeek', 'Schaerbeek', 'Jette', 'Saint-Gilles', 'Forest'].includes(d.name) ? 1 : 0.3)

			destinations.selectAll('.destination-label')
				.transition()
				.duration(300)
				.style('opacity', d => !options.highlightCommunes || ['Anderlecht', 'Saint-Josse', 'Molenbeek', 'Schaerbeek', 'Jette', 'Saint-Gilles', 'Forest'].includes(d.name) ? 1 : 0.3)
		}

		if(evolutions) {
			evolutions.selectAll('line')
				.transition()
				.duration(1000)
				.attr("y2", d => options.showEvolution ? d.y2008 : d.y2016)

				evolutions.selectAll('circle')
					.transition()
					.duration(1000)
					.attr('cy', d => options.showEvolution ? d.y2008 : d.y2016)
		}
	}

	$: options, update()

	function setLocale() {
		if(scaleLabels){
			scaleLabels.select('.high-precarity')
				.text($i18n("plot.highPrecarity")+' →')

			scaleLabels.select('.prop-bim')
				.text($i18n('plot.propBim'))

			scaleLabels.select('.ambu')
				.text("↑ "+ $i18n("plot.ambu"))	
		}
	}

	$: $locale, setLocale()
</script>

<style>
</style>

<div bind:this={el} bind:clientWidth={w} bind:clientHeight={h} style="height: 100%; background-color: #fff0"></div>
