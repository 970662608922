<script>
	import { onMount, setContext } from "svelte";
	import { feature } from 'topojson';
	import mapbox from "mapbox-gl";
	import MapboxLanguage from '@mapbox/mapbox-gl-language';
	//import buildings from "../../assets/maps/bxlMergedBuildings.geo.json"
	import { location } from '../../store.js';

	import buildingsTopo from "../../assets/maps/bxlMergedBuildings.topo.json"
	import communes from "../../assets/maps/communes.json"
	import sectors from "../../assets/maps/brusselsSectors.json"

	const buildings = feature(buildingsTopo, buildingsTopo.objects.bxlMergedBuildings)

	export let map;
	export let popup;
	export let id = "map";
	export const bounds = [
		[4.25,50.77],
		[4.48,50.91],
	]; 
	// Brussels
	export let interactive = true;
	export let minzoom = 0;
	export let maxzoom = 14;
	export let zoom = null;

	let container;
	let w;
	let options = { bounds: bounds, zoom: 12.73 };
	let marker;

	setContext("map", {
		getMap: () => map,
	});

	onMount(() => {
		const link = document.createElement("link");
		link.rel = "stylesheet";
		link.href = "https://unpkg.com/mapbox-gl@1.13.0/dist/mapbox-gl.css";

		link.onload = () => {
			map = new mapbox.Map({
				container,
				accessToken: "pk.eyJ1IjoicGZjcm91c3NlIiwiYSI6ImNrc3BxbGJ0YTA1ZzkycW4xc3N2cmZmNzIifQ.MgePXQLxhdutuYN_02yM6Q",
				style: "mapbox://styles/pfcrousse/cksqjtfes1d2918l61j160nvk",
				minZoom: minzoom,
				maxZoom: maxzoom,
				interactive: interactive,
				...options,
			});

			// Get initial zoom level
			map.on("load", () => {
				zoom = map.getZoom();
				let mapboxLanguage = new MapboxLanguage({ defaultLanguage: 'fr'})
				map.addControl(mapboxLanguage);
				map.setStyle(mapboxLanguage.setLanguage(map.getStyle(), 'fr'))

				// Create a popup, but don't add it to the map yet.
				popup = new mapbox.Popup({
					closeButton: false,
					closeOnClick: false,
					className: 'map-popup',
					anchor: 'top'
				});

				// buildings
				map.addSource('bxl-buildings', {
					'type': 'geojson',
					'data': buildings
				});

				map.addLayer(
					{
						'id': 'buildings-fill',
						'type': 'fill',
						'source': 'bxl-buildings',
						'layout': {},
						'filter': ['has', 'dim'],
						'paint': {
							'fill-color': [
							'interpolate',
							['linear'],
							['get', 'dim'],
							0,
							"#00939C",
							11,
							"#49A6AE",
							14,
							"#65B3BA",
							18,
							"#B2DCDF",
							24,
							"#FEEEE8",
							29,
							"#F8C0AA",
							34,
							"#EC9370",
							41,
							"#DA6436",
							50,
							"#C22E00"
							],
						},
					},
					'road-intersection'
				);

				// communes
				map.addSource('communes', {
					'type': 'geojson',
					'data': communes
				});

				map.addLayer(
					{
						'id': 'communes-layer',
						'type': 'line',
						'source': 'communes',
						'layout': {},
						'filter': ['==', 'id', ''],
						'paint': {
							'line-color': 'white',
							'line-width': 6
						}
					},
					'building'
				);

				map.moveLayer('communes-layer')

				// sectors
				map.addSource('sectors', {
					'type': 'geojson',
					'data': sectors
				});

				map.addLayer(
					{
						'id': 'sectors-layer-focus',
						'type': 'line',
						'source': 'sectors',
						'layout': {},
						'filter': ['==', 'cd_sector', ''],
						'paint': {
							'line-color': 'white',
							'line-width': 4
						}
					},
					'building'
				);

				map.moveLayer('sectors-layer-focus')

				map.addLayer(
					{
						'id': 'sectors-layer',
						'type': 'line',
						'source': 'sectors',
						'layout': {},
						'filter': ['==', 'tx_munty_descr_fr', ''],
						'paint': {
							'line-color': 'white',
							'line-width': 2
						}
					},
					'building'
				);

				map.moveLayer('sectors-layer')
			});	

			// Update zoom level when the view zooms
			map.on("zoom", () => {
				zoom = map.getZoom();
			});

			map.fitBounds(bounds, {bearing: 0, pitch: 0, zoom: 12.73})
		};

		document.head.appendChild(link);

		return () => {
			map.remove();
			link.parentNode.removeChild(link);
		};
	});

	function pinAddress() {
		if(map){
			if(marker) marker.remove();
			if($location) {
				marker = new mapbox.Marker({ color: '#FEED55' })
					.setLngLat($location.coordinates)
					.addTo(map);
			}
		}
	}

	$: $location, pinAddress();

	// Function that forces map to resize to fit parent div, in case it doesn't automatically
	function resizeCanvas() {
		let canvas = document.getElementsByClassName("mapboxgl-canvas");
		if (canvas[0]) {
			canvas[0].style.width = "100%";
			map.resize();
		}
	}

	// Invoke above function when parent div size changes
	$: w && resizeCanvas();
</script>

<div bind:clientWidth={w} bind:this={container} {id}>
	{#if map}
		<slot />
	{/if}
</div>

<style>
	div {
		width: 100%;
		height: 100%;
	}
</style>
