<script>
	import { setContext } from "svelte";
	import { setColors } from "./utils.js";
	import { themes } from "./config.js";
	import Section from "./layout/Section.svelte";
	import ScrollMapDim from "./content/ScrollMapDim/ScollMapDim.svelte"
	import Menu from "./content/Menu.svelte";
	import Chapitre1 from "./content/Chapitre1.svelte";
	import Chapitre2 from "./content/Chapitre2.svelte";
	import Chapitre3 from "./content/Chapitre3.svelte";
	import PrecarityIdentity from "./content/PrecarityIdentity.svelte"
	import PrecarityDimentions from "./content/PrecarityDimentions.svelte"
	import GenericFullMedia from "./content/GenericFullMedia.svelte"
	import GenericMediaGrid from "./content/GenericMediaGrid.svelte";
	import Missing7 from "./content/Missing7.svelte"
	import PrecarityEvolution from "./content/PrecarityEvolution.svelte"
	import Divider from "./layout/Divider.svelte";
	import Footer from "./content/Footer.svelte";
	import Doctors from './content/Doctors.svelte';
	import Prevention from './content/Prevention.svelte';
	import UrgenceAmbulatoires from './content/UrgenceAmbulatoires.svelte';
	import VerdurePollution from './content/VerdurePollution.svelte';
	import EnvironmentPrecarity from './content/EnvironmentPrecarity.svelte';
	import Header from "./content/Header.svelte";
	import EnvironmentDevelopment from './content/EnvironmentDevelopment.svelte';
	import { init, getLocaleFromNavigator, addMessages } from 'svelte-i18n';

	// STYLE CONFIG
	// Set theme globally (options are 'light' or 'dark')
	let theme = "light";
	setContext("theme", theme);
	setColors(themes, theme);

	$: outerWidth = 0
	$: innerWidth = 0
	$: outerHeight = 0
	$: innerHeight = 0

	import en from './translations/en.json';
	import fr from './translations/fr.json';
	import nl from './translations/nl.json';

	addMessages('en', en);
	addMessages('fr', fr);
	addMessages('nl', nl);

	const urlParams = new URLSearchParams(window.location.search);
  const lang = urlParams.get('lang');

	init({
		fallbackLocale: 'fr',
		initialLocale: lang || getLocaleFromNavigator(),
	});
</script>


<svelte:window bind:innerWidth bind:outerWidth bind:innerHeight bind:outerHeight />
<div class="text-font">
	<Menu/>
	<Header/>
	<div style="height: {Math.max(0,((innerWidth/1600)*1060)-innerHeight)}px;"></div>
	<ScrollMapDim />
	<Chapitre1 innerWidth={innerWidth}/>
	<div style="height: {Math.max(0,((innerWidth/1600)*1060)-innerHeight)}px;"></div>
	<PrecarityIdentity />
	<Missing7 />
	<Divider />
	<PrecarityDimentions />
	<PrecarityEvolution />
	<Divider />
	<Chapitre2 innerWidth={innerWidth}/>
	<div style="height: {Math.max(0,((innerWidth/1600)*1060)-innerHeight)}px;"></div>
	<Prevention />
	<Divider />
	<Doctors />
	<Divider />
	<UrgenceAmbulatoires />
	<Divider />
	<Chapitre3 innerWidth={innerWidth}/>
	<div style="height: {Math.max(0,((innerWidth/1600)*1060)-innerHeight)}px;"></div>
	<VerdurePollution /> 
	<Divider />
	<EnvironmentPrecarity />
	<EnvironmentDevelopment/>
	<Footer /> 
</div>

<style>
</style>
