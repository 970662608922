<script>
  import Section from "../layout/Section.svelte";
	import Em from "../ui/Em.svelte";
	import { _ } from 'svelte-i18n'

	let w;
</script>

<Section>
	<div style=" margin-top: 50px; margin-bottom: 50px;">
		{#if w !== undefined && w < 900}
			<div>
				<img src="img/InvisiblesMobile.jpg" style="width: 100%" alt="7 pourcents manquant dans les statistiques"/>
			</div>
		{/if}
		{#if w !== undefined && w >= 900}
			<div>
				<img src="img/Invisibles.jpg" style="width: 100%" alt="7 pourcents manquant dans les statistiques"/>
			</div>
		{/if}
	</div>

	<p><Em desiredText="white" color="#c32e00">{@html $_('chap1.p43')}</Em> {@html $_('chap1.p44')}</p>
	<p>{@html $_('chap1.p45')}</p>
</Section>
<div bind:clientWidth={w}></div>

<style>
</style>