<script>
	import { onMount } from 'svelte';
	import * as d3core from 'd3';
	import * as d3annotation from 'd3-svg-annotation';
	import { _, locale } from 'svelte-i18n'

	import { themes } from "../config.js";
	import brusselsCanal from "../assets/maps/brusselsCanal.json"
	import brusselsCommunes from "../assets/maps/brusselsCommunes.json"
	import brusselsSectors from "../assets/maps/brusselsSectors.json"
	import doctorLocations from "../assets/data/doctorLocations.json"
	import maisonLocations from "../assets/data/maisonLocations.json"
	import docPenuries from "../assets/maps/docPenuries.json"
	import hospitals from "../assets/data/hospitals.json"
	import { location } from '../store.js';

	const d3 = {...d3core, ...d3annotation}

	let el;
	let w;
	let h;
	let projection;
	let path;
	let main;
	let width;
	let height;
	let locationLayer;
	let annotations;
	export let options;
	let legend;
	let doctorLegend;
	let maisonLegend;
	let penurieLegend;
	let hospitalLegend;
	let doctorLayer;
	let maisonLayer;
	let penurieLayer;
	let hospitalLayer;

	onMount(() => {
		const margin = { top: 60, bottom: 10, left: w < 900 ? 0 : 10, right: w < 900 ? 0 : 10}
		width = Math.min(w, h) - margin.left - margin.right
		height = width - margin.top - margin.bottom

		projection = d3.geoMercator()
  		.fitExtent([[5, 5], [width, height]], brusselsSectors);

		path = d3.geoPath(d3.geoIdentity())
    	.projection(projection);
		
		const svg = d3.select(el)
		  .append("svg")
			.attr("width", w)
			.attr("height", h)
		
		main = svg.append('g')
			.attr("transform", `translate(${margin.left + ((w - width)/2)}, ${Math.max(margin.top, (h-w) / 2)})`)

		penurieLayer = main.append('g')
			.style('opacity', 0)

		penurieLayer.append('g').selectAll('path')
			.data(docPenuries)
			.enter().append('path')
			.attr('d', path)
			.style('fill', 'tomato')
			.style('stroke', 'none')
			.style('opacity', 0.3);

		main.append('g').selectAll('path')
			.data(brusselsCanal.features)
			.enter().append('path')
			.attr('fill', 'none')
			.attr('d', path)
			.style('stroke', 'dodgerblue')
			.style('stroke-width', width * 0.005)
			.style('opacity', 0.3)
		
		main.append('g').selectAll('path')
			.data(brusselsSectors.features)
			.enter().append('path')
			.attr('d', path)
			.style('fill', 'none')
			.style('stroke', 'grey')
			.style('stroke-width', width * 0.0005)
			.style('stroke-opacity', 0.3);
		
		main.append('g').selectAll('path')
			.data(brusselsCommunes.features)
			.enter().append('path')
			.attr('class', 'commune')
			.attr('d', path)
			.style('fill', 'none')
			.style('stroke', 'black')
			.style('stroke-width', width * 0.0025)
			.style('stroke-opacity', 1)

		hospitalLayer = main.append('g')
			.style('opacity', 0)

		hospitalLayer.append('g').selectAll('circle')
			.data(hospitals)
			.enter().append('circle')
			.attr('cx', d => projection([d.properties.Longitude, d.properties.Latitude])[0])
			.attr('cy', d => projection([d.properties.Longitude, d.properties.Latitude])[1])
			.attr('r', d => 5)
			.style('fill', 'orange')
			.style('stroke', d => 'white')
			.style('stroke-width', 1)
			.style('opacity', 0.9)
			.append("title")
			.text(d => d.properties.Name)

		doctorLayer = main.append('g')

		doctorLayer.selectAll('circle')
			.data(doctorLocations.map(d => projection(d)))
			.enter().append('circle')
			.attr('cx', d => d[0])
			.attr('cy', d => d[1])
			.attr('r', width * 0.003)
			.style('fill', d => 'dodgerblue')
			.style('stroke', d => 'white')
			.style('stroke-width', 0.5)
			.style('opacity', 0.8)
			.append("title")
			.text(d => d.details);

		legend = main.append('g')
			.style('font-size', 12)
			.style('font-family', 'Alfphabet')
			.attr('transform', `translate(5, -40)`)

		maisonLayer = main.append('g')
			.style('opacity', 0)

		maisonLayer.append('g').selectAll('circle')
			.data(maisonLocations.map(d => projection(d)))
			.enter().append('circle')
			.attr('cx', d => d[0])
			.attr('cy', d => d[1])
			.attr('r', width * 0.006)
			.style('fill', 'none')
			.style('stroke', 'white')
			.style('stroke-width', width * 0.003)
			.style('opacity', 1);

		maisonLayer.append('g').selectAll('circle')
			.data(maisonLocations.map(d => projection(d)))
			.enter().append('circle')
			.attr('cx', d => d[0])
			.attr('cy', d => d[1])
			.attr('r', width * 0.006)
			.style('fill', 'none')
			.style('stroke', '#c1257f')
			.style('stroke-width', width * 0.0025)
			.style('opacity', 0.9);

		
		doctorLegend = legend.append('g');
		maisonLegend = legend.append('g');
		penurieLegend = legend.append('g');
		hospitalLegend = legend.append('g');

		doctorLegend
			.append('circle')
			.attr('cx', d => 0)
			.attr('cy', d => 5)
			.attr('r', 5)
			.style('fill', 'dodgerblue');

		doctorLegend
			.append('text')
			.attr('x', d => 10)
			.attr('y', d => 10)
			.style('fill', 'black')
			.text($_('plot.legendMed'));

		penurieLegend
			.append('circle')
			.attr('cx', d => 0)
			.attr('cy', d => 25)
			.attr('r', 5)
			.style('fill', 'tomato')
			.style('opacity', 0.3);

		penurieLegend
			.append('text')
			.attr('x', d => 10)
			.attr('y', d => 30)
			.style('fill', 'black')
			.text($_('plot.legendPenurie'));

		maisonLegend
			.append('circle')
			.attr('cx', d => 0)
			.attr('cy', d => 45)
			.attr('r', 4)
			.style('fill', 'none')
			.style('stroke', '#c1257f')
			.style('stroke-width', 2)
			.style('opacity', 0.9);
		
		maisonLegend
			.append('text')
			.attr('x', d => 10)
			.attr('y', d => 50)
			.style('fill', 'black')
			.text($_('plot.legendMaison'));

		hospitalLegend
			.append('circle')
			.attr('cx', d => 0)
			.attr('cy', d => 65)
			.attr('r', 5)
			.style('fill', 'orange')
			.style('stroke', 'none')
			.style('opacity', 0.9);
		
		hospitalLegend
			.append('text')
			.attr('x', d => 10)
			.attr('y', d => 70)
			.style('fill', 'black')
			.text($_('plot.legendHopitaux'));

		annotations = main.append('g')
			.attr('class', 'annotations sub-title-font')

			locationLayer = main.append('g')
			setLocation()
	});

	function setLocation() {
		if(locationLayer) {
			locationLayer.selectAll('.pin-back')
				.data($location ? [projection($location.coordinates)] : [])
				.join('circle')
					.attr('class', 'pin-back')
					.attr('cx', d => d[0])
					.attr('cy', d => d[1])
					.attr('r', width * 0.008)
					.attr('fill', 'none')
					.attr('stroke', 'white')
					.attr('stroke-width', width * 0.008)

			locationLayer.selectAll('.pin')
				.data($location ? [projection($location.coordinates)] : [])
				.join('circle')
					.attr('class', 'pin')
					.attr('cx', d => d[0])
					.attr('cy', d => d[1])
					.attr('r', width * 0.008)
					.attr('fill', 'none')
					.attr('stroke', 'grey')
					.attr('stroke-width', width * 0.003)
		}

		if(annotations && $location) {
			const annotationsData = [{
				note: {
					label: `${$location.sector} ${$location.commune}`,
					bgPadding: {"top":15,"left":10,"right":10,"bottom":10},
					title: $_('base.quartier')
				},
				x: projection($location.coordinates)[0] - width * 0.008,
				y: projection($location.coordinates)[1] - width * 0.008,
				ny: width * 0.2,
				nx: width * 0.2,
				color: 'grey',
				//connector: { end: "arrow" },
			}]

			const type = d3.annotationCallout
			const makeAnnotations = d3.annotation()
				.notePadding(4)
				.type(type)
				.textWrap(250)
				.annotations(annotationsData)

			annotations
				.style('font-size', 10)
				.style('stroke-width', 1.5)
				.style('opacity', 1)
				//.transition()
				.call(makeAnnotations)
		} else if(annotations) {
			annotations
				.style('opacity', 0)
		}
	}

	$: $location || $locale, setLocation()

	function update() {
		if(legend){
			doctorLegend.transition().style("opacity", options.show.includes('doctors') ? 1 : 0)
			maisonLegend.transition().style("opacity", options.show.includes('maisonMed') ? 1 : 0)
			penurieLegend.transition().style("opacity", options.show.includes('pénuries') ? 1 : 0)
			hospitalLegend.transition().style("opacity", options.show.includes('hopitals') ? 1 : 0)

			doctorLayer.transition().style("opacity", options.show.includes('doctors') ? 1 : 0)
			maisonLayer.transition().style("opacity", options.show.includes('maisonMed') ? 1 : 0)
			penurieLayer.transition().style("opacity", options.show.includes('pénuries') ? 1 : 0)
			hospitalLayer.transition().style("opacity", options.show.includes('hopitals') ? 1 : 0)
		}
	}

	$: options, update()

	function setLocale() {
		if(legend) {
			doctorLegend.select('text').text($_("plot.legendMed"))
			maisonLegend.select('text').text($_("plot.legendMaison"))
			penurieLegend.select('text').text($_("plot.legendPenurie"))
			hospitalLegend.select('text').text($_("plot.legendHopitaux"))
		}
	}

	$: $locale, setLocale()
</script>

<style>
	/*svg {
		margin-top: 10vh;
	}*/
</style>

<div bind:this={el} bind:clientWidth={w} bind:clientHeight={h} style="background-color: #fff0; height: 100%;"></div>
<caption style="color: {themes['light']['text']}; background-color: {themes['light']['background']};">
	<div class="col-medium">
		<div class="caption" style="text-align: center;">{$_("base.source")}: <a href="https://famgb.be/4DCGI/fr/resultats-recherche/" alt="FAMGB" target="_blank">FAMGB</a> & <a href="https://www.bhak.be/" alt="BHAK" target="_blank">BHAK</a> & <a alt="OBSERVATOIRE DE LA SANTÉ ET DU SOCIAL" target="_blank" href="https://www.ccc-ggc.brussels/sites/default/files/documents/graphics/dossiers/dossier_2018-02_medecins_generalistes_bruxelles.pdf">{$_("base.obs")}</a></div>
	</div>
</caption>
<!-- <div>Progress: {progress}</div> -->