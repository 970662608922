<script>
  import Scroller from "../layout/Scroller.svelte";
  import { location, mainLocation } from '../store.js';
  import { _ } from 'svelte-i18n'
</script>

<div id="chapitre1">
  <Scroller threshold={0.9}>
    <div slot="background">
      <figure>
        <div class="col-full">
          <img src='img/illustrations/medor_bruxelles_malade_precarite_1600x1060_feutre.jpg' style="object-fit: cover; object-position: 75% top; width: 100%; min-height: 100vh;" alt="Chapitre1: "/>
        </div>
      </figure>
    </div>
    <div slot="foreground">
      <section style="padding: 40vh 0 80vh 0;">
        <div class="col-medium" style="text-align: center;">
          <h1 style="font-weight: 500;">{@html $_('chap1.title')}</h1>
          <h1 style="font-weight: 500;">{@html $_('chap1.subTitle')}</h1>
        </div>
      </section>
      <section>
        <div class="col-medium">
          {@html $_('chap1.p1')}
        </div>
      </section>
      <section>
        <div class="col-medium">
          {@html $_('chap1.p2')}
        </div>
      </section>
      <section>
        <div class="col-medium">
          {@html $_('chap1.p3')}
          <span class="caption">({@html $_('base.source')} : <a alt="baromètre social 2020" target="_blank" href="https://www.ccc-ggc.brussels/sites/default/files/documents/graphics/rapport-pauvrete/barometre-welzijnsbarometer/barometre_social_2020.pdf">{@html $_('chap1.p4')}</a>)</span>
        </div>
      </section>
      <section>
        <div class="col-medium">
          {@html $_('chap1.p5')}
        </div>
      </section>
      <section>
        <div class="col-medium">
          {@html $_('chap1.p6')}
        </div>
      </section>
      <section>
        <div class="col-medium">
          <p>
            {@html $_('chap1.p7')}
          </p>
        </div>
      </section>
      <section>
        <div class="col-medium">
          <p>{@html $_('chap1.p8')}</p>
          <blockquote class="text-indent">
            {@html $_('chap1.p9')}
          </blockquote>
        </div>
      </section>
      <section>
        <div class="col-medium">
          <p>{@html $_('chap1.p10')}</p>
          {@html $_('chap1.p11')}
        </div>
      </section>
    </div>
  </Scroller> 
</div>

<style>
</style>