<script>
	import { themes } from '../config.js';
	import { getContext } from 'svelte';
	
	export let theme = getContext('theme');
	import { _ } from 'svelte-i18n'
	let w;
</script>

<footer style="color: {themes[theme]['text']}; background-color: {themes[theme]['pale']};" >
	<div class="col-wide" data-analytics="footer" bind:clientWidth={w}>
		{#if w < 900}
			<div style="display: flex; flex-direction: column; text-align: center;">
				<a href="https://medor.coop/" target="_blank">
					<img
						class="logo-img"
						src="./img/medor-logo.svg"
						alt="Medor Logo" />
				</a>
				<a href="https://www.jetpack.ai/" target="_blank">
					<img
						class="logo-img"
						src="./img/JetpackAI-small-logo-dark.png"
						alt="Jetpack.AI Logo" />
				</a>
				<a href="https://dancingdog.be/" target="_blank">
					<img
					class="logo-img"
					style="width: 150px;"
					src="./img/ddp-logo.png"
					alt="Dancing dog production Logo" />
				</a>
			</div>
			<hr style="border-top-color: {themes[theme]['muted']}" />
			<div class="license" style="text-align:center;">
				{$_('chap3.p69')}
			</div>
			<div style="text-align:center;">
				<img
					class="logo-img"
					src="./img/FWB-logo.png"
					style="margin-bottom: 0px;"
					alt="Fédération Wallonie Bruxelles Logo" />
			</div>
		{:else }
			<div style="display: flex; flex-direction: row; justify-content: space-between; align-items: center;">
				<a href="https://www.jetpack.ai/" target="_blank">
					<img
						class="logo-img"
						src="./img/JetpackAI-small-logo-dark.png"
						alt="Jetpack.AI Logo" />
				</a>
				<a href="https://medor.coop/" target="_blank">
					<img
						class="logo-img"
						src="./img/medorlogo.png"
						alt="Medor Logo" />
				</a>
				<a href="https://dancingdog.be/" target="_blank">
					<img
					class="logo-img"
					style="width: 150px;"
					src="./img/ddp-logo.png"
					alt="Dancing dog production Logo" />
				</a>
			</div>
			<hr style="border-top-color: {themes[theme]['muted']}" />
			<div class="license" style="text-align: center; display: flex; flex-direction: row; justify-content: center; align-items: center;">
				Avec le soutien du centre du cinéma et de l'audiovisuel de la Fédération Wallonie Bruxelles <img
					class="logo-img"
					src="./img/FWB-logo.png"
					style="margin-bottom: 0px;"
					alt="Fédération Wallonie Bruxelles Logo" />
			</div>
		{/if}
	</div>
</footer>

<style>
	footer {
		padding-bottom: 72px;
		padding-top: 36px;
		font-size: 18px;
	}
	footer a.link {
		-webkit-box-sizing: border-box;
		box-sizing: border-box;
		text-decoration: underline;
		display: inline-block;
	}
	footer a.link:hover {
		text-decoration: none;
	}
	footer a.link:active {
		outline: 3px solid transparent;
		background-color: #fd0;
		-webkit-box-shadow: 0 -2px #fd0, 0 4px #222;
		box-shadow: 0 -2px #fd0, 0 4px #222;
		color: #222;
		text-decoration: none;
	}
	footer hr {
		margin-bottom: 27px;
		margin-top: 36px;
		border: none;
		border-top: 1px solid #777;
	}
	ul {
		padding: 0;
		margin: 0;
	}
	li {
		margin-right: 18px;
		display: inline-block;
	}
	.logo-img {
		width: 200px;
		margin-bottom: 27px;
	}
	/*.ogl-img {
		margin: 0 0.5rem 0.2rem 0;
		width: 59px;
		height: 24px;
		vertical-align: middle;
	}*/
	.license {
		vertical-align: top;
		font-family: "Belgika";
		font-size: 12px;
	}
	a img:hover {
		cursor: pointer;
	}
</style>