<script>
	import Section from "../layout/Section.svelte";
	import Scroller from "../layout/Scroller.svelte";
	import { themes } from "../config.js";
	import { _ } from 'svelte-i18n'

	import BxlMapGreenAndPollution from '../charts/BxlMapGreenAndPollution.svelte'

	// SCROLLYTELLING CONFIG
	// Config
	const threshold = 0.8;

	let index = 0;
	let offset = 0;
	let h;
	let w;
</script>

<Section>
	{@html $_('chap3.p20')}
</Section>

<Scroller {threshold} bind:index bind:offset splitscreen={true}>
	<div slot="background">
		<figure>
			<div class="col-wide height-full middle">
				<div class="chart" bind:clientWidth={w} bind:clientHeight={h}>
					{#if (h && h > 0)}
						<BxlMapGreenAndPollution h={h} step={index} transformationProgress={(index < 2 ? 0 : (index > 2 ? index > 3 ? 0 : 1 - offset : offset)) * 2}/>
					{/if}
					{#if [0,1,2].includes(index)}
						<caption style="color: {themes['light']['text']}; background-color: {themes['light']['background']};">
							<div class="caption" style="text-align: center; font-size: { w < 900 ? '10px' : '15px'}; margin-top: { w < 900 ? '8px' : '32px'}">{@html $_('base.source')}: <a href="https://geodata.environnement.brussels/client/view/67bc5ff0-90f6-4b76-9f05-15c15a928980" target="_blank" alt="environnement.brussels">{@html $_('base.bxlEnvVert')}</a></div>
						</caption>
					{:else if [3,4].includes(index)}
						<caption style="color: {themes['light']['text']}; background-color: {themes['light']['background']};">
							<div class="caption" style="text-align: center; font-size: { w < 900 ? '10px' : '15px'}; margin-top: { w < 900 ? '8px' : '32px'}">{@html $_('base.source')}: <a href="https://www.irceline.be/" target="_blank" alt="irceline">{@html $_('base.ircelin')}</a></div>
						</caption>
					{:else}
						<caption style="color: {themes['light']['text']}; background-color: {themes['light']['background']};">
							<div class="caption" style="text-align: center; font-size: { w < 900 ? '10px' : '15px'}; margin-top: { w < 900 ? '8px' : '32px'}">{@html $_('base.source')}: <a href="https://geodata.environnement.brussels/client/view/4050f2cd-46d9-4502-adf4-0f35e6f913d1" target="_blank" alt="environnement.brussels">{@html $_('base.bxlEnvBruit')}</a></div>
						</caption>
					{/if}
				</div>
			</div>
		</figure>
	</div>

	<div slot="foreground">
		<section style="padding: 40vh 0 80vh 0;">
			<div class="col-medium">
				{@html $_('chap3.p21')}
			</div>
		</section>
		<section>
			<div class="col-medium">
				{@html $_('chap3.p22')}
			</div>
		</section>
		<section>
			<div class="col-medium">
				{@html $_('chap3.p23')}
			</div>
		</section>
		<section>
			<div class="col-medium">
				{@html $_('chap3.p24')}
			</div>
		</section>
		<section>
			<div class="col-medium">
				{@html $_('chap3.p25')}
				{@html $_('chap3.p26')}
				{@html $_('chap3.p27')}
				{@html $_('chap3.p28')}
				{@html $_('chap3.p29')}
			</div>
		</section>
		<section>
			<div class="col-medium">
				{@html $_('chap3.p30')}		
			</div>
		</section>
	</div>
</Scroller>

<style>
	.chart {
		margin-top: 10vh;
		margin-bottom: 10vh;
		height: 80vh;
	}
</style>
