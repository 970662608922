<script>
	import { onMount } from 'svelte';
	import * as d3core from 'd3';
	import * as d3annotation from 'd3-svg-annotation';
	import _ from 'lodash'
	import { _ as i18n, locale } from 'svelte-i18n'

	import brusselsCommunes from "../assets/maps/brusselsCommunes.json"
	import brusselsSectors from "../assets/maps/brusselsSectors.json"
	import sectorData from "../assets/data/sectorData.json"
	import croissantPauvreShape from '../assets/maps/croissantPauvre.json'
	import { location } from '../store.js';
	import numeral from 'numeral';

	const d3 = {...d3core, ...d3annotation}

	export let selectedMertic;
	export let invertedColor;
	export let title;
	let el;
	let w;
	let projection;
	let path;
	let main;
	let width;
	let height;
	let locationLayer;
	let annotations;
	let legend;
	let data;
	let schemeUsed;
	let color;
	let step;

	const labels = ['Fortement au-dessous de la moyenne ↓', 'Au-dessous de la moyenne ↓', 'Au-dessus de la moyenne ↑', 'Fortement au-dessus de la moyenne ↑' ]

	onMount(() => {
		const margin = { top: 50, bottom: 1, left: 1, right: 1}
		width = w - margin.left - margin.right
		height = width - margin.top - margin.bottom

		projection = d3.geoMercator()
  		.fitExtent([[5, 5], [width, height]], brusselsSectors);

		path = d3.geoPath(d3.geoIdentity())
    	.projection(projection);
		
		const svg = d3.select(el)
		  .append("svg")
			.style('overflow', 'visible')
			.attr("width", width + margin.left + margin.right)
			.attr("height", height + margin.top + margin.bottom)
		
		main = svg.append('g')
			.attr("transform", `translate(${margin.left}, ${margin.top})`)
		
		const values = sectorData
			.map(d => d[selectedMertic])
			.filter(v => v != null)

		
		let allValues = _(values).uniq().orderBy().value()
		step = allValues[1] - allValues[0]
		const extra = allValues.map(d => d - step)
		const domain = d3.extent(extra)
		const scheme = ['#e86c5b', '#f5baa3', '#9dc1c2', '#2e8499']//['#c35e32', '#e7a875', '#a5c2a3', '#448b82']//['#49006a', '#ad037e', '#f99eb5', '#fce0dd']//d3.schemeRdPu[9]//invertedColor === 'true'? d3.schemeRdPu[numberOfColors].slice().reverse() : d3[`scheme${colorScheme}`]
		schemeUsed = invertedColor ? scheme.reverse() : scheme
		color =  d3.scaleThreshold()
      .domain(allValues.map(d => d + step))
      .range(schemeUsed)
		const xScale = d3.scaleLinear()
      .domain(domain)
      .range([0, Math.min(400, width * 0.7)])
		
		data = brusselsSectors.features
			.map(d => {
				const neighbourhood = sectorData.find(n => n.Code === d.properties.cd_sector);
				return {
					...d,
					id: d.properties.cd_sector,
					metric: neighbourhood ? neighbourhood[selectedMertic] : null,
					color: neighbourhood && neighbourhood[selectedMertic] && neighbourhood.population > 100 ? color(neighbourhood[selectedMertic]) : '#e8e8e8',
				} 
			})

		main.append('g').selectAll('path')
			.data(data)
			.enter().append('path')
			.attr('d', path)
			.style('fill', d => d.color)
			.style('stroke', 'white')
			.style('stroke-width', 0.7)
			.style('stroke-opacity', 0.5)
			.style('stroke-opacity', 0.5)
			.append("title")
			.text(d => `${schemeUsed.indexOf(d.color) !== -1 ? labels[schemeUsed.indexOf(d.color)] : 'Pas suffisament de données'}`);
		
		main.append('g').selectAll('path')
			.data(brusselsCommunes.features)
			.enter().append('path')
			.attr('d', path)
			.style('fill', 'none')
			.style('stroke', 'white')
			.style('stroke-width', 2)
			.style('stroke-opacity', 0.8)
		
		main.append('g').selectAll('path')
			.data(croissantPauvreShape.features)
			.enter().append('path')
			.attr('d', path)
			.style('fill', 'none')
			.style('stroke', 'white')
			.style('stroke-width', 4)
			.style('stroke-opacity', 1)
		
		main.append('g').selectAll('path')
			.data(croissantPauvreShape.features)
			.enter().append('path')
			.attr('d', path)
			.style('fill', 'none')
			.style('stroke', 'black')
			.style('stroke-width', 2.5)
			.style('stroke-opacity', 0.9)
	//    .style('stroke-dasharray', 2)
  
		
		legend = main.append('g')
			.attr('transform', `translate(${15},${-25})`);

		legendFunction(color, xScale)

		annotations = main.append('g')
			.attr('class', 'annotations sub-title-font')

		locationLayer = main.append('g')
			setLocation()
	});

	function setLocation() {
		if(locationLayer) {
			locationLayer.selectAll('.pin-back')
				.data($location ? [projection($location.coordinates)] : [])
				.join('circle')
					.attr('class', 'pin-back')
					.attr('cx', d => d[0])
					.attr('cy', d => d[1])
					.attr('r', width * 0.008)
					.attr('fill', 'none')
					.attr('stroke', 'white')
					.attr('stroke-width', width * 0.008)

			locationLayer.selectAll('.pin')
				.data($location ? [projection($location.coordinates)] : [])
				.join('circle')
					.attr('class', 'pin')
					.attr('cx', d => d[0])
					.attr('cy', d => d[1])
					.attr('r', width * 0.008)
					.attr('fill', 'none')
					.attr('stroke', 'grey')
					.attr('stroke-width', width * 0.004)
		}

		if(annotations && $location) {
			const sector = data.find(c => c.id === $location.sectorId)
			const annotationsData = [{
				note: {
					label: sector ? `${schemeUsed.indexOf(sector.color) !== -1 ? labels[schemeUsed.indexOf(sector.color)] : 'Pas suffisament de données'}` : null,//`${$location.sector} ${$location.commune}`,
					bgPadding: {"top":5,"left":5,"right":5,"bottom":5},
					title: $i18n('base.quartier')
				},
				className: "annotation-small",
				x: projection($location.coordinates)[0] - width * 0.008,
				y: projection($location.coordinates)[1] - width * 0.008,
				ny: 50,
				nx: width * 0.3,
				color: 'grey',
				//connector: { end: "arrow" },
			}]

			const type = d3.annotationCallout
			const makeAnnotations = d3.annotation()
				.notePadding(4)
				.type(type)
				.textWrap(250)
				.annotations(annotationsData)

			annotations
				.style('font-size', 10)
				.style('stroke-width', 1.5)
				.style('opacity', 1)
				//.transition()
				.call(makeAnnotations)
		} else if(annotations) {
			annotations
				.style('opacity', 0)
		}
	}

	$: $location || $locale, setLocation()

	function legendFunction (colorScale, xScale) {		
		const xAxis = d3.axisBottom(xScale)
			.tickSize(13)
			//.tickValues(threshold.domain())
			.tickValues(colorScale.range().map(d => colorScale.invertExtent(d)[0] - step).slice(1))
			//.tickValues(colorScale.domain())
			.tickFormat(d => d === 100 ? 'Moyenne' : numeral(d - 100).format('+0') + '%' )
				
		legend.call(xAxis);
		
		legend.select(".domain").remove();
		legend.selectAll("rect")
			.data(colorScale.range().map(function(color) {
				var d = colorScale.invertExtent(color);
				if (d[0] == null) d[0] = xScale.domain()[0] + step;
				if (d[1] == null) d[1] = xScale.domain()[1] + step;
				return d;
			}))
			.enter().insert("rect", ".tick")
				.attr("height", 8)
				.attr("x", function(d) { return xScale(d[0] - step); })
				.attr("width", function(d) { return xScale(d[1] - step) - xScale(d[0] - step); })
				.attr("fill", function(d) { return colorScale(d[0]); })
		
		legend.append("text")
			.attr("fill", "#000")
			.attr("font-weight", "bold")
			.attr("text-anchor", "start")
			.attr("font-size", 14)
			.attr("font-family", "Alfphabet")
			.attr("y", -6)
			.text(title)
	}
</script>

<style>
</style>

<div bind:this={el} bind:clientWidth={w} style="background-color: #fff0;"></div>
<!-- <div>Progress: {progress}</div> -->