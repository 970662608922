<script>
	import { onMount } from 'svelte';
	import * as d3core from 'd3';
	import * as d3annotation from 'd3-svg-annotation';
	import _ from 'lodash'
	import { labeler } from './labeler.js'
	import { _ as i18n, locale } from 'svelte-i18n'
	
	import communesRaw from "../assets/data/communePrecarity.json"
	import brusselsCanal from "../assets/maps/brusselsCanal.json"
	import brusselsCommunes from "../assets/maps/brusselsCommunes.json"
	import brusselsSectors from "../assets/maps/brusselsSectors.json"
	import sectors from "../assets/data/sectorData.json"
	import { location } from '../store.js';
	import numeral from 'numeral';

	const communes = communesRaw.filter(c => c.year === 2018)

	const d3 = {...d3core, ...d3annotation}

	let el;
	let w;
	let h;
	let width;
	let height;
	let initCircles;
	let main;
	let xScale;
	let rScale;
	let colorScale;
	let yAxis;
	let bubbles;
	let communeBubbles;
	let communeBubbleLabels;
	let communePos;
	let bubbleLegend;	
	let labels;
	let means;
	let circles;
	let annotations;
	let projection;
	let path;
	let map;
	let prevKey;
	let axis;
	let scaleLabels;
	let scaleLabelsY;
	let hulls;
	let avg;
	let debug;

	export let yAxisSpec;
	export let croissant = null;
	export let commune = null;

	onMount(() => {
		const margin = { top: 10, bottom: 50, left: 55, right: 10}
		width = w - margin.left - margin.right
		height = h - margin.top - margin.bottom

		const swarm = (data, x, r, priority, symmetric = true) => {
			let circles = data
				.map((d, index) => ({
					datum: d,
					x: x(d),
					y: Infinity,
					r: r(d),
					priority: priority(d),
					index
				}))
				.sort((a, b) => d3.ascending(a.x, b.x));
			let indices = d3
				.range(0, circles.length)
				.sort((a, b) => d3.ascending(circles[a].priority, circles[b].priority));
			indices.forEach((index, order) => (circles[index].order = order));

			for (let d of circles) {
				if (d.x == undefined)
					console.log('x is undefined for datum at index ' + d.index);
				if (d.r == undefined)
					console.log('r is undefined for datum at index ' + d.index);
				if (d.priority == undefined)
					console.log('priority is undefined for datum at index ' + d.index);
			}
			let { sqrt, abs, min } = Math;
			let maxRadius = d3.max(circles, d => d.r);
			for (let index of indices) {
				let intervals = [];
				let circle = circles[index];
				// scan adjacent circles to the left and right
				for (let step of [-1, 1])
					for (let i = index + step; i > -1 && i < circles.length; i += step) {
						let other = circles[i];
						let dist = abs(circle.x - other.x);
						let radiusSum = circle.r + other.r;
						// stop once it becomes clear that no circle can overlap us
						if (dist > circle.r + maxRadius) break;
						// don't pay attention to this specific circle if
						// it hasn't been placed yet or doesn't overlap us
						if (other.y === Infinity || dist > radiusSum) continue;
						// compute the distance by which one would need to offset the circle
						// so that it just touches the other circle
						let offset = sqrt(radiusSum * radiusSum - dist * dist);
						// use that offset to create an interval in which this circle is forbidden
						intervals.push([other.y - offset, other.y + offset]);
					}
				// We're going to find a y coordinate for this circle by finding
				// the lowest point at the edge of any interval where it can fit.
				// This is quadratic in the number of intervals, but runs fast in practice due to
				// fact that we stop once the first acceptable candidate is found.
				let y = 0;
				if (intervals.length) {
					let candidates = intervals
						.flat()
						.sort((a, b) => d3.ascending(abs(a), abs(b)));
					for (let candidate of candidates) {
						if (!symmetric && candidate > 0) continue;
						if (intervals.every(([lo, hi]) => candidate <= lo || candidate >= hi)) {
							y = candidate;
							break;
						}
					}
				}
				circles[index].y = y;
			}
			return circles;
		}
				
		const svg = d3.select(el)
		  .append("svg")
			.attr("width", width + margin.left + margin.right)
			.attr("height", height + margin.top + margin.bottom)
		
		main = svg.append('g')
			.attr("transform", `translate(${margin.left}, ${margin.top})`)

		debug = main.append('rect')
			.attr('fill', 'red')

		axis = main.append('g')

		xScale = d3.scaleLinear()
			.domain([0, d3.max(sectors, d => d["Droit à l'intervention majorée|2018"])])
			.range([0, width])
			.nice()

		rScale = d3.scaleLinear()
			.domain([0, d3.max(sectors, d => d.population)])
			.range([1, width * 0.02])

		colorScale = d3
			.scaleThreshold()
			.domain([11,14,18,24,29,34,41,50])
			.range(["#00939C","#49A6AE","#65B3BA","#B2DCDF","#FEEEE8","#F8C0AA","#EC9370","#DA6436","#C22E00"])

		const xAxis = g => g
			.attr("transform", `translate(0,${height})`)
			.call(d3.axisBottom(xScale)
					//.ticks(d3.timeMonth.every(3))
					.tickFormat(d => `${d}%`)
			)
			.call(g => g.select(".domain").remove())

		axis.append("g")
      .call(xAxis);
		
		const xGrid = svg => svg
			.attr("transform", `translate(0,0)`)
			.call(d3.axisBottom(xScale)
					.tickSize(height)
					.tickFormat('')
			)
			.call(g => g.select(".domain").remove())
			
		axis.append("g")
			.attr('class', 'grid')
      .call(xGrid)
			.style("stroke-dasharray", 2)
			.style("stroke-opacity", 0.2)

		bubbleLegend = main.append("g")
			.style('font-family', 'Alfphabet')
		drawMap()

		yAxis = axis.append("g")
		hulls = main.append('g')
			.attr('class', 'hulls')
		
		avg = main.append('g')
			.style('opacity', 0)

		avg.append('line')
			.attr('x1', xScale(32.4))
			.attr('x2', xScale(32.4))
			.attr('y1', 0)
			.attr('y2', height)
			.style('stroke', 'grey')
			.style('stroke-width', 1)
			.style('opacity', 0.6)

		avg.append('text')
			.attr('x', xScale(32.4) + 8)
			.attr('y', 8)
			.style('fill', 'grey')
			.style('font-size', 14)
			.style('font-family', "Alfphabet")
			.text(`32,4% ${$i18n('plot.avg')}`)

		bubbles = main.append("g")
		communeBubbles = main.append("g")
		communeBubbleLabels = main.append("g")
		means = main.append("g")
		
		axis.selectAll(".tick text")
     	.attr("font-family","system-ui, sans-serif")

		const data = sectors
			.filter(s => s["Droit à l'intervention majorée|2018"] !== null && s["Droit à l'intervention majorée|2018"] !== undefined)
			.map(s => ({
				...s,
				x: xScale(s["Droit à l'intervention majorée|2018"]),
				radius: rScale(s.population),
				color: colorScale(s["Droit à l'intervention majorée|2018"]),
				selected: (croissant && s.inCroissant) || (!croissant && (!commune || s.commune === commune))
			}))

		initCircles = swarm(
			data,
			// map each data point to an x position (in pixels)
			d => d.x,
			// map each data point to a radius (in pixels)
			d => d.radius,
			// map each data point to a priority which determines placement order (lower = earlier).
			d => -d.radius
		)

		annotations = main.append('g')
			.attr('class', 'annotations sub-title-font')

		scaleLabels = axis.append('g')
			.attr("font-family","system-ui, sans-serif")
		scaleLabels.append('text')
			.attr('class', 'highPrecarity')
			.attr('x', width - 60)
			.attr('y', height - 20)
			.attr('text-anchor', 'end')
			.attr('font-size', 14)
			.style('font-family', 'Alfphabet')
			.text(`${$i18n('plot.highPrecarity')} →`)
			
		scaleLabels.append('text')
			.attr('class', 'propBim')
			.attr('x', width /2)
			.attr('y', height + 40)
			.attr('text-anchor', 'middle')
			.attr('font-size', 14)
			.style('font-family', 'Alfphabet')
			.text(`${$i18n('plot.propBim')}`)

		scaleLabelsY = scaleLabels
			.append('text')
			.attr('x', 40)
			.attr('y', 40)
			.attr('text-anchor', 'start')
			.attr('font-size', 14)
			.style('font-family', 'Alfphabet')
			//.text("↑ yAXis")

		changeYAxis()
	});

	function changeYAxis() {
		if(main){
			let yScale
			if(yAxisSpec.key === null || yAxisSpec.key === 'geo' ){
				yAxis.style('opacity', 0)
				if(yAxisSpec.key === null){
					avg.transition().delay(1000).style('opacity', 1)
				} else {
					avg.transition().style('opacity', 0)
				}
			} else {
				yScale = d3.scaleLinear()
					.domain(yAxisSpec.extent || d3.extent(sectors, d => d[yAxisSpec.key]))
					.range([height, 0])

				yAxis.style('opacity', 1)
				avg.transition().style('opacity', 0)
				yAxis.call(
					d3.axisLeft(yScale)
						.tickFormat(yAxisSpec.tickFormat ? yAxisSpec.tickFormat : null)
				)
				.call(g => g.select(".domain").style('stroke', 'lightgrey'))
			}

			axis.selectAll(".tick text")
     	.attr("font-family","system-ui, sans-serif")

			axis.transition()
				//.delay()
				.style('opacity', yAxisSpec.key === 'geo' ? 0 : 1)

			const meanValues = _(sectors)
				.groupBy('classe')
				.map(sec => {
					return {
						avg: yAxisSpec.key ? d3.sum(sec, d => d[yAxisSpec.key] * d["population"])/d3.sum(sec, d => d["population"]) : 0,
						span: d3.extent(sec, d => d["Droit à l'intervention majorée|2018"])
					}
				})
				.take(5)
				.value()

			means.selectAll('.mean')
				.data(meanValues)
				.join(
					enter => enter.append('g')
						.attr('class', 'mean')
						.attr('transform', d => `translate(0, ${yScale ? yScale(d.avg) : -50})`)
						.call(enter => enter.append('line')
							.attr('y1', 0)
							.attr('x1', d => xScale(d.span[0]))
							.attr('y2', 0)
							.attr('x2',  d => xScale(d.span[1]))
							.attr('stroke-width', 4)
							.attr('stroke', "white")
							.style('stroke-linecap', 'round')
						)
						.call(enter => enter.append('line')
							.attr('y1', 0)
							.attr('x1', d => xScale(d.span[0]))
							.attr('y2', 0)
							.attr('x2',  d => xScale(d.span[1]))
							.attr('stroke-width', 2)
							.attr('stroke', "black")
							.style('stroke-linecap', 'round')
						)
						.call(enter => enter.append('text')
							.attr('class', 'shadow')
							.attr('y', height * 0.024)
							.attr('x',  d => xScale(d.span[1]) - width * 0.01)
							.attr('text-anchor', 'end')
							.attr('font-size', 14)
							.attr('font-weight', 'bold')
							.style('stroke', 'white')
							.style('stroke-width', 4)
							.style('opacity', 0.8)
							.attr("font-family","system-ui, sans-serif")
							.attr('color', "black")
							.text(d => yAxisSpec.tickFormat ? yAxisSpec.tickFormat(Math.round(d.avg*10)/10) : Math.round(d.avg*10)/10)
						)
						.call(enter => enter.append('text')
							.attr('class', 'main-text')
							.attr('y', height * 0.024)
							.attr('x',  d => xScale(d.span[1]) - width * 0.01)
							.attr('text-anchor', 'end')
							.attr('font-size', 14)
							.attr('font-weight', 'bold')
							.attr("font-family","system-ui, sans-serif")
							.attr('color', "black")
							.text(d => yAxisSpec.tickFormat ? yAxisSpec.tickFormat(Math.round(d.avg*10)/10) : Math.round(d.avg*10)/10)
						),
					update => update
						.transition()
						.duration(1500)
						.attr('transform', d => `translate(0, ${yScale ? yScale(d.avg) : -50})`)
						.call(update => update 
							.select('.main-text')
							.text(d => yAxisSpec.tickFormat ? yAxisSpec.tickFormat(Math.round(d.avg*10)/10) : Math.round(d.avg*10)/10)
						)
						.call(update => update 
							.select('.shadow')
							.text(d => yAxisSpec.tickFormat ? yAxisSpec.tickFormat(Math.round(d.avg*10)/10) : Math.round(d.avg*10)/10)
						)
				)

			communePos = []
			if(!yAxisSpec.key){
				circles = initCircles
			} else if(yAxisSpec.key === 'geo') {
				circles = sectors
					.filter(s => s["Droit à l'intervention majorée|2018"] !== null && s["Droit à l'intervention majorée|2018"] !== undefined)
					.map(s => ({
						y: projection(s.centroid)[1],
						x: projection(s.centroid)[0],
						r: rScale(s.population),
						datum: {
							...s,
							color: colorScale(s["Droit à l'intervention majorée|2018"]),
							selected: (croissant && s.inCroissant) || (!croissant && (!commune || s.commune === commune))
						}
					}))
			} else if(yAxisSpec.key === 'commune') {
				communePos = _(communes)
					.map(c => ({
						...c,
						x: xScale(c.dim), 
						y: yScale ? yScale(c.revenue/12) : 0,
						r: Math.max(5, width * 0.01),
					}))
					.orderBy('population', 'desc')
					.value()
	
				const communePosMap = _(communePos).keyBy('name').value()
				circles = sectors
					.filter(s => s["Droit à l'intervention majorée|2018"] !== null && s["Droit à l'intervention majorée|2018"] !== undefined)
					.map(s => ({
						y: communePosMap[s.tx_munty_descr_fr].y,
						x: communePosMap[s.tx_munty_descr_fr].x,
						yRev: yScale(s["revenueMensuelMedian2018"]),
						xBim: xScale(s["Droit à l'intervention majorée|2018"]),
						r: rScale(s.population),
						datum: {
							...s,
							color: colorScale(s["Droit à l'intervention majorée|2018"]),
							selected: (croissant && s.inCroissant) || (!croissant && (!commune || s.commune === commune))
						}
					}))
			} else {
				circles = sectors
					.filter(s => s["Droit à l'intervention majorée|2018"] !== null && s["Droit à l'intervention majorée|2018"] !== undefined)
					.map(s => ({
						y: s[yAxisSpec.key] !== null && yScale ? yScale(s[yAxisSpec.key]) : null,
						x: xScale(s["Droit à l'intervention majorée|2018"]),
						r: rScale(s.population),
						datum: {
							...s,
							color: colorScale(s["Droit à l'intervention majorée|2018"]),
							selected: (croissant && s.inCroissant) || (!croissant && (!commune || s.commune === commune))
						}
					}))
			}

			bubbles
				.selectAll("circle")
				.data(_(circles).orderBy('r', 'desc').value(), d => d.datum.Code)
				.join(enter => enter.append("circle")
					.attr("cy", d => (d.y || 0) + (yAxisSpec.key === null ? height / 2 : 0))
					.attr("cx", d => d.x)
					.attr("r", d => d.r)
					.attr("fill", d => d.datum.color)
					//.attr("stroke", d => d.datum.selected && (croissant || commune) ? 'black' : 'white')
					//.attr("stroke-width", 1)
					.attr('stroke-width', d => $location && d.datum.id === $location.sectorId ? 2 : 0.4)
					.attr('stroke', d => $location && d.datum.id === $location.sectorId ? 'grey' : (d.datum.selected && (croissant || commune) ? 'black' : 'black'))
					.style("opacity", d => d.datum.selected ? 1 : 0.3)
					.append('title')
						.attr('class', 'tooltip-bubble')
						.text(d => `${d.datum.name.split(' (')[0]} à ${d.datum.commune}
 ${numeral(d.datum["population"]).format(',')} habitants
 Taux de BIM: ${d.datum["Droit à l'intervention majorée|2018"]}%`),
					update => update
						.transition()
						.delay(prevKey === 'geo' ? 1000 : 0)
						.duration(1500)
						.attr("cy", d => (d.y === null ? -100 : d.y || 0) + (yAxisSpec.key === null ? height / 2 : 0))
						.attr("cx", d => d.x)
						.attr("r", d => yAxisSpec.key !== 'commune' ? d.r : 10)
						.style("opacity", yAxisSpec.key === 'commune' ? 0 : 1)
						.call(update => update.selectAll('.tooltip-bubble')
							.text(d => `${d.datum.name.split(' (')[0]} à ${d.datum.commune}
 ${numeral(d.datum["population"]).format(',')} habitants
 Taux de BIM: ${d.datum["Droit à l'intervention majorée|2018"]}%
 ${yAxisSpec.key !== null && yAxisSpec.key !== 'geo' ? (yAxisSpec.label + ': ' + (yAxisSpec.tickFormat ? yAxisSpec.tickFormat(d.datum[yAxisSpec.key]) : d.datum[yAxisSpec.key])) : ''}`
							))
				)

			communeBubbles.selectAll("circle")
				.data(communePos, d => d.name)
				.join(enter => enter.append("circle")
					.attr("cy", d => d.y)
					.attr("cx", d => d.x)
					.attr("r", d => 0)
					.attr("fill", d => colorScale(d.dim))
					.attr('stroke-width', d => $location && d.name === $location.commune ? 2 : 0.4)
					.attr('stroke', d => $location && d.name === $location.commune ? 'grey' : 'black')
					.on("mouseenter", function(e, d) {
						bubbles.selectAll('circle')
							.filter(b => b.datum.tx_munty_descr_fr === d.name)
							.attr("cy", b => b.yRev)
							.attr("cx", b => b.xBim)
							.attr("r", b => b.r)
							.style("opacity", 1)
						
						communeBubbles.selectAll('circle')
							.filter(b => b.name !== d.name)
							.attr("opacity", 0.1)

						communeBubbles.selectAll('circle')
							.filter(b => b.name === d.name)
							.attr("fill-opacity", 0.1)

						labels
							.filter(b => b.name !== d.name)
							.attr("opacity", 0.1)

						d3.select(this).attr('stroke', 'black')

						hulls.attr("opacity", yAxisSpec.showHulls ? 0.1 : 0)
					})
					.on("mouseleave", function(e, d) {
						bubbles.selectAll('circle')
							.attr("cy", b => b.y)
							.attr("cx", b => b.x)
							.attr("r", 0)
							.style("opacity", 0)
							
						communeBubbles.selectAll('circle')
							.attr("opacity", yAxisSpec.key === 'commune' ? 1 : 0)
							.attr("fill-opacity", 1)

						labels.attr("opacity", yAxisSpec.key === 'commune' ? 1 : 0)

						d3.select(this).attr('stroke', 'grey')

						hulls.attr("opacity", yAxisSpec.showHulls ? 1 : 0)
					}),
					update => update
						.attr("cy", d => d.y)
						.attr("cx", d => d.x)
					 	.transition()
						.duration(1500)
						.attr("r", d => yAxisSpec.key === 'commune' ? d.r : 0)
				)
				.transition()
				.duration(1500)
				.attr("r", d => yAxisSpec.key === 'commune' ? d.r : 0)


				communeBubbles.selectAll("circle")
					.append('title')
					.attr('class', 'tooltip-bubble')
					.text(d => `${d.name}
 ${numeral(d.population).format(',')} habitants
 Taux de BIM: ${d.dim}%
 Revenu médian: ${numeral(d.revenue/12).format(',.')}€`)

			const labelArray = communes
				.map(c => ({
					...c,
					x: xScale(c.dim), 
					y: yScale ? yScale(c.revenue/12) : 0, 
					width: 18.0, 
					height: width * 0.012
				}))

			const anchorArray = communes
				.map(c => ({
					x: xScale(c.dim), 
					y: yScale ? yScale(c.revenue/12) : 0, 
					r: width * 0.012
				}))

			let backLabels = communeBubbleLabels.selectAll('.back-label')
				.data(labelArray)
				.join(enter => enter
						.append('text')
						.attr('class', 'back-label')
						.attr('x', d => d.fx ? d.fx + 8 : d.x)
						.attr('y', d => d.fy ? d.fy + 4 : d.y)
						.style('font-family', 'Alfphabet')
						.attr('font-size', 14)
						.attr("fill", "white")
						.attr("stroke", "white")
						.attr("stroke-width", 2)
						.attr('text-anchor', 'start')
						.text(d => d.name)
						.style('opacity', yAxisSpec.key === 'commune' ? 0.7 : 0)
				)

			labels = communeBubbleLabels.selectAll('.label')
				.attr("font-family","system-ui, sans-serif")
				.data(labelArray)
				.join(enter => enter
						.append('text')
						.attr('class', 'label')
						.attr('x', d => d.fx ? d.fx + 8 : d.x)
						.attr('y', d => d.fy ? d.fy + 4 : d.y)
						.style('font-family', 'Alfphabet')
						.attr('font-size', 14)
						.attr('fill', 'black')
						.attr('text-anchor', 'start')
						.text(d => d.name)
				)

			let index = 0;
			labels.each(function() {
				labelArray[index].width = this.getBBox().width;
				labelArray[index].height = this.getBBox().height;
				index += 1;
			});

			var sim_ann = labeler()
				.label(labelArray)
				.anchor(anchorArray)
				.width(width)
				.height(height)
				sim_ann.start(1000);

			labels
				.attr("opacity", 0)
				.attr("x", function(d) { return d.fx ? d.fx + 8 :  (d.x); })
				.attr("y", function(d) { return d.fy ? d.fy + 4 : (d.y); })
				.transition()
				.duration(prevKey === 'commune' ? 0 : 1000)
				.delay(yAxisSpec.key === 'commune' && prevKey !== 'commune'? 1000 : 0)
				.attr("opacity", yAxisSpec.key === 'commune' ? 1 : 0)

			backLabels
				.attr("opacity", 0)
				.attr("x", function(d) { return d.fx ? d.fx + 8 :  (d.x); })
				.attr("y", function(d) { return d.fy ? d.fy + 4 : (d.y); })
				.transition()
				.duration(1000)
				.delay(yAxisSpec.key === 'commune' ? 1000 : 0)
				.attr("opacity", yAxisSpec.key === 'commune' ? 0.7 : 0);
			
			//

			scaleLabels.style('opacity', yAxisSpec.key === 'geo' ? 0 : 1)
			//scaleLabelsY.style('opacity', yAxisSpec.key ? 1 : 0)
			scaleLabelsY.text(yAxisSpec.key ? `↑ ${yAxisSpec.label}` : '')

			map.transition().style('opacity', yAxisSpec.key === 'geo' ? 1 : 0)
			bubbleLegend.transition().style('opacity', yAxisSpec.key === 'geo' ? 1 : 0)
			prevKey = `${yAxisSpec.key}`;

			if(hulls){
				const groups = [
					["Auderghem", "Uccle", "Watermael-Boitsfort", "Woluwe-Saint-Lambert", "Woluwe-Saint-Pierre"],
					["Berchem-Sainte-Agathe", "Etterbeek", "Evere", "Forest", "Ganshoren", "Ixelles", "Jette"],
					["Anderlecht", "Bruxelles", "Koekelberg", "Saint-Gilles", "Schaerbeek"],
					["Molenbeek", "Saint-Josse"]
				]

				const hullPolygons = _(groups)
					.map(g => communePos.filter(c => g.includes(c.name)))
					.map(communes => communes.map(c => [c.x, c.y]))
					.map(points => (points.length < 3) ? points : d3.polygonHull(points))
					.value()

				var hullPadding = width < 900 ? 14 : 20;

				var vecScale = function (scale, v) {
						// Returns the vector 'v' scaled by 'scale'.
						return [ scale * v[0], scale * v[1] ];
				}

				var vecSum = function (pv1, pv2) {
						// Returns the sum of two vectors, or a combination of a point and a vector.
						return [ pv1[0] + pv2[0], pv1[1] + pv2[1] ];
				}

				var unitNormal = function (p0, p1) {
						// Returns the unit normal to the line segment from p0 to p1.
						var n = [ p0[1] - p1[1], p1[0] - p0[0] ];
						var nLength = Math.sqrt (n[0]*n[0] + n[1]*n[1]);
						return [ n[0] / nLength, n[1] / nLength ];
				};

				const roundedHull = function (polyPoints) {
					// Returns the SVG path data string representing the polygon, expanded and rounded.
					// Handle special cases
					if (!polyPoints || polyPoints.length < 1) return "";
					if (polyPoints.length === 1) return roundedHull1 (polyPoints);
					if (polyPoints.length === 2) return roundedHull2 (polyPoints);

					var segments = new Array (polyPoints.length);

					// Calculate each offset (outwards) segment of the convex hull.
					for (var segmentIndex = 0;  segmentIndex < segments.length;  ++segmentIndex) {
							var p0 = (segmentIndex === 0) ? polyPoints[polyPoints.length-1] : polyPoints[segmentIndex-1];
							var p1 = polyPoints[segmentIndex];

							// Compute the offset vector for the line segment, with length = hullPadding.
							var offset = vecScale (hullPadding, unitNormal (p0, p1));

							segments[segmentIndex] = [ vecSum (p0, offset), vecSum (p1, offset) ];
					}

					var arcData = 'A ' + [hullPadding, hullPadding, '0,0,0,'].join(',');

					segments = segments.map (function (segment, index) {
							var pathFragment = "";
							if (index === 0) {
									var pathFragment = 'M ' + segments[segments.length-1][1] + ' ';
							}
							pathFragment += arcData + segment[0] + ' L ' + segment[1];

							return pathFragment;
					});

					return segments.join(' ');
			}


			const roundedHull1 = function (polyPoints) {
					// Returns the path for a rounded hull around a single point (a circle).

					var p1 = [polyPoints[0][0], polyPoints[0][1] - hullPadding];
					var p2 = [polyPoints[0][0], polyPoints[0][1] + hullPadding];

					return 'M ' + p1
							+ ' A ' + [hullPadding, hullPadding, '0,0,0', p2].join(',')
							+ ' A ' + [hullPadding, hullPadding, '0,0,0', p1].join(',');
			};


			const roundedHull2 = function (polyPoints) {
					// Returns the path for a rounded hull around two points (a "capsule" shape).

					var offsetVector = vecScale (hullPadding, unitNormal (polyPoints[0], polyPoints[1]));
					var invOffsetVector = vecScale (-1, offsetVector);

					var p0 = vecSum (polyPoints[0], offsetVector);
					var p1 = vecSum (polyPoints[1], offsetVector);
					var p2 = vecSum (polyPoints[1], invOffsetVector);
					var p3 = vecSum (polyPoints[0], invOffsetVector);

					return 'M ' + p0
							+ ' L ' + p1 + ' A ' + [hullPadding, hullPadding, '0,0,0', p2].join(',')
							+ ' L ' + p3 + ' A ' + [hullPadding, hullPadding, '0,0,0', p0].join(',');
			};

				hulls.selectAll('.hull')
					.data(hullPolygons)
					.join(enter => enter
						.append('g')
						.attr('class', 'hull')
						.style('opacity', 0)
						.call(enter => enter
							.append('path')
							.attr('d', roundedHull)
							.attr('fill', 'grey')
							.attr('stroke', 'white')
							.attr('stroke-width', 2)
						),
						update => update
							.call(update => update
								.select('path')
								.attr('d', roundedHull)
							)
							.transition()
							.duration(1000)
							.delay(yAxisSpec.key === 'commune' && prevKey !== 'commune'? 2000 : 0)
							.style('opacity',yAxisSpec.key === 'commune' && yAxisSpec.showHulls ? 0.3 : 0)
					)
			}
		}
	}

	$: yAxisSpec, changeYAxis()


	function setLocation() {
		if(annotations && circles && yAxisSpec && $location && yAxisSpec.key !== 'commune' && circles.find(c => c.datum.id === $location.sectorId)) {
			const circle = circles.find(c => c.datum.id === $location.sectorId)
			let v
			if(yAxisSpec.key){
				if(yAxisSpec.key === 'geo') {
					v = ''
				} else {
					v = `\n(${yAxisSpec.tickFormat ? yAxisSpec.tickFormat(circle.datum[yAxisSpec.key]) : circle.datum[yAxisSpec.key]} ${yAxisSpec.shortLabel ? yAxisSpec.shortLabel : ''})`
				}
			} else {
				v = `(${Math.round(circle.datum["Droit à l'intervention majorée|2018"])}% ${$i18n('plot.xScaleBIM')})`
			}
			const x = circle.x;
			const y = circle.y + ( yAxisSpec.key ? 0 : height / 2)
			let annotationsData = [{
				note: {
					label: `${$location.sector} ${$location.commune}`,
					bgPadding: {"top":15,"left":10,"right":10,"bottom":10},
					title: `${$i18n('base.quartier')} ${v}`
				},
				className: "show-bg",
				x,
				y,
				width: Math.min(300, width*0.8),
				color: 'grey',
				//connector: { end: "arrow" },
			}]

			if(yAxisSpec.key === 'geo') {
				annotationsData[0].ny = width * 0.1;
				annotationsData[0].nx = width * 0.3;
			} else {
				let r = {
					w: Math.min(300, width*0.8),
					h: 60,
					pointsInside: 10000000,
					dist: 10000000
				}
				const w = width - r.w
				const h = height - r.h
				for(let i = 2; i <= 9; i++) {
					for(let j = 1; j <= 9; j++) {
						let rx = (i * w / 10) 
						let ry = (j * h / 10)
						if(rx < x && width >= 900) rx = rx - r.w
						if(ry < y) ry = ry - r.h
						const pointsInRect = circles.length - circles.filter(c => !(c.x > rx && c.x < rx + r.w && (c.y + (yAxisSpec.key === null ? height / 2 : 0)) > ry && (c.y + (yAxisSpec.key === null ? height / 2 : 0)) < ry + r.h)).length
						if(rx > 0 && pointsInRect <= r.pointsInside) {
							const dist = Math.sqrt((rx - x) ** 2 + (ry - y) ** 2);
							if(pointsInRect < r.pointsInside || dist < r.dist) {
								r.x = rx;
								r.y = ry;
								r.pointsInside = pointsInRect
								r.dist = dist
							}
						}
					}
				}

				const t = circle.r / Math.sqrt((r.x - x) ** 2 + (r.y - y) ** 2)
				const xt = (1 - t) * x + (t * r.x)
				const yt = (1 - t) * y + (t * r.y)

				// debug
				// 	.attr('x', r.x)
				// 	.attr('y', r.y)
				// 	.attr('width', r.w)
				// 	.attr('height', r.h)

				annotationsData[0].ny = r.y + (r.y > yt ? 0 : (r.h))
				annotationsData[0].nx = width < 900 ? r.x + (r.w/2) : (r.x + ( r.x < xt ? r.w : 0))
				annotationsData[0].x = xt
				annotationsData[0].y = yt
			}

			const type = width < 900 ? d3.annotationLabel : d3.annotationCallout
			const makeAnnotations = d3.annotation()
				.notePadding(4)
				.type(type)
				.textWrap(250)
				.annotations(annotationsData)

			//console.log('annotationsData', annotationsData)

			annotations
				.style('font-size', 10)
				.style('stroke-width', 1.5)
				.style('opacity', 1)
				//.transition()
				.call(makeAnnotations)
		} else if(annotations) {
			annotations
				.style('opacity', 0)
		}

		if(bubbles) bubbles
			.selectAll("circle")
			.attr('stroke-width', d => $location && d.datum.id === $location.sectorId ? 2 : 0.4)
			.attr('stroke', d => $location && d.datum.id === $location.sectorId ? 'black' : (d.datum.selected && (croissant || commune) ? 'black' : 'black'))

		if(labels){
			labels.attr("font-weight", d => $location && d.name === $location.commune ? 'bold' : '');
		}
	}
	
	$: $location || yAxisSpec || $locale, setLocation()

	function drawMap(){
		projection = d3.geoMercator()
  		.fitExtent([[5, 5], [width, height]], brusselsSectors);

		path = d3.geoPath(d3.geoIdentity())
    	.projection(projection);

		map = main.append('g')

		map.append('g').selectAll('path')
			.data(brusselsCanal.features)
			.enter().append('path')
			.attr('fill', 'none')
			.attr('d', path)
			.style('stroke', 'dodgerblue')
			.style('stroke-width', width * 0.005)
			.style('opacity', 0.3)
		
		map.append('g').selectAll('path')
			.data(brusselsSectors.features)
			.enter().append('path')
			.attr('d', path)
			.style('fill', 'none')
			.style('stroke', 'grey')
			.style('stroke-width', width * 0.0005)
			.style('stroke-opacity', 0.5);
		
		map.append('g').selectAll('path')
			.data(brusselsCommunes.features)
			.enter().append('path')
			.attr('class', 'commune')
			.attr('d', path)
			.style('fill', 'none')
			.style('stroke', 'grey')
			.style('stroke-width', width * 0.002)
			.style('stroke-opacity', 1)

		const steps = [10000, 5000, 2000, 500, 100]
		bubbleLegend.append('text')
			.attr('class', 'hab-legend')
			.attr('x', (width * 0.10) + rScale(steps[0]))
			.attr('y', height * 0.8 - 30)
			.attr('font-size', 14)
			.attr('fill', 'grey')
			.attr('font-weight', 'bold')
			.attr('text-anchor', 'middle')
			.text($i18n('plot.hab'))

		bubbleLegend.selectAll('.item')
			.data(steps)
			.join(enter => enter
				.append('g')
				.attr('class', 'item')
				.call(enter => enter.append('circle')
					.attr('cx', width * 0.10)
					.attr('cy', (_, i) => height * 0.8 + (d3.sum(steps.slice(0, i), d => 1.5 * rScale(d) + 12)))
					.attr('r', d => rScale(d))
					.attr('fill', 'none')
					.attr('stroke', 'grey')
					.attr('stroke-width', 0.5)
				)
				.call(enter => enter.append('text')
					.attr('x', (width * 0.11) + rScale(steps[0]))
					.attr('y', (_, i) => (height * 0.8 + (d3.sum(steps.slice(0, i), d => 1.5 * rScale(d) + 12))) + 5)
					.attr('font-size', 14)
					.attr('fill', 'grey')
					.text(d => numeral(d).format(','))
				)
			)
	}

	function setLocale() {
		if(avg) avg.select('text')
			.text(`32,4% ${$i18n('plot.avg')}`)
		
		if(scaleLabels){
			scaleLabels.select('.highPrecarity')
				.text(`${$i18n('plot.highPrecarity')} →`)

			scaleLabels.select('.propBim')
				.text(`${$i18n('plot.propBim')}`)	
		}

		if(bubbleLegend) bubbleLegend.select('.hab-legend')
			.text($i18n('plot.hab'))
	}

	$: $locale, setLocale()
</script>

<style>
</style>

<div bind:this={el} bind:clientWidth={w} bind:clientHeight={h} style="height: 100%; background-color: #fff0"></div>
