<script>
	import { onMount } from "svelte";
	import Section from "../layout/Section.svelte";
	import PrecarityEnvironmentChart from '../charts/PrecarityEnvironmentChart.svelte'
	import Scroller from "../layout/Scroller.svelte";
	import { themes } from "../config.js";
	import { _ } from 'svelte-i18n'
	
	// SCROLLYTELLING CONFIG
	// Config
	const threshold = 0.8;
	// State
	let index = [];
	let indexPrev = [];
	onMount(() => {
		indexPrev = [...index];
	});

	const chartOptions = [ 
		{ show: ['sectors', 'horizontalArrows']}, 
		{ show: ['sectors']}, 
		{ show: ['sectors', 'means']},
		{ show: ['sectors', 'means', "diff-Droit à l'intervention majorée|2018", 'diff-noise_mean'], focus: ["Droit à l'intervention majorée|2018", 'noise_mean']},
		{ show: ['sectors', 'means', "diff-Droit à l'intervention majorée|2018", 'diff-noise_mean'], focus: ["Droit à l'intervention majorée|2018", 'noise_mean']},
		{ show: ['sectors', 'means', 'diff-greenCoverage', 'diff-pm25', 'diff-no2'], focus: ["Droit à l'intervention majorée|2018", 'pm25', 'no2', 'greenCoverage']},
		{ show: ['sectors', 'means']},
	];
	let options = chartOptions[0];

	// Reactive code to trigger CHART actions
	$: if (index[0] != indexPrev[0]) {
		if (chartOptions[+index[0]]) {
			options = chartOptions[+index[0]];
		}
		indexPrev[0] = index[0];
	}

	let w;
	let h;
</script>

<Section>
	<div class="col-medium">
		{@html $_('chap3.p31')}
	</div>
</Section> 


<Scroller {threshold} bind:index={index[0]} splitscreen={true}>
	<div slot="background">
		<figure>
			<div class="col-wide height-full middle">
				<div style="text-align: center; margin-top: 8vh;"></div>
				<div class="chart" style="height: 85vh;" bind:clientHeight={h}>
					{#if h}
						<PrecarityEnvironmentChart options={options} h={h}/>
					{/if}
					<caption style="color: {themes['light']['text']}; background-color: {themes['light']['background']};">
						<div class="caption" style="text-align: center;">{@html $_('base.source')}: <a alt="AGENCE INTERMUTUALISTE" target="_blank" href="https://atlas.aim-ima.be/">{@html $_('base.aim')}</a> & <a alt="MONITEUR DES QUARTIERS IBSA" target="_blank" href="https://monitoringdesquartiers.brussels">{@html $_('base.monituer')}</a></div>
					</caption>
				</div>
			</div>
		</figure>
	</div>

	<div slot="foreground">
		<section style="padding: 60vh 0 80vh 0;">
			<div class="col-medium">
				{@html $_('chap3.p32')}
			</div>
		</section>
		<section>
			<div class="col-medium">
				{@html $_('chap3.p33')}
			</div>
		</section>
		<section>
			<div class="col-medium">
				{@html $_('chap3.p34')}
			</div>
		</section>
		<section>
			<div class="col-medium">
				{@html $_('chap3.p35')}
			</div>
		</section>
		<section>
			<div class="col-medium">
				{@html $_('chap3.p36')}
			</div>
		</section>
		<section>
			<div class="col-medium">
				{@html $_('chap3.p37')}
			</div>
		</section>
		<section style="padding: 0vh 0 35vh 0;">
			<div class="col-medium">
				{@html $_('chap3.p38')}
			</div>
		</section>
	</div>
</Scroller>

<style>
	.chart {
		margin-top: 0vh;
		margin-bottom: 10vh;
		height: 85vh;
	}
</style>
