import { writable, derived } from 'svelte/store';
import turfInside from "turf-inside"
import turfPoint from "turf-point"
import _ from 'lodash'

import brusselsSectors from "./assets/maps/brusselsSectors.json"
import sectorData from "./assets/data/sectorData.json"

export const address = writable(null)
export const mainLocation = writable(null)

export const location = derived(
	address,
	$address => {
		if(!$address) return null;
		const sector = brusselsSectors.features.find(f => turfInside(turfPoint($address.center), f))
		const data = sectorData.find(s => s.cd_sector === sector.properties.cd_sector)
		const bottomSector = sector.geometry.type === 'MultiPolygon' ? _(sector.geometry.coordinates[0][0]).minBy(d => d[1]) : _(sector.geometry.coordinates[0]).minBy(d => d[1])

		return {
			coordinates: $address.center,
			sectorId: sector.properties.cd_sector,
			sector: sector.properties.tx_sector_descr_fr ? sector.properties.tx_sector_descr_fr.split(' (')[0] : (data.name ? data.name.split(' (')[0] : 'Inconnu'),
			sectorData: { ...sector, ...data},
			bottomSector,
			commune: sector.properties.tx_munty_descr_fr,
		}
	}
);