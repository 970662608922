<script>
	import { mainLocation } from '../store.js';
	import { _ } from 'svelte-i18n'
	let w;
</script>

<div bind:clientWidth={w}>
  <div style="padding: 8px;">
		<div class="text-font" style="text-align: left; margin-top: 32px;">
			<p>{@html $_('intro.p6')}</p>
			{#if w < 740} 
				<div style="text-align: center; margin-top: 16px; margin-bottom: 16px;">
					{@html $_('intro.p7')}
					<div style="margin-top: 8px;">
						<button 
						class="matter-button-{$mainLocation && $mainLocation === 'brussels' ? 'contained' : 'outlined'}" 
						on:click={() => $mainLocation = 'brussels'}
						>
							{@html $_('intro.bxl')}
						</button>
						<button 
							class="matter-button-{$mainLocation && $mainLocation !== 'brussels' ? 'contained' : 'outlined'}" 
							on:click={() => $mainLocation = 'notBrussels'}
						>
							{@html $_('intro.ailleurs')}
						</button>
					</div>
				</div>
			{:else}
				<div style="text-align: center; margin-top: 16px; margin-bottom: 32px;">
					{@html $_('intro.p7')}
					<button 
						class="matter-button-{$mainLocation && $mainLocation === 'brussels' ? 'contained' : 'outlined'}" 
						on:click={() => $mainLocation = 'brussels'}
					>
						{@html $_('intro.bxl')}
					</button>
					<button 
						class="matter-button-{$mainLocation && $mainLocation !== 'brussels' ? 'contained' : 'outlined'}" 
						on:click={() => $mainLocation = 'notBrussels'}
					>
						{@html $_('intro.ailleurs')}
					</button>
				</div>
			{/if}
    </div>
		{#if $mainLocation}
			<div style="margin-top: 16px;">
				{#if $mainLocation === 'brussels'}
					<div>{@html $_('intro.p8')}</div>
				{:else}
					<div>{@html $_('intro.p9')}</div>
				{/if}
			</div>
		{/if}
  </div>
</div>

<style>
</style>