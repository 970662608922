<script>
	import Section from "../layout/Section.svelte";
	import Scroller from "../layout/Scroller.svelte";
	import { themes } from "../config.js";
	import Em from "../ui/Em.svelte";
	import Divider from "../layout/Divider.svelte";
	import { _ } from 'svelte-i18n'
	import ImmoChart from '../charts/ImmoChart.svelte'

	// SCROLLYTELLING CONFIG
	// Config
	const threshold = 0.8;

	let index = 0;
	let offset = 0;
	let w
</script>

<Section>
	{@html $_('chap1.p52')}
</Section>

<Scroller {threshold} bind:index bind:offset splitscreen={true}>
	<div slot="background">
		<figure>
			<div class="col-wide height-full middle" bind:clientWidth={w}>
				<div class="chart">
					<ImmoChart step={index} evolution={index < 1 ? 0 : (index > 1 ? 1 : offset)}/>
					<caption style="color: {themes['light']['text']}; background-color: {themes['light']['background']};">
						<div class="caption" style="font-size: { w < 900 ? '10px' : '15px'}; margin-top: { w < 900 ? '8px' : '32px'}">{@html $_('base.source')}: <a href="https://ibsa.brussels/sites/default/files/publication/documents/Focus-44_FR.pdf" target="_blank" alt="IBSA">{@html $_('base.ibsa')}</a></div>
					</caption>
				</div>
			</div>
		</figure>
	</div>

	<div slot="foreground">
		<section style="padding: 40vh 0 80vh 0;">
			<div class="col-medium">
				{@html $_('chap1.p53')}
			</div>
		</section>
		<section>
			<div class="col-medium">
				{@html $_('chap1.p54')}
			</div>
		</section>
		<section>
			<div class="col-medium">
				{@html $_('chap1.p55')}
			</div>
		</section>
		<section>
			<div class="col-medium">
				{@html $_('chap1.p56')}
			</div>
		</section>
	</div>
</Scroller>

<Section>
	{@html $_('chap1.p57')}
	<blockquote class="text-indent">{@html $_('chap1.p58')}</blockquote>
	{@html $_('chap1.p59')}
	<p>{@html $_('chap1.p60')}<span class="caption">({@html $_('base.source')}: <a alt="Observatoire de la Santé et du Social, novembre 2020" target="_blank" href="https://www.ccc-ggc.brussels/sites/default/files/documents/graphics/dossiers/dossier_2021_brochure_surendettement_fr.pdf">{@html $_('chap1.p61')}</a>)</span></p>
	<Divider/>
	<p>{@html $_('chap1.p62')}</p>
	<p style="margin-bottom: 64px;">{@html $_('chap1.p63')}</p>
</Section>

<style>
	.chart {
		margin-top: 10vh;
		margin-bottom: 10vh;
		height: 80vh;
	}
</style>
