<script>
	import { onMount } from 'svelte';
	import * as d3core from 'd3';
	import * as d3annotation from 'd3-svg-annotation';
	import { _, locale } from 'svelte-i18n'

	import { location } from '../store.js';
	import sectors from "../assets/data/sectorData.json"

	const d3 = {...d3core, ...d3annotation}

	let el;
	let w;
	let width;
	let height;
	let bubbles;
	let circles;
	let annotations;
	let avg;
	let scaleLabels;

	export let croissant = null;
	export let commune = null;

	onMount(() => {
		const margin = { top: 60, bottom: 45, left: 20, right: 20}
		width = w - margin.left - margin.right
		height = Math.max(250, width * 0.5) - margin.top - margin.bottom

		const swarm = (data, x, r, priority, symmetric = true) => {
			let circles = data
				.map((d, index) => ({
					datum: d,
					x: x(d),
					y: Infinity,
					r: r(d),
					priority: priority(d),
					index
				}))
				.sort((a, b) => d3.ascending(a.x, b.x));
			let indices = d3
				.range(0, circles.length)
				.sort((a, b) => d3.ascending(circles[a].priority, circles[b].priority));
			indices.forEach((index, order) => (circles[index].order = order));

			for (let d of circles) {
				if (d.x == undefined)
				console.log('x is undefined for datum at index ' + d.index);
				if (d.r == undefined)
				console.log('r is undefined for datum at index ' + d.index);
				if (d.priority == undefined)
				console.log('priority is undefined for datum at index ' + d.index);
			}
			let { sqrt, abs, min } = Math;
			let maxRadius = d3.max(circles, d => d.r);
			for (let index of indices) {
				let intervals = [];
				let circle = circles[index];
				// scan adjacent circles to the left and right
				for (let step of [-1, 1])
					for (let i = index + step; i > -1 && i < circles.length; i += step) {
						let other = circles[i];
						let dist = abs(circle.x - other.x);
						let radiusSum = circle.r + other.r;
						// stop once it becomes clear that no circle can overlap us
						if (dist > circle.r + maxRadius) break;
						// don't pay attention to this specific circle if
						// it hasn't been placed yet or doesn't overlap us
						if (other.y === Infinity || dist > radiusSum) continue;
						// compute the distance by which one would need to offset the circle
						// so that it just touches the other circle
						let offset = sqrt(radiusSum * radiusSum - dist * dist);
						// use that offset to create an interval in which this circle is forbidden
						intervals.push([other.y - offset, other.y + offset]);
					}
				// We're going to find a y coordinate for this circle by finding
				// the lowest point at the edge of any interval where it can fit.
				// This is quadratic in the number of intervals, but runs fast in practice due to
				// fact that we stop once the first acceptable candidate is found.
				let y = 0;
				if (intervals.length) {
					let candidates = intervals
						.flat()
						.sort((a, b) => d3.ascending(abs(a), abs(b)));
					for (let candidate of candidates) {
						if (!symmetric && candidate > 0) continue;
						if (intervals.every(([lo, hi]) => candidate <= lo || candidate >= hi)) {
							y = candidate;
							break;
						}
					}
				}
				circles[index].y = y;
			}
			return circles;
		}
				
		const svg = d3.select(el)
		  .append("svg")
			.attr("width", width + margin.left + margin.right)
			.attr("height", height + margin.top + margin.bottom)
		
		const main = svg.append('g')
			.attr("transform", `translate(${margin.left}, ${margin.top})`)

		const xScale = d3.scaleLinear()
			.domain([0, d3.max(sectors, d => d["Droit à l'intervention majorée|2018"])])
			.range([0, width])
			.nice()

		scaleLabels = main.append('g')
			.attr("font-family","system-ui, sans-serif")

		scaleLabels.append('text')
			.attr('class', 'highPrecarity')
			.attr('x', width - 20)
			.attr('y', 20)
			.attr('text-anchor', 'end')
			.attr('font-size', 12)
			.style('font-family', 'Alfphabet')
			.text(`${$_('plot.highPrecarity')} →`)

		scaleLabels.append('text')
			.attr('class', 'propBim')
			.attr('x', width /2)
			.attr('y', height + 40)
			.attr('text-anchor', 'middle')
			.attr('font-size', 12)
			.style('font-family', 'Alfphabet')
			.text(`${$_('plot.propBim')}`)

		const rScale = d3.scaleLinear()
			.domain([0, d3.max(sectors, d => d.population)])
			.range([1, width * 0.02])

		const colorScale = d3
			.scaleThreshold()
			.domain([11,14,18,24,29,34,41,50])
			.range(["#00939C","#49A6AE","#65B3BA","#B2DCDF","#FEEEE8","#F8C0AA","#EC9370","#DA6436","#C22E00"])

		const xAxis = svg => svg
			.attr("transform", `translate(0,${height})`)
			.call(d3.axisBottom(xScale)
					//.ticks(d3.timeMonth.every(3))
					.tickFormat(d => `${d}%`)
			)
			.call(g => g.select(".domain").remove())

		const xGrid = svg => svg
			.attr("transform", `translate(0,0)`)
			.call(d3.axisBottom(xScale)
					.tickSize(height)
					.tickFormat('')
			)
			.call(g => g.select(".domain").remove())

		const xAxisSelection = main.append("g")
			.attr('class', 'axis')
			.call(xAxis);

		main.append("g")
			.attr('class', 'grid')
      .call(xGrid)
			.style("stroke-dasharray", 2)
			.style("stroke-opacity", 0.2)

		xAxisSelection.selectAll(".tick text")
     	.attr("font-family","system-ui, sans-serif")

		const data = sectors
			.filter(s => s["Droit à l'intervention majorée|2018"] !== null && s["Droit à l'intervention majorée|2018"] !== undefined)
			.map(s => {
				return {
				...s,
				x: xScale(s["Droit à l'intervention majorée|2018"]),
				radius: rScale(s.population),
				color: colorScale(s["Droit à l'intervention majorée|2018"]),
				selected: (croissant && s.inCroissant) || (!croissant && (!commune || s.commune === commune))
			}})

		circles = swarm(
			data,
			// map each data point to an x position (in pixels)
			d => d.x,
			// map each data point to a radius (in pixels)
			d => d.radius,
			// map each data point to a priority which determines placement order (lower = earlier).
			d => -d.radius
		)

		bubbles = main.append('g')
		bubbles
			.selectAll("circle")
			.data(circles)
			.enter()
			.append("circle")
			.attr("cy", d => d.y + height / 2)
			.attr("cx", d => d.x)
			.attr("r", d => d.r)
			.attr("fill", d => d.datum.color)
			.attr("stroke", 'black')
			.attr("stroke-width", d => d.datum.selected && (croissant || commune) ? 1 : 0.2)
			.style("opacity", d => d.datum.selected ? 1 : 0.2)
			.append('title')
				.text(d => `${d.datum.name.split(' (')[0]} à ${d.datum.commune} \nTaux de BIM: ${d.datum["Droit à l'intervention majorée|2018"]}%`);

		annotations = main.append('g')
			.attr('class', 'annotations sub-title-font')

		avg = main.append('g')
			.style('opacity', commune ? 0.3 : 0.8)

		avg.append('line')
			.attr('x1', xScale(32.4))
			.attr('x2', xScale(32.4))
			.attr('y1', 0)
			.attr('y2', height)
			.style('stroke', 'grey')
			.style('stroke-width', 1)
			.style('opacity', 0.8)

		avg.append('text')
			.attr('x', xScale(32.4) + 8)
			.attr('y', height - 8)
			.style('fill', 'grey')
			.style('font-size', 12)
			.style('font-family', "Alfphabet")
			.text(`32,4% ${$_('plot.avg')}`)
	});

	function setLocation() {
		if(annotations && circles && $location && circles.find(c => c.datum.id === $location.sectorId)) {
			const circle = circles.find(c => c.datum.id === $location.sectorId)
			const offsetX = Math.cos(Math.PI/8) * (circle.x > width / 2 ? - circle.r : circle.r);
			const offsetY = (height/2) - (Math.sin(Math.PI/8) * circle.r);
			const annotationsData = [{
				note: {
					label: `${$location.sector} ${$location.commune}`,
					bgPadding: {"top":15,"left":10,"right":10,"bottom":10},
					title: `${$_('base.quartier')} (${Math.round(circle.datum["Droit à l'intervention majorée|2018"])}% ${$_('plot.tauxBim')})`
				},
				//can use x, y directly instead of data
				data: { date: "18-Sep-09", close: 185.02 },
				className: "show-bg",
				x: circle.x + offsetX,
				y: circle.y + offsetY,
				ny: -10,
				nx: circle.x + offsetX + (circle.x > width / 2 ? - Math.min(height / 2, Math.max(1, circle.x - 250)) : Math.min(height / 2, Math.max(1, width - circle.x - 250))),
				color: 'grey',
				//connector: { end: "arrow" },
			}]

			const type = d3.annotationCallout
			const makeAnnotations = d3.annotation()
				.notePadding(4)
				.type(type)
				.textWrap(250)
				.annotations(annotationsData)

			annotations
				.style('opacity', 1)
				.style('font-size', 5)
				.style('stroke-width', 1.5)
				.call(makeAnnotations)
		} else if(annotations) {
			annotations
				.style('opacity', 0)
		}
		if(bubbles) bubbles
			.selectAll("circle")
			.attr('stroke-width', d => $location && d.datum.id === $location.sectorId ? 2 : (d.datum.selected && (croissant || commune) ? 1 : 0.2))
			.attr('stroke', 'black')//d => d.datum.id === $location.sectorId ? 'grey' : (d.datum.selected && (croissant || commune) ? 'black' : 'black'))
	}
	
	$: $location || $locale, setLocation()

	function setLocale() {
		if(avg) avg.select('text')
			.text(`32,4% ${$_('plot.avg')}`)
		
		if(scaleLabels){
			scaleLabels.select('.highPrecarity')
				.text(`${$_('plot.highPrecarity')} →`)

			scaleLabels.select('.propBim')
				.text(`${$_('plot.propBim')}`)		
		}
	}

	$: $locale, setLocale()
</script>

<style>
</style>

<div bind:this={el} bind:clientWidth={w} style="background-color: #fff0; padding: 0px;"></div>
