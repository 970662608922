<script>
	import { location, mainLocation } from '../store.js';
  import { _ } from 'svelte-i18n';
  let value = 50;
  let w;

  export let precarityGuess;
  $: response =  precarityGuess === undefined ? null : ($mainLocation === 'notBrussels' ? 32.4 : ($location ? $location.sectorData["Droit à l'intervention majorée|2018"] : null))
</script>

<div bind:clientWidth={w} style="margin-top: 32px; margin-bottom: 16px">
  {#if w < 740}
    <div style="margin-bottom: 32px; margin-top: 64px;">
      <div style="width: 100%; position: relative;">
        <span id="val" style="left: {value*0.95}%;">{value}%</span>
        <span id="slide-before">0% {@html $_('slider.precarity')}</span>
        <span id="slide-after">100% {@html $_('slider.precarity')}</span>
        {#if response}
          <svg id="response" width="6" height="36" style="left: calc({response}% - {3 + (((response-50)/50)*24)}px)">
            <rect x="0" y="0" width="6" height="29" fill="grey" rx="4"></rect>
          </svg>
        {/if}
        <input id="slide" type="range" min="0" max="100" bind:value={value}  />
      </div>
      <div style="text-align: center; margin-top: 16px;">
        <button 
          class="matter-button-outlined" 
          on:click={() => precarityGuess = value} 
          style=" margin-top: 24px; min-width: 40%;">
          {@html $_('slider.validate')}
        </button>
      </div>
    </div>  
  {:else}
    <div style="display: flex; flex-direction: row; margin-bottom: 34px;">
      <div style="width: 80%; padding: 12px; position: relative;">
        <span id="val" style="left: {value*0.95}%;">{value}%</span>
        <span id="slide-before">0% {@html $_('slider.precarity')}</span>
        <span id="slide-after">100% {@html $_('slider.precarity')}</span>
        {#if response}
          <svg id="response" width="6" height="36" style="left: calc({response}% - {3 + (((response-50)/50)*24)}px)">
            <rect x="0" y="0" width="6" height="29" fill="grey" rx="4"></rect>
          </svg>
        {/if}
        <input id="slide" type="range" min="0" max="100" bind:value={value}  />
      </div>
      <button 
        class="matter-button-outlined" 
        on:click={() => precarityGuess = value} 
        style="width: 17%; margin-top: 8px;">
        {@html $_('slider.validate')}
      </button>
    </div>
  {/if}
</div>

<style>
  input[type=range] {
    width: 100%;
    -webkit-appearance: none;
    margin: 0;
    border: none;
    background: none;
  }
  input[type=range]:focus { outline: none; }
  input[type=range]::-webkit-slider-runnable-track {
    width: 100%;
    height: 19px;
    cursor: pointer;
    box-shadow: 0 4px 4px rgba(0,0,0,0.3) inset;
    /*background: linear-gradient(to right, #005fc2, #35A834 40%, #ffd400 65%, #FF6400);*/
    background: linear-gradient(to right, #00939C, #49A6AE 11%, #65B3BA 14%, #B2DCDF 18%, #FEEEE8 24%, #F8C0AA 29%, #EC9370 34%, #DA6436 41%, #C22E00 50%);
    border-radius: 20px;
    border: 0.2px solid #010101;
  }
  input[type=range]::-webkit-slider-thumb {
    box-shadow: 1px 1px 1px #000000, 0px 0px 1px #0d0d0d;
    border: 1px solid #000000;
    height: 30px;
    width: 30px;
    border-radius: 30px;
    background: white;
    cursor: pointer;
    -webkit-appearance: none;
    margin-top: -6px;
  }
  input[type=range]::-moz-range-track {
    width: 100%;
    height: 19px;
    cursor: pointer;
    box-shadow: 0 4px 4px rgba(0,0,0,0.3) inset;
    /*background: linear-gradient(to right, #005fc2, #35A834 40%, #ffd400 65%, #FF6400);*/
    background: linear-gradient(to right, #00939C, #49A6AE 11%, #65B3BA 14%, #B2DCDF 18%, #FEEEE8 24%, #F8C0AA 29%, #EC9370 34%, #DA6436 41%, #C22E00 50%);
    border-radius: 20px;
    border: 0.2px solid #010101;
  }
  input[type=range]::-moz-range-thumb {
    box-shadow: 1px 1px 1px #000000, 0px 0px 1px #0d0d0d;
    border: 1px solid #000000;
    height: 30px;
    width: 30px;
    border-radius: 30px;
    background: white;
    cursor: pointer;
    -webkit-appearance: none;
    margin-top: -6px;
  }
  #slide-before {
    position: absolute;
    bottom: -12px;
    left: 16px;
    color: black;
    font-size: 1rem;
  }
  #slide-after {
    position: absolute;
    bottom: -12px;
    right: 16px;
    color: black;
    text-align: right;
    font-size: 1rem;
  }
  #val {
    font-size: 1.6rem;
    position: absolute;
    bottom: 45px;
    width: 100px;
  }
  #response {
    position: absolute;
  }
</style>