<script>
	import { onMount } from 'svelte';
	import * as d3core from 'd3';
	import * as d3annotation from 'd3-svg-annotation';
	import _ from 'lodash'
	import { _ as i18n, locale } from 'svelte-i18n'
	
	import dataMarimekko from "../assets/data/dataMarimekko.json"
	import groupedFullData from '../assets/data/groupedFullData.json'

	const d3 = {...d3core, ...d3annotation}

	let el;
	let w;
	export let h;
	let width;
	let height;
	let main;
	let margin;
	let pointers;
	let legend;
	let column;

	const traduction = () => ({
		'Male': $i18n('plot.male'),
		'Female': $i18n('plot.female'),
		'ForeignerNonEU': $i18n('plot.foreignerNonEu'),
		'ForeignerEU': $i18n('plot.foreignerEu'),
		'Belgian': $i18n('plot.belgium'),
	})

	const traductionSmall = () => ({
		'Male': $i18n('plot.male'),
		'Female': $i18n('plot.female'),
		'ForeignerNonEU': $i18n('plot.foreignerNonEuShort'),
		'ForeignerEU': $i18n('plot.foreignerEuShort'),
		'Belgian': $i18n('plot.belgium'),
	})

	const ranges = {
		55: '< 1100€',
		65: '1100€ - 1280€',
		75: '1280€ - 1460€',
		85: '1460€ - 1640€',
		95: '1640€ - 1820€',
		105: '1820€ - 2000€',
		115: '2000€ - 2180€',
		125: '2180€ - 2360€',
		135: '2360€ - 2540€',
		145: '2540€ - 2720€',
		155: '> 2720€'
	}

	onMount(() => {
		margin = {top: 70, right: 1, bottom: 0, left: 1}
		width = w - margin.left - margin.right
		height = (h || (width * 0.5)) - margin.top - margin.bottom
				
		const svg = d3.select(el)
		  .append("svg")
			.attr("width", width + margin.left + margin.right)
			.attr("height", height + margin.top + margin.bottom)
			.attr("viewBox", [0, 0, width, height])
			.style("font", w < 740 ? "10px Alfphabet" : "14px Alfphabet")
			.style('overflow', 'visible')
		
		main = svg.append('g')
			.attr("transform", `translate(${margin.left}, ${margin.top})`)

		const marimekko = main.append('g')

		showLegend()

		const groupOrder = _(groupedFullData)
			.map((d, i) => ({group: d.group, index: i}))
			.keyBy('group')
			.mapValues(d => d.index)
			.value()

		const format = d => d.toLocaleString()
		const color = d3.scaleOrdinal(d3.schemeGreys[9].slice(0,7).concat(d3.schemeReds[9].slice(2,9))).domain([undefined, undefined, undefined, undefined, undefined, undefined, undefined,])//data.map(d => d.y))
		const hierarchy = d3.hierarchy(d3.group(dataMarimekko.filter(d => d.income && d.group !== 'notActiveWorkforce'), d => d.group, d => d.income))
			.sum(d => d.count)
			.sort((a, b) => groupOrder[b.data[0]] ?  groupOrder[a.data[0]] - groupOrder[b.data[0]] : b.data[0] - a.data[0])

		const root = d3.treemap()
			.round(true)
			.tile(d3.treemapSliceDice)
			.size([
				width - margin.left - margin.right, 
				height*0.835
			])
		(hierarchy)
		.each(d => {
			d.x0 += margin.left;
			d.x1 += margin.left;
			d.y0 += margin.top;
			d.y1 += margin.top;
		});

		const node = marimekko.selectAll("g")
			.data(root.descendants())
			.join("g")
				.attr("transform", d => `translate(${d.x0},${d.y0})`);

		column = node.filter(d => d.depth === 1);

		column.append("text")
			.attr('class', 'origin')
			.attr("x", 3)
			.attr("y", "-3.3em")
			.style("font-weight", "bold")
			.text((d, i) => i % 2 === 0 ? (w < 740 ? traductionSmall()[d.data[0].split('-')[1]] : traduction()[d.data[0].split('-')[1]]) : '');

		column.append("text")
			.attr('class', 'gender')
			.attr("x", 3)
			.attr("y", "-1.7em")
			.style("font-weight", "bold")
			.text(d => w < 740 ? traductionSmall()[d.data[0].split('-')[0]] : traduction()[d.data[0].split('-')[0]]);

		column.append("text")
			.attr("x", 3)
			.attr("y", "-0.5em")
			.attr("fill-opacity", 0.7)
			.text(d => format(d.value));

		column.append("line")
				.attr("x1", -0.5)
				.attr("x2", -0.5)
				.attr("y1", (d, i) => i % 2 === 0 ? -55 : -35)
				.attr("y2", d => d.y1 - d.y0)
				.attr("stroke", "#000")

		const cell = node.filter(d => d.depth === 2);

		cell.append("rect")
				.attr("fill", d => color(d.data[0]))
				//.attr("fill-opacity", (d, i) => (d.value / d.parent.value)*2)
				.attr("width", d => d.x1 - d.x0 - 1)
				.attr("height", d => Math.max(0, d.y1 - d.y0 - 1));

		/*cell.append("text")
				.attr("x", 3)
				.attr("y", "1.1em")
				.text(d => `${d.data[0] === 155 ? '> 155€' : `${d.data[0] - 10}€ - ${d.data[0]}€`}`);*/

		cell.append("text")
				.attr("x", 3)
				.attr("y", "1.1em")
				.text(d => d.data[0] > 65 ? ranges[+d.data[0]] : '')//`${d.data[0] === 155 ? `> ${Math.round(155*(219/12)/50)*50}€` : `${Math.round((d.data[0] - 10)*(219/12)/50)*50}€ - ${Math.round((d.data[0])*(219/12)/50)*50}€`}`);

		cell.append("text")
				.attr("x", 3)
				.attr("y", "2.3em")
				.attr("fill-opacity", 0.7)
				.text(d => d.data[0] > 75 ? `${Math.round(d.value/d.parent.value*100)}%` : '');

		marimekko.append('line')
			.attr('x1', 0)
			.attr('x2', width)
			.attr('y1', margin.top + (height - margin.top - margin.bottom - 25) / 2)
			.attr('y2', margin.top + (height - margin.top - margin.bottom - 25) / 2)
			.attr('stroke', 'white')
			.attr('stroke-dasharray', 2)

		pointers = marimekko.append('g')
		if(width > 740) showPointers()
	})

	function showPointers() {
		pointers.selectAll('.pointer')
			.data([
				{
					origin: { x: width * 0.08, y: height * 0.67},
					destination: { x: width * 0.14, y: height * 1.02},
					r: 0.6,
					text: $i18n('plot.pointer1'),
					align: 'right',
					direction: 'oposite',
					dy: -Math.round(Math.max(12, width * 0.01))*2,
				},
				{
					origin: { x: width * 0.11, y: height * 0.83},
					destination: { x: width * 0.14, y: height * 1.02},
					r: 0.6,
					text: "",
					align: 'right',
					direction: 'oposite',
					dy: -Math.round(Math.max(12, width * 0.01))*2,
				},
				{
					origin: { x: width * 0.13, y: height * 0.91},
					destination: { x: width * 0.14, y: height * 1.02},
					r: 0.6,
					text: "",
					align: 'right',
					direction: 'oposite',
					dy: -Math.round(Math.max(12, width * 0.01))*2,
				},
				{
					origin: { x: width * 0.9, y: -height * 0.05},
					destination: { x: width * 0.96, y: height * 0.2},
					r: 0.55,
					text: $i18n('plot.pointer2'),
					align: 'right',
					dy: -Math.round(Math.max(12, width * 0.01))*2,
				},
			])
			.join(enter => enter
				.append('g')
				.attr('class', 'pointer')
				.call(enter => enter
					.append('path')
					.attr('fill', 'none')
					.attr('stroke', 'white')
					.attr('stroke-width', 4)
					.style('stroke-linecap', 'round')
					.attr('d', d => {
						const dx = d.destination.x - d.origin.x
						const dy = d.destination.y - d.origin.y
						const distance = Math.sqrt(Math.pow(dx,2)+Math.pow(dy,2));
						const dr = distance*d.r;
						return `M${d.origin.x},${d.origin.y}A${dr},${dr} 0 0,1 ${d.destination.x},${d.destination.y}`
					})
				)
				.call(enter => enter
					.append('path')
					.attr('fill', 'none')
					.attr('stroke', 'black')
					.attr('stroke-width', 1.5)
					.style('stroke-linecap', 'round')
					.attr('d', d => {
						const dx = d.destination.x - d.origin.x
						const dy = d.destination.y - d.origin.y
						const distance = Math.sqrt(Math.pow(dx,2)+Math.pow(dy,2));
						const dr = distance*d.r;
						return `M${d.origin.x},${d.origin.y}A${dr},${dr} 0 0,1 ${d.destination.x},${d.destination.y}`
					})
				)
				.call(enter => enter
					.append('foreignObject')
					.attr('x', d => (d.direction === 'oposite' ? d.destination.x : d.origin.x) - (d.align === 'right' ? width * 0.1 : 0) -4)
					.attr('y', d => (d.direction === 'oposite' ? d.destination.y : d.origin.y) + (d.dy || 0))
					.attr('width', width * 0.1)
					.attr('height', width)
					.append("xhtml:div")
					.style('font-family', 'Alfphabet')
					.html(d => `<div style="font-size: ${Math.round(Math.max(12, width * 0.01))}px;">${d.text}</div>`)
				),
				update => update.select('foreignObject')
					.html(d => `<div style="font-size: ${Math.round(Math.max(12, width * 0.01))}px;">${d.text}</div>`)
			);
	}

	function showLegend() {
		legend = main.append('g')
			.style('font-size', 14)
			.style('font-family', 'Alfphabet')
			.attr('transform', `translate(${w < 740 ? w/8 : w/3}, -${margin.top})`)

		const tickSize = 12;
		const legendWidth = w < 740 ? 3*w/4 : w/3;
		const legendHeight = 44 + tickSize;
		const marginTop = 18;
		const marginRight = 0;
		const marginBottom = 26 + tickSize;
		const marginLeft = 0;
		const ticks = width / 64;
		let tickValues;

		const xScale = d3.scaleLinear()
      .domain([1100-180, 2720 + 180])
			.range([0, legendWidth])
		
		const colorScale = d3.scaleThreshold()
      .domain([1100-180, 1100, 1280, 1460, 1640, 1820, 2000, 2180, 2360, 2540, 2720, 2720 + 180])
      .range(['#a50f15', '#cb181d', '#ef3b2c', '#fb6a4a', '#fc9272', '#fcbba1', '#525252', '#737373', '#969696', '#bdbdbd', '#d9d9d9', '#f0f0f0', "#ffffff"])

		legend.append("g")
      .attr("transform", `translate(0,${legendHeight - marginBottom})`)
			.selectAll("rect")
			.data(colorScale.range().map(function(color) {
				var d = colorScale.invertExtent(color);
				if (d[0] == null) d[0] = xScale.domain()[0];
				if (d[1] == null) d[1] = xScale.domain()[1];
				return d;
			}))
			.enter().insert("rect", ".tick")
				.attr("height", 8)
				.attr("x", function(d) { return xScale(d[0]); })
				.attr("width", function(d) { return xScale(d[1]) - xScale(d[0]); })
				.attr("fill", function(d) { return colorScale(d[0]); })

		legend.append("g")
      .attr("transform", `translate(0,${legendHeight - marginBottom})`)
      .call(d3.axisBottom(xScale)
        .ticks(ticks, typeof tickFormat === "string" ? tickFormat : undefined)
        .tickFormat(d => d)
        .tickSize(tickSize)
        .tickValues([1100, 1280, 1460, 1640, 1820, 2000, 2180, 2360, 2540, 2720])
				.tickSizeOuter(0)
			)
      //.call(tickAdjust)
      .call(g => g.select(".domain").remove())
      .call(g => g.append("text")
        .attr("x", marginLeft + legendWidth / 2)
        .attr("y", marginTop + marginBottom - legendHeight - 6)
        .attr("fill", "currentColor")
        .attr("text-anchor", "middle")
				.attr("font-family", "Alfphabet")
				.style("font-size", "16px")
				.style("font-weight", "bold")
        .attr("class", "title")
        .text($i18n('plot.ledgendSalaire'))
			)
			.call(g => g.append("text")
				.attr('class', "low")
        .attr("x", w < 740 ? -8 : - 16)
        .attr("y", 8)
        .attr("fill", "currentColor")
        .style("text-anchor", "end")
				.style("font-family", "Alfphabet")
				.style("font-size", "14px")
        .text($i18n('plot.ledgendSalaireBas'))
			)
			.call(g => g.append("text")
				.attr('class', "high")
        .attr("x", legendWidth + (w < 740 ? 8 : 16))
        .attr("y", 8)
        .attr("fill", "currentColor")
        .style("text-anchor", "start")
				.style("font-family", "Alfphabet")
				.style("font-size", "14px")
        .text($i18n('plot.ledgendSalaireElevé'))
				)
	}

	function setLocale() {
		if(legend){
			legend.select('.high')
				.text(`${$i18n('plot.ledgendSalaireElevé')}`)

			legend.select('.low')
				.text(`${$i18n('plot.ledgendSalaireBas')}`)	

			legend.select('.title')
				.text(`${$i18n('plot.ledgendSalaire')}`)	
		}

		if(pointers) showPointers();

		if(column){
			column.selectAll('.origin')
				.text((d, i) => i % 2 === 0 ? (w < 740 ? traductionSmall()[d.data[0].split('-')[1]] : traduction()[d.data[0].split('-')[1]]) : '');

			column.selectAll('.gender')
			.text(d => w < 740 ? traductionSmall()[d.data[0].split('-')[0]] : traduction()[d.data[0].split('-')[0]]);
		}
	}

	$: $locale, setLocale()
</script>

<style>
</style>

<div bind:this={el} bind:clientWidth={w} style="background-color: #fff0; padding: 0px;"></div>
