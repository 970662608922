<script>
  import Scroller from "../layout/Scroller.svelte";
  import { _ } from 'svelte-i18n'
</script>

<div id="chapitre2">
  <Scroller threshold={0.9}>
    <div slot="background">
      <figure>
        <div class="col-full">
          <img src='img/illustrations/medor_bruxelles_malade_sante_1600x1060_feutre.jpg' style="object-fit: cover; object-position: 25% top; width: 100%; min-height: 100vh;" alt="Chapitre1: "/>
        </div>
      </figure>
    </div>
    <div slot="foreground">
      <section style="padding: 40vh 0 80vh 0;">
        <div class="col-medium" style="text-align: center;">
          <h1 style="font-weight: 500;">{@html $_('chap2.title')}</h1>
          <h1 style="font-weight: 500;">{@html $_('chap2.subTitle')}</h1>
        </div>
      </section>
      <section>
        <div class="col-medium">
          {@html $_('chap2.p1')}
        </div>
      </section>
      <section>
        <div class="col-medium">
          {@html $_('chap2.p2')}
        </div>
      </section>
      <section>
        <div class="col-medium">
          <blockquote class="text-indent">
            {@html $_('chap2.p3')}
            &mdash; <a style="color: #777;" alt="OMS" target="_blank" href="https://www.who.int/fr/about/governance/constitution">{@html $_('chap2.p4')}</a>
          </blockquote>
        </div>
      </section>
      <section>
        <div class="col-medium">
          {@html $_('chap2.p5')}
        </div>
      </section>
      <section>
        <div class="col-medium">
          {@html $_('chap2.p6')}
        </div>
      </section>
      <section>
        <div class="col-medium">
          <blockquote>{@html $_('chap2.p7')} &mdash; <a style="color: #777;" alt="KCE" href="https://kce.fgov.be/sites/default/files/2021-11/KCE_334B_Evaluation_equite_systeme_sante_belge_Synthese.pdf">{@html $_('chap2.p8')}</a></blockquote>
        </div>
      </section>
      <section>
        <div class="col-medium">
          {@html $_('chap2.p8')}
          {@html $_('chap2.p9')}
        </div>
      </section>
    </div>
  </Scroller> 
</div>
<style>
</style>