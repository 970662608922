<script>
  import { onMount } from "svelte";
	import Scroller from "../../layout/Scroller.svelte";
	import Map from "./Map.svelte";
	import Em from "../../ui/Em.svelte";
	import SectorsHive from "../../charts/SectorsHive.svelte"
	import bbox from "../../assets/data/communeBbox.json";
	import { address, location, mainLocation } from '../../store.js';
	import AutoComplete from "simple-svelte-autocomplete";
	import numeral from 'numeral';
	import PrecaritySlider from "../PrecaritySlider.svelte";
	import { _, locale } from 'svelte-i18n'
	
	// SCROLLYTELLING CONFIG
	// Config
	const threshold = 0.80;
  // State
	let index;
	let prevIndex;
	let precarityGuess;
	let w;

	//onMount(() => mounted = true) 

	const mapbounds = {
		bxl: [
			[4.25,50.77],
			[4.48,50.91],
		],
		croissantPauvre: [
			[4.310760498046875,50.81851685601869],
      [4.3650054931640625,50.868053132348585],     
		],
		...bbox,
	};
	// State
	let map = null;
	let popup = null;

	// Actions for MAP scroller
	let basicMapActions = {
		bxl: () => {
			if(map.isStyleLoaded()) {
				map.setFilter('communes-layer', ['==', 'id', '']);
				map.setFilter('sectors-layer', ['==', 'tx_munty_descr_fr', '']);
			}
			map.fitBounds(mapbounds.bxl, {bearing: 0, pitch: 0, padding: w < 900 ? 20 : 100});
		},
		bxlZoomedIn: () => {
			if(map.isStyleLoaded()) {
				map.setFilter('communes-layer', ['==', 'id', '']);
				map.setFilter('sectors-layer', ['==', 'tx_munty_descr_fr', '']);
			}
			map.fitBounds(mapbounds.bxl, {bearing: 0, pitch: 0, padding: w < 900 ? 20 : 100, zoom: 12.73});
		},
		locationZoomedOut: () => {
			if(map.isStyleLoaded()) {
				map.setFilter('communes-layer', ['==', 'id', '']);
				map.setFilter('sectors-layer', ['==', 'tx_munty_descr_fr', '']);
				map.setFilter('sectors-layer-focus', ['==', 'cd_sector', $location && $location.sectorId ? $location.sectorId : '']);
			}
			map.fitBounds(mapbounds.bxl, {bearing: 0, pitch: 0, padding: w < 900 ? 20 : 100});
		},
		locationZoomedIn: () => {
			if(map.isStyleLoaded()) {
				map.setFilter('communes-layer', ['==', 'id', '']);
				map.setFilter('sectors-layer', ['==', 'tx_munty_descr_fr', '']);
				map.setFilter('sectors-layer-focus', ['==', 'cd_sector', $location && $location.sectorId ? $location.sectorId : '']);
			}
			map.fitBounds($location && $location.commune ? mapbounds[$location.commune] : mapbounds.bxl, {bearing: 0, pitch: 0, padding: w < 900 ? 20 : 100});
		},
		saintJosse: () => {
			if(map.isStyleLoaded()) {
				map.setFilter('communes-layer', ['==', 'id', 'Saint-Josse']);
				map.setFilter('sectors-layer', ['==', 'tx_munty_descr_fr', 'Saint-Josse']);
				map.setFilter('sectors-layer-focus', ['==', 'cd_sector', '']);
			}
			map.fitBounds(mapbounds['Saint-Josse'], {bearing: -20, pitch: 40, padding: w < 900 ? 20 : 100});
		},
		woluwe: () => {
			if(map.isStyleLoaded()) {
				map.setFilter('communes-layer', ['==', 'id', 'Woluwe-Saint-Pierre']);
				map.setFilter('sectors-layer', ['==', 'tx_munty_descr_fr', 'Woluwe-Saint-Pierre']);
				map.setFilter('sectors-layer-focus', ['==', 'cd_sector', '']);
			}
			map.fitBounds(mapbounds['Woluwe-Saint-Pierre'], {bearing: 20, pitch: 30, padding: w < 900 ? 20 : 100});
		},
		croissant: () => {
			if(map.isStyleLoaded()) {
				map.setFilter('communes-layer', ['==', 'id', 'croissantPauvre']);
				map.setFilter('sectors-layer', ['==', 'tx_munty_descr_fr', 'croissantPauvre']);
				map.setFilter('sectors-layer-focus', ['==', 'cd_sector', '']);
			}
			map.fitBounds(mapbounds.croissantPauvre, {bearing: 0, pitch: 40, padding: w < 900 ? 70 : 100});
		},
	};

	let mapActions = [
		//basicMapActions.bxlZoomedIn,
		basicMapActions.locationZoomedIn,
		basicMapActions.locationZoomedOut,
		basicMapActions.bxl,
		basicMapActions.bxl,
		basicMapActions.bxl,
		basicMapActions.saintJosse,
		basicMapActions.woluwe,
		basicMapActions.croissant,
		basicMapActions.bxl,
	];

	function action() {
		//console.log('index', index, prevIndex)
		if(index !== prevIndex) {
			if(map && mapActions && mapActions[index]){
				if(mapActions[index]) mapActions[index]();
			}
			prevIndex = index
		}
	}

	const ACCESS_TOKEN = 'pk.eyJ1IjoicGZjcm91c3NlIiwiYSI6ImNrc3BxbGJ0YTA1ZzkycW4xc3N2cmZmNzIifQ.MgePXQLxhdutuYN_02yM6Q' 

	async function getLocation(inputAddress) {
		const url = `https://api.mapbox.com/geocoding/v5/mapbox.places/${inputAddress}.json
			?country=be
			&limit=10
			&types=address
			&language=fr
			&autocomplete=true
			&fuzzyMatch=true
			&access_token=${ACCESS_TOKEN}`

		const response = await fetch(url);
		const json = await response.json();

		const results = json.features.map(d => ({ 
			...d, 
			address: d.place_name, 
			region: d.context.find(c => c.id.startsWith('region')) ? d.context.find(c => c.id.startsWith('region')).short_code : null
		}))
		.filter(d => d.region === "BE-BRU" && d.place_type.includes('address'))

		return results;
	}

	// Reactive code to trigger MAP actions
	$: index, action()

	function setLocation() {
		if(map && map.isStyleLoaded()){
			let html= '';
			if($location) {
				html = `<strong>${$_('map.quartier')}</strong>
					<div>${$_('map.habitants')}: ${numeral($location.sectorData["population"]).format('0,0.')}</div>
					${$location.sectorData["Droit à l'intervention majorée|2018"] ? `<div>${$_('map.tauxPrécarité')}: ${$location.sectorData["Droit à l'intervention majorée|2018"] }%</div>` : ''}
				`
				if(map.isStyleLoaded()) {
					map.setFilter('communes-layer', ['==', 'id', '']);
					map.setFilter('sectors-layer', ['==', 'tx_munty_descr_fr', '']);
					map.setFilter('sectors-layer-focus', ['==', 'cd_sector', $location && $location.sectorId ? $location.sectorId : '']);
				}
				map.fitBounds(mapbounds[$location.commune], {bearing: 0, pitch: 0, padding: 100});
			} else {
				if(map.isStyleLoaded()) {
					map.setFilter('communes-layer', ['==', 'id', '']);
					map.setFilter('sectors-layer', ['==', 'tx_munty_descr_fr', '']);
					map.setFilter('sectors-layer-focus', ['==', 'cd_sector', '']);
				}
				map.fitBounds(mapbounds.bxl, {bearing: 0, pitch: 0, padding: 100});
			}			
			if(popup) {
				if($location) {
					popup.setLngLat($location.bottomSector).setHTML(html).addTo(map);
				} else {
					popup.remove();
				}
			}
		}
	}

	$: $location || $locale, setLocation()
</script>

<Scroller {threshold} bind:index>
	<div slot="background" bind:clientWidth={w}>
		<figure>
			<div class="col-full height-full">
				<Map bind:map bind:popup interactive={false} />
			</div>
		</figure>
	</div>

	<div slot="foreground">
		<section>
			<div class="col-medium" id="personalisation">
				<p>
					{@html $_('map.p1')} 					 
				</p>
				{#if !$mainLocation || $mainLocation === 'brussels'}
					<p>{@html $_('map.p2')}</p>
				{:else}
					<p>{@html $_('map.p3')}</p>
				{/if}
				<div class="sub-title-font">
					<PrecaritySlider bind:precarityGuess={precarityGuess}/>
				</div>
				{#if precarityGuess !== undefined && $mainLocation !== 'notBrussels'}
					<div class="sub-title-font">
						<div style="margin-bottom: 8px;">
							{@html $_('map.p4')}
						</div>
						<div class="credit-font" style="text-transform: 'capitalize'; margin-bottom: 8px;">{@html $_('map.p5')}</div>
						<div style="width: 90%;" id="address-input">
							<AutoComplete 
								inputClassName="pure-material-textfield-outlined"
								searchFunction={getLocation} 
								delay=1000 
								localFiltering=false 
								labelFieldName="address" 
								bind:selectedItem={$address} 
								showLoadingIndicator=true
								valueFieldName="id"
								minCharactersToSearch=5
								placeholder="ex: 24, chaussée de Médor"
								showClear=true
								noResultsText={`${$_('map.p6')}`}
								onChange={(v) => {
									if(v){
										(document.getElementById('address-input') && $location && $location.sectorData["Droit à l'intervention majorée|2018"]) && document.getElementById('address-input').scrollIntoView({behavior: "smooth"})
									}
								}}
							>
								<div slot="loading" let:loadingText={loadingText}>
									<span>{loadingText}</span>
								</div>
							</AutoComplete>
						</div>
						{#if $location}
							<p style="padding-left: 24px; padding-right: 24px; padding-bottom: 16px; padding-top: 32px;">
								{@html $_("map.location2", { values: { sector: $location.sector, commune: $location.commune, count: numeral($location.sectorData["population"]).format('0,0.') } })} <br/>
								{#if $location.sectorData["Droit à l'intervention majorée|2018"] !== null}
									{@html $_("map.p7", { values: { bim: $location.sectorData["Droit à l'intervention majorée|2018"]}})}<br/>
									<span class="caption">({@html $_('base.source')} : <a alt="Atlas Agence Intermutualiste" target="_blank" href="https://www.aim-ima.be/Atlas-AIM?lang=fr">{@html $_('base.aim')}Atlas Agence Intermutualiste</a>)</span>
								{:else} 
									{@html $_('map.p8')} 
								{/if}
							</p>
						{/if}
					</div>
				{/if}
				{#if precarityGuess !== undefined && $mainLocation === 'notBrussels'}
					{@html $_('map.p12')} <br/>
					{@html $_('map.p13')}
					<div class="sub-title-font">
						<div style="margin-bottom: 8px; margin-top: 16px;">
							{@html $_('map.p14')}
						</div>
						<div class="credit-font" style="text-transform: 'capitalize'; margin-bottom: 8px;">	{@html $_('map.p9')}</div>
						<div style="width: 90%;" id="address-input">
							<AutoComplete 
								inputClassName="pure-material-textfield-outlined"
								searchFunction={getLocation} 
								delay=1000 
								localFiltering=false 
								labelFieldName="address" 
								bind:selectedItem={$address} 
								showLoadingIndicator=true
								valueFieldName="id"
								minCharactersToSearch=5
								placeholder="ex: 24, chaussée de Médor"
								showClear=true
								noResultsText={`${$_('map.p6')}`}
								onChange={(v) => {if(v){(document.getElementById('address-input') && $location && $location.sectorData["Droit à l'intervention majorée|2018"]) && document.getElementById('address-input').scrollIntoView({behavior: "smooth"})}}}
							>
								<div slot="loading" let:loadingText={loadingText}>
									<span>{loadingText}</span>
								</div>
							</AutoComplete>
						</div>
						{#if $location}
							<p style="padding-left: 24px; padding-right: 24px; padding-bottom: 16px; padding-top: 32px;">
								{@html $_("map.location", { values: { sector: $location.sector, commune: $location.commune, count: numeral($location.sectorData["population"]).format('0,0.'), bim: $location.sectorData["Droit à l'intervention majorée|2018"] } })}
<!-- 
								Tu as encodé le quartier <Em>{$location.sector}</Em> de la commune de {$location.commune}.<br/>
								{numeral($location.sectorData["population"]).format('0,0.')} personnes habitent dans ce quartier. <br/>
								{$location.sectorData["Droit à l'intervention majorée|2018"]}% d’entre elles présentent un risque de précarité. <br/> -->
								<span class="caption">({@html $_("base.source")} : <a alt="Atlas Agence Intermutualiste" target="_blank" href="https://www.aim-ima.be/Atlas-AIM?lang=fr">{@html $_("base.aim")}</a>)</span>
							</p>
						{/if}
					</div>
				{/if}
			</div>
		</section>
		<section>
			<div class="col-medium">
				<p>
					{#if !$location}
						{@html $_('map.p15')}
					{:else}
						{#if !$mainLocation || $mainLocation === 'brussels'}
							{@html $_('map.p16')}
						{:else}
							{@html $_('map.p17')}
						{/if}
					{/if}
					<SectorsHive/>
				</p>
				<p>
					{@html $_('map.p18')}
				</p>
				<p>
					{@html $_('map.p19')}
				</p>
			</div>
		</section>
		<!-- <section style={`height: ${$location ? '' : 0}; padding: ${$location ? '' : '0 0 0 0 !important'}; visibility: ${$location ? 'visible' : 'hidden'};`}> -->
		<section>
			<div class="col-medium">
				<h2>{@html $_('map.p20')}</h2>
				<p>{@html $_('map.p21')}</p>
				<p>
					{@html $_('map.p22')} 
				</p>
				<p>
					{@html $_('map.p23')}
				</p>
				<p>
					{@html $_('map.p24')}
				</p>
			</div>
		</section>
		<section>
			<div class="col-medium">
				<p>
					{@html $_('map.p25')}
				</p>
				<p>
					{@html $_('map.p26')}
				</p>
			</div>
		</section>
		<section>
			<div class="col-medium">
				<p>
					{@html $_('map.p27')}
				</p>
			</div>
		</section>
		<section>
			<div class="col-medium">
				<p>
					<Em>Saint-Josse</Em> {@html $_('map.p28')}
					<SectorsHive commune="Saint-Josse"/>
				</p>
			</div>
		</section>
		<section>
			<div class="col-medium">
				<p>
					<Em>Woluwe-Saint-Pierre</Em> {@html $_('map.p29')}
					<SectorsHive commune="Woluwe-Saint-Pierre"/>
				</p>
			</div>
		</section>
		<section>
			<div class="col-medium">
				<p>
					{@html $_('map.p30')}
					<SectorsHive croissant={true}/>
				</p>
			</div>
		</section>
		<section>
			<div class="col-medium">
				<p>
					{@html $_('map.p31')}
				</p>
			</div>
		</section>
		<section>
			<div class="col-medium">
				<p>
					{@html $_('map.p32')}
				</p>
				<p>
					{@html $_('map.p33')}
				</p>
			</div>
		</section>
	</div>
</Scroller>