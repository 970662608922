<script>
	import { Facebook, Twitter, LinkedIn, Email  } from 'svelte-share-buttons-component';
  import { _ , locale } from 'svelte-i18n'
  const url = 'https://bxl-malade.medor.coop/';
</script>

<div id="share-circle" >
  <Facebook class="share-button" quote={$_('share')} url={`${url}?lang=${$locale}`} />
  <Twitter class="share-button" text={$_('share')} url={`${url}?lang=${$locale}`} />
  <LinkedIn class="share-button" url={`${url}?lang=${$locale}`} />  
  <Email class="share-button" subject="Bruxelles malade" body="{$_('share')} {`${url}?lang=${$locale}`}" />
</div>

<style>
.share-button {
  padding: 10px 16px 16px 16px !important;
  border-radius: 50% !important;
}
</style>